<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let resultToChange = null;
      this.unusedVars = resultToChange;
      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1

      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      // Видеоредакторы
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'Владение инструментами видеоредактирования: ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // 3D
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += 'Владение инструментами трехмерной графики и анимации: ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Графические редакторы
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Владение инструментами';
        if (answersObject.q3?.['3_1'] && answersObject.q3?.['3_2']) {
          this['resultTextarea' + variant + isEn] += ' ';
          this['resultTextarea' + variant + isEn] += 'векторной и растровой';
        } else if (answersObject.q3?.['3_1'] && !answersObject.q3?.['3_2']) {
          this['resultTextarea' + variant + isEn] += ' ';
          this['resultTextarea' + variant + isEn] += 'векторной';
        } else if (!answersObject.q3?.['3_1'] && answersObject.q3?.['3_2']) {
          this['resultTextarea' + variant + isEn] += ' ';
          this['resultTextarea' + variant + isEn] += 'растровой';
        }
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += 'графики:';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт монтажа
      if (answersObject.q7) {
        if (!answersObject.q7?.['7_3']) {
          this['resultTextarea' + variant + isEn] += 'Опыт монтажа';
          this['resultTextarea' + variant + isEn] += ' ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q7, variant, language);
          if (answersObject.q7?.['7_1'] && !answersObject.q7?.['7_2']) {
            this['resultTextarea' + variant + isEn] += ' ';
            this['resultTextarea' + variant + isEn] += 'продукции';
          }
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Приемы монтажа
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += 'Знания основ и ключевых приемов сложного видеомонтажа:';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q8, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Pre-production
      if (answersObject.q9?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q9['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      //Английский
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // личные качества
      if (answersObject.q13) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q13, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2

      // Опыт работы
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Уже монтировали
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += 'Имею опыт видеосъемки различной степени сложности: ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q4, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // YouTube 1 (YouTube 2)
      if (answersObject.q5?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q5['0'][variantFull][language];

        // YouTube 2
        if (answersObject.q6) {
          this['resultTextarea' + variant + isEn] += '(';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
          this['resultTextarea' + variant + isEn] += ')';
          this['resultTextarea' + variant + isEn] += '.';
        }

        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Работали видеооператором
      if (answersObject.q10?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Сможете сделать
      if (answersObject.q11) {
        this['resultTextarea' + variant + isEn] += 'При необходимости самостоятельно осуществляю';
        this['resultTextarea' + variant + isEn] += ' ';

        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q11);

        if (answersObject.q11['11_1'] || answersObject.q11['11_2']) {
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, "11_3", ' и ');
        }

        if (answersObject.q11?.['11_3']) {
          if (answersObject.q11['11_1'] && answersObject.q11['11_2']) {
            this['resultTextarea' + variant + isEn] += ', а также ';
          } else {
            this['resultTextarea' + variant + isEn] += ' и ';
          }
          this['resultTextarea' + variant + isEn] += answersObject.q11['11_3'].results[0][variantFull][language];
        }

        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'В хорошем смысле помешан на видеомонтаже. Люблю пробовать новые оригинальные идеи и подходы.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';
      this['resultTextarea' + variant + isEn] += 'Ценю сложные, неординарные задачи. Стремлюсь находить для них простые и оптимальные решения.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      // График
      if (answersObject.q15) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском

      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском

      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>
