<template>
  <div class="page">
    <div class="container" v-if="!isLoading">

      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-eOJMYsd53ii+scO/bJGFsiCZc+5NDVN2yr8+0RDqr0Ql0h+rP48ckxlpbzKgwra6" crossorigin="anonymous">

      <p>По умолчанию данные с 22.03.21</p>

      <div class="md-layout md-gutter period-filter">
        <div class="md-layout-item mb-3"><b>Фильтр по периоду:</b></div>
        <div class="md-layout-item input-group mb-3"><input class="form-control" type="datetime-local" name="datefrom" v-model="dateFrom"></div>
        <div class="md-layout-item input-group mb-3"><input class="form-control" type="datetime-local" name="dateto" v-model="dateTo"></div>
        <div class="btn-group" role="group" aria-label="Кнопки">
          <button type="button" class="btn btn-primary" @click.prevent="filterFieldsFill()">Загрузить</button>
          <a class="btn btn-link" @click.prevent="reset()" href="/admin">Сбросить</a>
        </div>
      </div>

      <!--<pre>{{data}}</pre>-->
      <br>
      <br>
      <p>Всего: {{data.count_real}}</p>
      <br>

      <table class="table table-bordered" width="100%" ref="table">
        <thead>
        <tr>
          <th>id</th>
          <th>email</th>
          <th>дата</th>
          <th>premium</th>
          <th>dates</th>
          <th>jobs</th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="item in dataList" v-bind:key="item.id">
          <td>{{ item.id }}</td>
          <td>{{ item.email }}</td>
          <td>{{ format_date_render(item.created_at) }}</td>
          <td>{{ isFieldPremium(item.is_premium) }}</td>
          <td>{{ isFieldDates(item.user_dates_count) }}</td>
          <td><span v-if="item.user_jobs_count" @click="loadJobs(item.id, item.email)">{{ isFieldJobs(item.user_jobs_count) }}</span></td>
        </tr>
        <tr v-if="isNextPage">
          <td colspan="6"><button type="button" class="btn btn-primary" @click.prevent="loadMoreData(data.list.next_page_url)">Загрузить ещё</button></td>
        </tr>
        </tbody>
      </table>

      <div class="modal fade show" tabindex="-1" v-bind:style="{display: modalDisplay}">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{modalEmail}}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="modalClose"></button>
            </div>
            <div class="modal-body">
              <div class="lds-ring" v-if="isModalLoading"><div></div><div></div><div></div><div></div></div>
              <ul v-if="!isModalLoading">
                <li v-for="(item, index) in modalContent.data" v-bind:key="index">{{item.name}}</li>
              </ul>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="line-height: normal;" @click="modalClose">Закрыть</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "Admin",
  data() {
    return {
      isLoading: true,
      data: [],
      dataList: [],
      dateFrom: null,
      dateTo: null,
      modalDisplay: "none",
      modalEmail: null,
      isModalLoading: true,
      modalContent: [],
      isNextPage: true
    }
  },
  computed: {
    isDebug() {
      return this.$route.query.isDebug || false
    },
  },
  methods: {

    //region Modal
    modalOpen: function () {
      this.modalDisplay = "block"
    },
    modalClose: function () {
      this.modalDisplay = "none"
      this.isModalLoading = true
    },
    loadJobs(userId, email) {
      const jobsUrl = "admin/jobs/" + userId;

      this.modalDisplay = "block";

      return axios.get(process.env.VUE_APP_API + jobsUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then(resp => {
            this.modalEmail = email + " (" + userId + ")";
            this.modalContent = resp.data;

            if (process.env.VUE_APP_TYPE === 'local') {
              console.log('resp ' + jobsUrl, resp);
            }

            this.isModalLoading = false;

          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log('err', err);
            }
          });
      // return usersList
    },
    //endregion

    reset: function () {
      this.dateFrom = null;
      this.dateTo = null;
      this.loading = true;
      this.loadDataAsync(this.dateFrom, this.dateTo);
    },

    isFieldDates: function (value) {
      if (value !== 0) {
        return value
      }
      return null
    },
    isFieldJobs: function (value) {
      if (value !== 0) {
        return value
      }
      return null
    },
    isFieldPremium: function (value) {
      if (value !== null) {
        // return '&#10003;'
        return 'Есть ✓'
      }
      return null
    },

    // sortDatesArray(array) {
    //   return array.sort(function(a,b){
    //     // Turn your strings into dates, and then subtract them
    //     // to get a value that is either negative, positive, or zero.
    //     return new Date(a) - new Date(b);
    //   });
    // },
    filterFieldsFill() {
      if(this.dateFrom && this.dateTo) {
        this.loading = true;
        // this.loadData(this.dateFrom, this.dateTo);
        this.loadDataAsync(this.dateFrom, this.dateTo);
      }
    },

    // Формат даты для фильтрации
    format_date(value){
      if (value) {
        //20200701T09:00
        if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
          console.log('format_date value', value);
          console.log('format_date value result', moment(String(value)).subtract(3, 'hours').format('YYYYMMDDTHH:mm'));
        }
        return moment(String(value)).subtract(3, 'hours').format('YYYYMMDDTHH:mm')
      }
    },

    // Формат даты для вывода
    format_date_render(value){
      if (value) {
        // let time = moment(String(value),'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm:ss');
        // return time.add(3, 'hours')
        return moment(String(value),'YYYY-MM-DD HH:mm:ss').add(3, 'hours').format('DD.MM.YYYY HH:mm:ss');
      }
    },

    // Загрузка данных
    async loadDataAsync(dateFrom,dateTo) {
      this.loading = true
      try {
        await Promise.all([this.loadDataUsers(dateFrom,dateTo)])
      } catch (error) {
        console.log('error', error);
        this.errormsg = error.message;
      } finally {
        this.isLoading = false
      }
    },

    loadDataUsers(dateFrom,dateTo) {
      let usersUrl = "admin/getUsers";
      if (dateFrom && dateTo) {
        usersUrl = "admin/getUsers/" + this.format_date(dateFrom) + '-' + this.format_date(dateTo);
      }
      console.log('usersUrl', usersUrl);
      const self = this;
      return axios.get(process.env.VUE_APP_API + usersUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then(resp => {
            console.log('resp', resp);
            this.data = resp.data;
            this.dataList = resp.data.list.data;
            if (!resp.data.list.next_page_url) {
              self.isNextPage = false
            } else {
              self.isNextPage = true
            }
            // console.log('usersUrl2', usersUrl);

          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log('err', err);
            }
          });
      // return usersList
    },

    loadMoreData(url) {
      console.log('url', url);
      const self = this;
      return axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then(resp => {
            // self.dataList.push({...resp.data.list.data});
            self.data = resp.data;

            // self.set('dataList', resp.data.list.data)

            // self.dataList = {...resp.data.list.data};

            self.dataList.push(...resp.data.list.data);

            // this.dataList = {...resp.data.list.data};
            // self.dataList.add(resp.data.list.data);

            // this.set(self.dataList, 0, resp.data.list.data)


            if (!resp.data.next_page_url) {
              self.isNextPage = false
            }
            // self.data.push(resp.data.list.data);
            // self.dataList.push();
            // self.dataList.splice(indexOfItem, 1, newValue)
            // console.log('usersUrl2', usersUrl);

          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log('err', err);
            }
          });
    },

    onScroll() {
      if (this.isNextPage) {

        // const table = this.$refs["table"];

        // console.log('table', table);

        // const marginTopTable = table.getBoundingClientRect().top;
        // const innerHeight = window.innerHeight;
        // if ((marginTopTable - innerHeight) < -50) {
        //   // console.log('this.data', this.data);
        //   console.log('this.data.next_page_url', this.data.list.next_page_url);
        //   // this.loadMoreData(this.data.next_page_url);
        // }

        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;

        if (bottomOfWindow) {
          this.loadMoreData(this.data.next_page_url);
        }
      }

    }

  },
  mounted() {

    this.loadDataAsync();

    if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
      this.dateFrom = "1011-01-10T10:10"
      this.dateTo = "2030-01-10T10:10"
      this.loadDataAsync(this.dateFrom, this.dateTo);
    }

    // Начинаем отслеживание скролла
    this.$nextTick(function() {
      window.addEventListener('scroll', this.onScroll);
      this.onScroll(); // needed for initial loading on page
    });

  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  }
}
</script>

<style scoped>
.btn {
  line-height: normal;
  height: auto;
}
.page {
  padding-top: 100px;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #333;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #333 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>