<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1

      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      // Backend Senior (владеете уверенно)
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += 'Владение языками backend на уровне senior (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Backend Middle (У Вас средний уровень)
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Владение языками backend на уровне middle (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Backend Junior (У Вас начальный уровень)
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += 'Владение языками backend на уровне junior (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q4, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Frontend
      if (answersObject.q5) {
        if (!answersObject.q5?.['5_4']) {
          this['resultTextarea' + variant + isEn] += 'Понимание ключевых принципов frontend-программирования (';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q5, variant, language, '5_4');
          this['resultTextarea' + variant + isEn] += ')';
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Храните код
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'Опыт использования ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += ' для хостинга проектов и их совместной разработки.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Контроль версий
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += 'Опыт использования ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q7, variant, language);
        this['resultTextarea' + variant + isEn] += ' в качестве системы контроля версий.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Язык разметки
      if (answersObject.q11) {
        this['resultTextarea' + variant + isEn] += 'Опыт использования ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q11, variant, language);
        this['resultTextarea' + variant + isEn] += ' для передачи данных в логически и визуально правильной форме.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Архитектурный стиль
      if (answersObject.q12) {
        this['resultTextarea' + variant + isEn] += 'Понимание принципов создания веб-сервисов на основе ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q12, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Базы данных
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += 'Опыт использования СУБД (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q8, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества ЛК
      if (answersObject.q17) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q17, variant, language, '', '<br/><br/>');
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2

      // Опыт работы
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Виды тестирования
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'Осуществляю';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += 'тестирование';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт тестирования
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'Имею опыт написания автоматизированных тестовых скриптов для';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'Владею полным циклом качественного, высокопрофессионального QA-тестирования: разработка тест-кейсов, тест-планов, чек-листов; выявление багов, составление четкого, понятного беклога.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';
      this['resultTextarea' + variant + isEn] += 'Умею работать в команде. Разделяю ответственность за общий результат. Нацелен на открытое, честное и максимально тактичное взаимодействие с разработчиками, продакт-менеджерами, дизайнерами, аналитиками.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';
      this['resultTextarea' + variant + isEn] += 'Отношусь к QA-тестированию любого проекта как к своему собственному. Понимаю, что автотесты служат не только выявлению ошибок, но и снижению затрат на порой дорогостоящую разработку.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      // Инструменты тестирования
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += 'Для записи, отладки и воспроизведения тестовых сценариев использую';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q10, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Ручное тестирование
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // таск-трекеры
      if (answersObject.q14) {
        this['resultTextarea' + variant + isEn] += 'Владею навыками работы с таск-трекерами';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q14, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт руководства
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // График
      if (answersObject.q19) {
        this['resultTextarea' + variant + isEn] += answersObject.q19['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском
      this['resultTextarea' + variant + isEn] += '<b>Professional skills:</b><br/><br/>';

      // Backend Senior (владеете уверенно)
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += 'Senior level backend programming experience (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Backend Middle (У Вас средний уровень)
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Middle level backend programming experience (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Backend Junior (У Вас начальный уровень)
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += 'Junior level backend programming experience (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q4, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Frontend
      if (answersObject.q5) {
        if (!answersObject.q5?.['5_4']) {
          this['resultTextarea' + variant + isEn] += 'Knowledge of key principles of frontend programming (';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q5, variant, language, '5_4');
          this['resultTextarea' + variant + isEn] += ')';
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Храните код
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'Experience using ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += ' for project hosting and collaborative development.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Контроль версий
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += 'Experience using ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q7, variant, language);
        this['resultTextarea' + variant + isEn] += ' as a version control system.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Язык разметки
      if (answersObject.q11) {
        this['resultTextarea' + variant + isEn] += 'Experience using ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q11, variant, language);
        this['resultTextarea' + variant + isEn] += ' to communicate data in a logically and visually correct manner.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Архитектурный стиль
      if (answersObject.q12) {
        this['resultTextarea' + variant + isEn] += 'Experience developing API based on ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q12, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Базы данных
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += 'Experience using DBMS (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q8, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества ЛК
      if (answersObject.q17) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Personal skills:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q17, variant, language, '', '<br/><br/>');
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском
      // Опыт работы
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Виды тестирования
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'I perform';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += 'testing';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт тестирования
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'I have experience in writing automated test scripts for';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'I have thorough knowledge of high-quality and highly professional QA-testing: developing test cases, test plans, check lists, identifying bugs, and writing clear and understandable test behavior logs.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';
      this['resultTextarea' + variant + isEn] += 'I’m able to work in a team. I can share responsibility for the overall result. And I’m totally up for open, honest and polite collaboration with developers, product managers, designers, and analysts.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';
      this['resultTextarea' + variant + isEn] += 'I approach QA testing of any project as if it were my own. I understand that autotests serve not only to detect errors, but also to reduce development costs (which might be rather expensive).';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      // Инструменты тестирования
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += 'I use';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q10, variant, language);
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += 'to record, debug and replay test scenarios';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Ручное тестирование
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // таск-трекеры
      if (answersObject.q14) {
        this['resultTextarea' + variant + isEn] += 'I’m skilled in working with task-trackers';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q14, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт руководства
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // График
      if (answersObject.q19) {
        this['resultTextarea' + variant + isEn] += answersObject.q19['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>