<template>
  <div class="container container_main">
    <div class="text-page">
      <a class="btn btn_back" href="#" @click="$router.push('/')">&lt; Назад</a>
      <h1>Оплата и безопасность платежей</h1>
      <p>При оплате заказа банковской картой, обработка платежа (включая ввод номера карты) происходит на защищенной странице процессинговой системы, которая прошла международную сертификацию. Это значит, что Ваши конфиденциальные данные (реквизиты карты, регистрационные данные и др.) не поступают в интернет-магазин, их обработка полностью защищена и никто, в том числе наш интернет-магазин, не может получить персональные и банковские данные клиента.
        <br>
      При работе с карточными данными применяется стандарт защиты информации, разработанный международными платёжными системами Visa и MasterCard - Payment Card Industry Data Security Standard (PCI DSS), что обеспечивает безопасную обработку реквизитов Банковской карты Держателя. Применяемая технология передачи данных гарантирует безопасность по сделкам с Банковскими картами путем использования протоколов Transport Layer Security (TLS), Verified by Visa, Secure Code, и закрытых банковских сетей, имеющих высшую степень защиты.<br>
      В случае возврата, денежные средства возвращаются на ту же карту, с которой производилась оплата.</p>

      <p>При оплате  заказа банковской картой возврат денежных средств производится на ту карту, с которой был произведен платеж</p>

      <p>Оператор по переводу денежных средств - ПАО РОСБАНК (ИНН 7730060164), тел. 8 800 200 5434. Генеральная лицензия Банка России №2272 от 28.01.2015.<br>
      Платежный агрегатор (банковский платежный агент) оператора - АО "КОКК" (ИНН 7710060991), тел. 8 800 250 3556 на основании Соглашения от 29.12.2007 и Дополнительного соглашения от 31.12.2019г</p>

      <p><img src="/img/cards.png" alt=""></p>
      <p><img src="/img/cards2.jpg" alt=""></p>

    </div>
  </div>
</template>

<script>
export default {
  name: "Security"
}
</script>

<style scoped>
.text-page {
  padding-bottom: 80px;
}
</style>