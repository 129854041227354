// module.exports = {
//   getSrmColor: (color) => {
//     return color;
//   },
//
//   ObjectToString: function(obj,del,variant) {
//     // Удаляем пустой элемент
//     delete obj[del];
//
//     console.log('obj', obj);
//     console.log('del', del);
//     console.log('variant', variant);
//
//     return Object.keys(obj)
//       .map(function(k){
//         if (variant === 1) {
//           console.log('obj[k].results[0]', obj[k].results[0]);
//
//           return obj[k].results[0].variant1['ru']
//         } else {
//           console.log('obj[k].results[0] 2', obj[k].results[0]);
//
//           return obj[k].results[0].variant2['ru']
//         }
//       }).join(", ")
//   },
// }

// import Pay from "@/pages/Pay.vue";

const ObjectToString = (obj, variant, lang, del, delimiter) => {
  // console.log('obj', obj);
  // console.log('del', del);
  // console.log('variant', variant);
  // Удаляем пустой элемент
  if (del) {
    if (typeof del === "string") {
      delete obj[del];
    }
    if (Array.isArray(del)) {
      for (let i = 0; i < del.length; i++) {
        delete obj[del[i]];
      }
    }
  }

  if (!delimiter) {
    delimiter = ", "
  }

  let language = lang ?? 'ru';

  return Object.keys(obj)
    .map(function(k){
      if (variant === 1) {
        return obj[k].results[0].variant1[language]
      } else {
        return obj[k].results[0].variant2[language]
      }
    }).join(delimiter)
}

// Результаты из текстовых множественных полей в строку
const TextResultsToString = (obj, delimiter) => {
  let clonedObj = clone(obj);
  let newObj = [];

  // Удаляем пустые значения
  // Наверняка есть способ получше, но шёл 13й час работы
  for (let i = 0; i < clonedObj.length; i++) {
    if (clonedObj[i].text_result !== "") {
      newObj.push(clonedObj[i])
    }
  }

  if (!delimiter) {
    delimiter = ", "
  }
  return Object.keys(newObj)
    .map(function(k){
      if (newObj[k].text_result !== "") {
        return newObj[k].text_result
      }
    }).join(delimiter)
}

const ObjectToStringFull = (obj, variant, lang, remove, delimiter) => {
  // console.log('obj', obj);
  // console.log('variant', variant);
  // Удаляем пустой элемент
  if (remove) {
    if (typeof remove === "string") {
      delete obj[remove];
    }
    if (Array.isArray(remove)) {
      for (let i = 0; i < remove.length; i++) {
        delete obj[remove[i]];
      }
    }
  }
  if (!delimiter) {
    delimiter = " "
  }

  let language = lang ?? 'ru';

  if (obj) {
    return Object.keys(obj)
      .map(function(k){
        if (variant === 1) {
          return obj[k].results[0].variant1[language]
        } else {
          return obj[k].results[0].variant2[language]
        }
      }).join(delimiter)
  }
}

// Заменяет слово, чтобы учесть мужский/женский род
const SexWord = (words, sex, string) => {
  let stringNew = string;
  // Какое слово должно быть вместо первоначального
  let word1 = 0;
  let word2 = 1;
  if (words && sex !== 'man') {
    // Обратный порядок, если должен быть женский род
    word1 = 0;
    word2 = 1;

    let index = 0;
    while (index < words.length) {
      // stringNew = stringNew.replaceAll(words[index][word1], words[index][word2]);
      // Для поддержки старых браузеров и
      const re = new RegExp('(' + words[index][word1] + ')([^а-яА-Я])', 'g');
      stringNew = stringNew.replace(re, words[index][word2] + '$2');
      index = index + 1;

    }
  }

  return stringNew;
}

// Заменяет фразу на нужную
const replace = (string, orig, need) => {
  if (orig && need) {
    return string.replace(orig, need);
  }
  console.log('replace error');
  return string;
}

// Не актуально
// Ж в М род ("Готова" в "готов")
const SexWordWomanToMan = (words, sex, string) => {
  let stringNew = string;
  // Какое слово должно быть вместо первоначального
  if (words && sex === 'man') {
    let index = 0;
    while (index < words.length) {
      stringNew = stringNew.replace(words[index][0], words[index][1]);
      index = index + 1;
    }
  }
  return stringNew;
}

// Не актуально
// М в Ж род ("Готов" в "готова")
const SexWordManToWoman = (words, sex, string) => {
  let stringNew = string;
  // Какое слово должно быть вместо первоначального
  if (words && sex !== 'man') {
    let index = 0;
    while (index < words.length) {
      stringNew = stringNew.replace(words[index][0], words[index][1]);
      index = index + 1;
    }
  }
  return stringNew;
}

const clone = (obj) => {
  if (obj == null || typeof(obj) != 'object') {
    return obj;
  }

  var temp = obj.constructor();

  for (var key in obj) {
    temp[key] = clone(obj[key]);
  }

  return temp;
}

// Проверка на пустой объект
function isEmptyObj(obj) {
  // console.log('stringify', JSON.stringify(obj));
  // console.log('stringify 1_4', JSON.stringify(obj['1_4']));
  //
  // console.log('obj', obj && Object.keys(obj).length === 0 && obj.constructor === Object);

  return obj && Object.keys(obj).length === 0 && obj.constructor === Object

}



export default {
  ObjectToString,
  ObjectToStringFull,
  SexWord,
  SexWordWomanToMan,
  SexWordManToWoman,
  clone,
  replace,
  isEmptyObj,
  TextResultsToString
}