import Vue from 'vue'
import Vuex from 'vuex'
import './plugins/axios'
// import helpers from '@/helpers'
import jobsHelpers from '@/helpers/jobs'

import App from './App.vue'

import VueAxios from './plugins/axios'
Vue.use(VueAxios)

Vue.use(Vuex)

// Маски
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

// Загрузчик скриптов
import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

// Свои плагины
// import helpers from './helpers.js';
// const plugin = {
//   install (Vue) {
//     // we use $ because it's the Vue convention
//     Vue.prototype.$helpers = helpers;
//   }
// };
// Vue.use(plugin);


Vue.prototype.jobsHelpers = jobsHelpers
Vue.jobsHelpers = jobsHelpers
Vue.use(jobsHelpers)


// Копирование
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.config.productionTip = false


import VueRouter from "vue-router";
Vue.use(VueRouter)
import {routes} from "@/routes";
export const router = new VueRouter({

  scrollBehavior () {
    return { x: 0, y: 0 };
  },

  routes,
  mode: "history",
});


import VueYandexMetrika from 'vue-yandex-metrika'
Vue.use(VueYandexMetrika, {
  id: 66407710,
  router: router,
  // env: process.env.VUE_APP_TYPE
  env: 'production',
  triggerEvent: true,
  // other options
})

//region Мета теги
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});
//endregion


// Vue.prototype.$http = Axios;
// Vue.prototype.$http = VueAxios;
// Vue.prototype.$http = VueAxios;
// const token = localStorage.getItem('token')
// if (typeof token !== "undefined" && token) {
//   console.log('token2', token);
//
//   Vue.prototype.$http.defaults.headers.common['Authorization'] = token
// }
//
// const token = localStorage.getItem('token')
// if (token) {
//   axios.defaults.headers.common['Authorization'] = token
// }

// import http from './http'
// Vue.prototype.$http = http

import store from './store'

new Vue({
  store,
  router,
  watch:{
    '$route' (){
    }
  },
  render: h => h(App),
}).$mount('#app')


