<template>
  <div id="app">
    <Menu />
    <router-view />
  </div>
</template>

<script>
import Menu from "@/components/Menu";
export default {
  // directives: {mask},
  name: 'App',
  components: {Menu},
  // computed: {
  //   isFront() {
  //     console.log('this.$route.name', this.$route.name);
  //     return this.$route.name === 'Front'
  //   }
  // },
  methods: {
    created: function () {
      // Автовыход, если ошибка 401 от бэка
      this.$http.interceptors.response.use(undefined, function (err) {
        // return new Promise(function (resolve, reject) {
        return new Promise(function () {
          if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
            this.$store.dispatch("logout")
            this.$router.push({ path: "login" })
          }
          throw err;
        });
      });

      // Автовход
      // this.$store.dispatch('AutoLogin');

    }
  },
}
</script>

