<template>
  <div class="page">
    <div class="container container_main">

      <div class="want">
        <div class="want__title">Вы хотите</div>
        <div class="want__item" @click="$router.push({name:'jobs'})">
          <div class="want__item-title">Составить описание для резюме&nbsp;и&nbsp;сопроводительных писем</div>
          <div class="want__item-text">
            <p>Идеально для</p>
            <p>HeadHunter · SuperJob · Rabota.ru · YouDo · PROFI.RU</p>
          </div>
        </div>
        <!--<div class="want__item" @click="$router.push({name:'dates'})">-->
        <!--  <div class="want__item-title">Составить описание для сайтов&nbsp;и&nbsp;приложений знакомств</div>-->
        <!--  <div class="want__item-text">-->
        <!--    <p>Идеально для</p>-->
        <!--    <p>Tinder · Badoo · Bumble · Mamba · Teamo</p>-->
        <!--  </div>-->
        <!--</div>-->
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Want",
  mounted() {
    this.$router.push({name:'jobs'})
  },
  computed: {
    isDebug() {
      return this.$route.query.isDebug || false
    },
  },
  methods: {

  }
}
</script>

<style scoped>

</style>