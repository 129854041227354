<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      let resultToChange = null;
      this.unusedVars = resultToChange;
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1

      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';
      this['resultTextarea' + variant + isEn] += 'Уверенное владение HTML, CSS.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      // JavaScript
      if (answersObject.q1?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q1['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // jQuery
      if (answersObject.q2?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q2['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Фреймворки
      if (answersObject.q8) {
        if (!answersObject.q8?.['8_6']) {
          this['resultTextarea' + variant + isEn] += 'Опыт работы с фреймворками (';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q8, variant, language, '8_6');
          this['resultTextarea' + variant + isEn] += ')';
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Шаблонизаторы
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += 'Опыт использования шаблонизаторов (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q7, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Храните код
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Опыт использования ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += ' для хостинга проектов и их совместной разработки.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Контроль версий
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += 'Опыт использования ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q4, variant, language);
        this['resultTextarea' + variant + isEn] += ' в качестве системы контроля версий.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Язык разметки
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'Опыт использования ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += ' для передачи данных в логически и визуально правильной форме.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Инструменты сборки
      if (answersObject.q13) {
        if (!answersObject.q13?.['13_7']) {
          this['resultTextarea' + variant + isEn] += 'Опыт работы с инструментами сборки';
          this['resultTextarea' + variant + isEn] += ' ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q13, variant, language, '13_7');
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Английский
      if (answersObject.q22?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q22['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества ЛК
      if (answersObject.q20) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q20, variant, language, '', '<br/><br/>');
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2

      // Опыт работы
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Уже делали
      // [ВНИМАНИЕ! Английская версия сильно отличается]
      if (answersObject.q14) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q14);

        // Предложение1
        if (answersObject.q14?.['14_1']) {
          this['resultTextarea' + variant + isEn] += answersObject.q14['14_1'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += ' ';
        }

        // Предложение2
        if (answersObject.q14['14_2'] || answersObject.q14['14_3']) {
          this['resultTextarea' + variant + isEn] += 'Верстал ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ["14_1", "14_4"]);
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += ' ';
        }

        // Предложение3
        if (answersObject.q14?.['14_4']) {
          this['resultTextarea' + variant + isEn] += answersObject.q14['14_4'].results[0][variantFull][language];
        }
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Препроцессоры HTML
      if (answersObject.q5) {
        this['resultTextarea' + variant + isEn] += 'Для автоматизации рутинных операций использую препроцессоры HTML';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q5, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Препроцессоры CSS
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'В целях ускорения и упрощения разработки, обеспечения «бесшовной» поддержки стилей использую препроцессоры CSS';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // БЭМ
      if (answersObject.q11?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Анимации
      if (answersObject.q10?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // UX/UI
      if (answersObject.q17?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q17['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Стремитесь
      if (answersObject.q21) {
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q21, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // таск-трекеры (Управляете задачами)
      if (answersObject.q12) {
        this['resultTextarea' + variant + isEn] += 'Владею навыками работы с таск-трекерами';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q12, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Графические редакторы
      if (answersObject.q16) {
        this['resultTextarea' + variant + isEn] += 'Знаком с графическими редакторами';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q16, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Инструменты проектирования
      if (answersObject.q15) {
        if (answersObject.q18) {
          this['resultTextarea' + variant + isEn] += 'Знаком также с инструментами проектирования';
        }
        else {
          this['resultTextarea' + variant + isEn] += 'Знаком с инструментами проектирования';
        }

        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q15, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт руководства
      if (answersObject.q19?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q19['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // График
      if (answersObject.q23?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q23['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском
      this['resultTextarea' + variant + isEn] += '<b>Professional skills:</b><br/><br/>';

      this['resultTextarea' + variant + isEn] += 'Proficiency in HTML and CSS.<br/><br/>';

      // JavaScript
      if (answersObject.q1?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q1['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // jQuery
      if (answersObject.q2?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q2['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Фреймворки
      if (answersObject.q8) {
        if (!answersObject.q8?.['8_6']) {
          this['resultTextarea' + variant + isEn] += 'Experience with frameworks (';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q8, variant, language, '8_6');
          this['resultTextarea' + variant + isEn] += ')';
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Шаблонизаторы
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += 'Experience with templates (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q7, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Храните код
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Experience using ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += ' for project hosting and collaborative development.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Контроль версий
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += 'Experience using ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q4, variant, language);
        this['resultTextarea' + variant + isEn] += ' as a version control system.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Язык разметки
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'Experience using ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += ' to communicate data in a logically and visually correct manner.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Инструменты сборки
      if (answersObject.q13) {
        if (!answersObject.q13?.['13_7']) {
          this['resultTextarea' + variant + isEn] += 'Experience working with build tools ';
          this['resultTextarea' + variant + isEn] += ' ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q13, variant, language, '13_7');
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Английский
      if (answersObject.q22?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q22['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества ЛК
      if (answersObject.q20) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Personal skills:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q20, variant, language, '', '<br/><br/>');
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском

      // Опыт работы
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Уже делали
      // [ВНИМАНИЕ! Английская версия сильно отличается]
      if (answersObject.q14) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q14);

        this['resultTextarea' + variant + isEn] += 'I have experience in creating html layout';
        this['resultTextarea' + variant + isEn] += ' ';

        // Предложение1
        if (answersObject.q14['14_1'] || answersObject.q14['14_2'] || answersObject.q14['14_3']) {
          this['resultTextarea' + variant + isEn] += 'for ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ["14_4"]);
          this['resultTextarea' + variant + isEn] += ' ';
        }

        // Предложение2
        if (answersObject.q14?.['14_4']) {
          if (answersObject.q14['14_1'] || answersObject.q14['14_2'] || answersObject.q14['14_3']) {
            this['resultTextarea' + variant + isEn] += ' ';
            this['resultTextarea' + variant + isEn] += ',';
            this['resultTextarea' + variant + isEn] += 'and';
            this['resultTextarea' + variant + isEn] += ' ';
          }
          this['resultTextarea' + variant + isEn] += answersObject.q14['14_4'].results[0][variantFull][language];
        }

        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Препроцессоры HTML
      if (answersObject.q5) {
        this['resultTextarea' + variant + isEn] += 'I use HTML preprocessors';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q5, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += 'to automate routine operations';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Препроцессоры CSS
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'I use CSS preprocessors';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += 'in order to speed up and simplify development and provide seamless style support';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Анимации
      if (answersObject.q10?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // UX/UI
      if (answersObject.q17?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q17['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Стремитесь
      // [ВНИМАНИЕ] Русская версия сильно отличается
      if (answersObject.q21) {
        let string = Vue.jobsHelpers.ObjectToStringFull(answersObject.q21, variant, language, '', '<br/><br/>');

        if (answersObject.q21['21_1'] || answersObject.q21['21_2']) {
          string = Vue.jobsHelpers.replace(string, 'I\'m interested in', 'I\'m also interested in');
        }

        if (answersObject.q21['21_3']) {
          string = Vue.jobsHelpers.replace(string, 'Backend programming fascinates', 'Backend programming also fascinates');
          string = Vue.jobsHelpers.replace(string, 'working with databases is very', 'working with databases is likewise very');
        }

        this['resultTextarea' + variant + isEn] += string;
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // таск-трекеры (Управляете задачами)
      if (answersObject.q12) {
        this['resultTextarea' + variant + isEn] += 'I’m skilled in working with task-trackers';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q12, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Графические редакторы
      if (answersObject.q16) {
        this['resultTextarea' + variant + isEn] += 'I’m familiar with graphics editing tools';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q16, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Инструменты проектирования
      if (answersObject.q15) {
        if (answersObject.q18) {
          this['resultTextarea' + variant + isEn] += 'I’m also familiar with design tools';
        }
        else {
          this['resultTextarea' + variant + isEn] += 'I’m familiar with design tools';
        }

        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q15, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт руководства
      if (answersObject.q19?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q19['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // График
      if (answersObject.q23?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q23['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>