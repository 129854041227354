<template>
  <div class="blur" v-bind:class="{blur_work: ifIsJob, blur_first: isFirst}">
    <div class="blur__top">
      <div class="blur__lock"></div>
    </div>
    <div class="blur__inner">

      <div class="blur__title">Доступ к полной версии – по подписке.</div>
      <div class="blur__text">Оформите подписку, чтобы сохранить полное описание в Ваш личный кабинет и ознакомиться с его английской версией.</div>
      <div class="blur__subscribe" @click="$router.push('/pay')">Оформить подписку</div>
      <div class="blur__readmore" @click="$router.push('/#start')">Подробнее о Best Me</div>

      <template v-if="isJob === true">
        <div class="blur__link" @click="$router.push('/jobs')">Попробовать другую профессию &gt;</div>
        <!--<div class="blur__link" @click="$router.push('/dates')">Попробовать описание для знакомств &gt;</div>-->
      </template>
      <template v-else>
        <!--<div class="blur__link" @click="$router.push('/dates')">Попробовать другое описание &gt;</div>-->
        <div class="blur__link" @click="$router.push('/jobs')">Попробовать описание для работы &gt;</div>
      </template>

    </div>
  </div>
</template>

<script>
export default {
  name: "Blur",
  // job||date
  props: {
    isJob: Boolean,
    isFirst: Boolean
  },
  mounted() {
   this.ifIsJob = this.isJob
    // console.log('this.isJob', this.isJob);
  },
  data() {
    return {
      ifIsJob: false
    }
  }
}
</script>

<style scoped>
.blur.blur_first {
  padding-top: 0;
}
</style>