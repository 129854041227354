<template>
  <p>Выход</p>
</template>

<script>
// import store from "@/store";

export default {
  name: "Logout",
  created: function () {
    this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/')
        })
  }

}
</script>

<style scoped>

</style>