<template>

  <div class="page">
    <main class="page__content container container_narrow">
      <div class="jobs">

        {{ /* Пол */ }}
        <div class="question question_main" v-if="!isSexAnswer">
          <div class="question__wrapper">
            <div class="question__header">

              <div class="question__title">Вы</div>
              <div class="question__subtitle"></div>
            </div>
            <div class="question__variants question__variants_buttons">
              <div class="question__item question-item">
                <input type="radio" id="sex_women" name="sex">
                <label for="sex_women" @click="submitSex('woman')">Женщина</label>
              </div>
              <div class="question__item question-item">
                <input type="radio" id="sex_men" name="sex">
                <label for="sex_men" @click="submitSex('man')">Мужчина</label>
              </div>
            </div>
          </div>
        </div>

        <template v-if="dataArray && dataArray.length > 0 && isSexAnswer">

          <question :data="dataArray[currentQuestion]"
                    :currentProgress="progressBar"
                    v-bind:key="'id_' + dataArray[currentQuestion].id"
                    @changeStep="onChangeStep"
                    @lastStep="onLastStep"
                    @setAnswer="onSetAnswer"
                    @skipStep="onSkipStep"
                    v-if="!isResultReady && !isBeforeResult"
          >
          </question>

          <template v-if="isResultReady">

            <jobs-results
                :result1="resultTextarea1"
                :result1en="resultTextarea1en"
                :result2="resultTextarea2"
                :result2en="resultTextarea2en"
                :resultSubject="realSubject"
            ></jobs-results>

          </template>


        </template>

      </div>
    </main>
  </div>
</template>

<script>
import Question from "@/components/Question";
import axios from "axios";
import JobsResults from "@/components/JobsResults";

// import textResult from '@/jobs/system_administrator'

import * as jobsRes from '@/mixins/jobs';
import Vue from "vue";
import sexWords from "@/helpers/sexWords";

export default {
  name: "JobsQuestions",
  components: {JobsResults, Question},
  props: {
    file: String,
    subject: String,
    preSex: String,
  },
  data() {
    return {
      // Текущий вопрос
      currentQuestion: 0,
      isSexAnswer: false,
      sex: '',
      dataArray: null,

      isResultReady: false,
      isBeforeResult: false,

      answers: [],
      answersObject: {},

      //region Результаты
      //region textarea с результатами
      resultSubject: this.subject,
      resultTextarea1: null,
      resultTextarea1en: null,
      resultTextarea2: null,
      resultTextarea2en: null,
      //endregion
      //endregion

    }
  },
  computed: {
    isDebug() {
      return this.$route.query.isDebug || false
    },
    // Прогресс
    progressBar: function () {
      if (this.dataArray) {
        // Текущее значение прогресса
        return 100 / (4 + this.dataArray.length) * (this.currentQuestion + 4)
      }
      return 0
    },
    // Для возможной подмены темы
    realSubject() {
      if (this.subject === "Санитар") {
        if (this.sex === "woman") {
          return "Санитарка";
        }
      }
      return this.subject
    },
  },
  mounted() {
    // this.answersObject = {
    //   "q6": {
    //     "6_2": {"id": "6_2", "name": "HTML", "description": "", "results": [{"variant1": {"ru": "", "en": ""}, "variant2": {"ru": "HTML", "en": ""}}]},
    //     "6_3": {"id": "6_3", "name": "CSS", "description": "", "results": [{"variant1": {"ru": "", "en": ""}, "variant2": {"ru": "CSS", "en": ""}}]}
    //   }
    // };
    // this.$helpers.ObjectToString(this.answersObject.q6, '6_4');

    if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
      console.log('prop', this.file)
      console.log('prop', this.subject)
      console.log('prop', this.preSex)
    }

    let self = this
    axios
        .get('/json/jobs/' + this.file + '.json?v=' + Date.now())
        .then(function (response) {
          // console.log('response', response);
          if (process.env.VUE_APP_TYPE === 'local' || self.isDebug) {
            console.log('response json data', response.data);
            console.log('response json data steps', response.data.steps);
          }
          self.dataArray = response.data.steps;
          // self.resultsTitles = response.data.results_titles;
        });

    // Медбрат/медсестра имеет предварительно выбранный пол
    if (this.preSex) {
      this.isSexAnswer = true
      this.sex = this.preSex
    }

    // if (process.env.VUE_APP_TYPE === 'local') {
    //   this.isSexAnswer = true
    //   this.sex = 'man'
    // }
  },
  methods: {
    // Пол
    submitSex(variant) {
      // Пол
      this.sex = variant;
      // Переход к вопросам
      this.isSexAnswer = true;
    },

    // region Шаги
    onChangeStep(data) {
      // todo: УДАЛИТЬ
      this.setResults();

      this.currentQuestion = data.currentStep;

      // if (data.currentStep === 5) {
      //   this.$gtag.event('q5');
      // }
      // else if (data.currentStep === 10) {
      //   this.$gtag.event('q10');
      // }
      // else if (data.currentStep === 15) {
      //   this.$gtag.event('q15');
      // }

    },
    onLastStep() {

      // todo: УДАЛИТЬ
      this.setResults();

      this.isBeforeResult = true;
      this.isResultReady = true;

      // this.$gtag.event('q_last');

    },
    onSkipStep(data) {
      this.currentQuestion = data.currentStep;

      // if (data.currentStep === 5) {
      //   this.$gtag.event('q5');
      // }
      // else if (data.currentStep === 10) {
      //   this.$gtag.event('q10');
      // }
      // else if (data.currentStep === 15) {
      //   this.$gtag.event('q15');
      // }
    },
    // Получаем ответ на вопрос
    onSetAnswer(data) {

      //console.log('data', data.answerResults[0]);

      const key = 'q' + this.dataArray[this.currentQuestion].id;
      // this.answersObject.key = data.answer;

      // Получаем варианты ответов на вопроы
      this.$set(this.answersObject, key, data.answerResults);

      // console.log('answersObject', this.answersObject);

      // this.$set(this.answersObject[key], data.answer);

      this.answers.push({
        id: this.dataArray[this.currentQuestion].id,
        answer: data.answer
      });
    },
    //endregion

    setResults() {
      const result = jobsRes[this.file].result(this.answersObject);

      this.resultTextarea1 = Vue.jobsHelpers.SexWord(sexWords, this.sex, result.resultTextarea1);
      this.resultTextarea2 = Vue.jobsHelpers.SexWord(sexWords, this.sex, result.resultTextarea2);

      this.resultTextarea1en = result.resultTextarea1en;
      this.resultTextarea2en = result.resultTextarea2en;
    },
    //
    // ObjectToString: function(obj,del,variant) {
    //   // Удаляем пустой элемент
    //   delete obj[del];
    //
    //   return Object.keys(obj)
    //       .map(function(k){
    //     if (variant === 1) {
    //       return obj[k].results[0].variant1['ru']
    //     } else {
    //       return obj[k].results[0].variant2['ru']
    //     }
    //   }).join(", ")
    // },
  }
}
</script>

<style scoped lang="scss">
.page {
  padding-top: 70px;
}
.btn_save {
  margin-top: 10px;
  margin-bottom: 20px;
  //margin: 10px 0 20px;
}
.result__buttons {
  opacity: 0;
  visibility: hidden;
}
</style>

<style lang="scss">
//todo: Сделать в вёрстке
.result__header {
  flex-wrap: wrap;
  text-align: center;
}

.result__title {
  @media only screen and (max-width: 470px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 402px) {
    font-size: 26px;
  }
}

.result__tabs {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 310px;
  margin-left: auto;
  margin-right: auto;
}

.result__tabs-item {
  display: block;
  text-decoration: none;
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  height: 33px;
  line-height: 33px;
  border-radius: 20px;
  text-align: center;
  width: 130px;
  background-color: #cccccc;
  cursor: pointer;

  @media only screen and (max-width: 390px) {
    width: 110px;
    font-size: 13px;
    height: 26px;
    line-height: 25px;
    border-radius: 15px;
  }

  &.js-active {
    background-color: #333333;
  }
}
</style>