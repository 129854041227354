<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let resultToChange = null;
      this.unusedVars = resultToChange;
      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1

      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      // О1, О2, О3
      if (answersObject.q1) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q1);

        // Опыт 1, О1
        if (answersObject.q1['1_1'] || answersObject.q1['1_2'] || answersObject.q1['1_3']) {
          this['resultTextarea' + variant + isEn] += 'Опыт ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ["1_4", "1_5"]);
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 2, О2
        if (answersObject.q1?.['1_4']) {
          this['resultTextarea' + variant + isEn] += answersObject.q1['1_4'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 3, О3
        if (answersObject.q1?.['1_5']) {
          this['resultTextarea' + variant + isEn] += answersObject.q1['1_5'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // О4, О5
      if (answersObject.q2) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q2);

        // Опыт 4, О4
        if (answersObject.q2['2_1'] || answersObject.q2['2_2'] || answersObject.q2['2_3'] || answersObject.q2['2_4']) {
          this['resultTextarea' + variant + isEn] += 'Опыт ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, "2_5");
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 5, О5
        if (answersObject.q2?.['2_5']) {
          this['resultTextarea' + variant + isEn] += answersObject.q2['2_5'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Холодные продажи
      if (answersObject.q3?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q3['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'Знание ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // личные качества
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q7, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2
      // Опыт работы
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'Понимаю алгоритм эффективного и коммерчески успешного взаимодействия по линии франчайзер – партнер.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "Умею смотреть на процесс организации продаж глазами обеих сторон."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "Вникаю в суть продукта и услуги, за которые несу ответственность. Стараюсь быть помощником одинаково хорошо как для собственника бизнеса, так и будущего франчайзи."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";

      // Открытие новых точек
      if (answersObject.q5?.['0'].text_result) {
        this['resultTextarea' + variant + isEn] += 'Имею опыт открытия ';
        this['resultTextarea' + variant + isEn] += answersObject.q5['0'].text_result;
        this['resultTextarea' + variant + isEn] += " ";
        this['resultTextarea' + variant + isEn] += "точек продаж абсолютно с нуля.";
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Ошибки
      if (answersObject.q8?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q8['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Достижения
      if (answersObject.q9?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q9['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Для тела, физическое
      if (answersObject.q10?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Для души, духовное
      if (answersObject.q11?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Принципы
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Командировки
      if (answersObject.q4?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q4['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // График
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском

      this['resultTextarea' + variant + isEn] += '<b>Professional skills:</b><br/><br/>';

      // О1, О2, О3
      if (answersObject.q1) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q1);

        // Опыт 1, О1
        if (answersObject.q1['1_1'] || answersObject.q1['1_2'] || answersObject.q1['1_3']) {
          this['resultTextarea' + variant + isEn] += 'Опыт ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ["1_4", "1_5"]);
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 2, О2
        if (answersObject.q1?.['1_4']) {
          this['resultTextarea' + variant + isEn] += answersObject.q1['1_4'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 3, О3
        if (answersObject.q1?.['1_5']) {
          this['resultTextarea' + variant + isEn] += answersObject.q1['1_5'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // О4, О5
      if (answersObject.q2) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q2);

        // Опыт 4, О4
        if (answersObject.q2['2_1'] || answersObject.q2['2_2'] || answersObject.q2['2_3'] || answersObject.q2['2_4']) {
          this['resultTextarea' + variant + isEn] += 'Experience ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, "2_5");
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 5, О5
        if (answersObject.q2?.['2_5']) {
          this['resultTextarea' + variant + isEn] += answersObject.q2['2_5'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Холодные продажи
      if (answersObject.q3?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q3['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'Knowledge of ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // личные качества
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += '<b>Professional skills:</b><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q7, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском

      // Опыт работы
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'I understand how to build truly effective and commercially successful relationship between franchisor and partner.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "I am able to look at the process of organizing sales through the eyes of both parties."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "I go into the essence of the product and service for which I am responsible. I try to be a genuine assistant to both the business owner and the future franchisee."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";

      // Открытие новых точек
      if (answersObject.q5?.['0'].text_result) {
        this['resultTextarea' + variant + isEn] += 'I have experience in opening ';
        this['resultTextarea' + variant + isEn] += answersObject.q5['0'].text_result;
        this['resultTextarea' + variant + isEn] += " ";
        this['resultTextarea' + variant + isEn] += "sales points absolutely from scratch.";
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Ошибки
      if (answersObject.q8?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q8['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Достижения
      if (answersObject.q9?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q9['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Для тела, физическое
      if (answersObject.q10?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Для души, духовное
      if (answersObject.q11?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Принципы
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Командировки
      if (answersObject.q4?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q4['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // График
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>