<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let resultToChange = null;
      this.unusedVars = resultToChange;
      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1

      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      //Опыт 1
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q1, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      if (answersObject.q2) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q2);

        // Опыт 2
        if (answersObject.q2['2_1'] || answersObject.q2['2_2'] || answersObject.q2['2_4'] || answersObject.q2['2_5']) {
          this['resultTextarea' + variant + isEn] += 'Опыт работы в ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, "2_3");
          this['resultTextarea' + variant + isEn] += ' ';
          this['resultTextarea' + variant + isEn] += 'стоматологии';
          this['resultTextarea' + variant + isEn] += '.<br/><br/>';
        }

        // Опыт 3
        if (answersObject.q2?.['2_3']) {
          this['resultTextarea' + variant + isEn] += answersObject.q2['2_3'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // личные качества 1
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q4, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2

      // Опыт работы
      if (answersObject.q5?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q5['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'Знаком с современными стоматологическими материалами и методиками лечения.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "Слежу за эргономикой кабинета, его рациональным обустройством."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";

      // Опыт А
      if (answersObject.q1?.['1_1']) {
        this['resultTextarea' + variant + isEn] += answersObject.q1['1_1'].results[0][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'Стремлюсь к тому, чтобы быть настоящей «правой рукой». Стараюсь опережать действия врача на шаг вперед.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";

      // Программы
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Имею опыт использования';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      //Личные качества 2
      if (answersObject.q4) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q4);
        if (answersObject.q4?.['4_1'] || answersObject.q4?.['4_4']) {
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(resultToChange, variant, language, ['4_2', '4_3', '4_5', '4_6', '4_7'], '<br/><br/>');
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Английский
      if (answersObject.q6?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q6['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском

      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском

      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  }
}

export default textResult

</script>
