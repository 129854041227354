<template>
  <main class="page__content container container_main">

    <DatesResults v-if="isDataLoaded && savedData && savedData.dates" :resultsObject="savedData.dates"></DatesResults>

    <jobs-results v-if="isDataLoaded && savedData && savedData.jobs"
                  :result1="savedData.jobs.result1"
                  :result1en="savedData.jobs.result1en"
                  :result2="savedData.jobs.result2"
                  :result2en="savedData.jobs.result2en"
                  :resultSubject="savedData.jobs.realSubject"
    ></jobs-results>

  </main>
</template>

<script>
import JobsResults from "@/components/JobsResults";
import DatesResults from "@/components/DatesResults";

export default {
  name: "About",
  components: {
    JobsResults,
    DatesResults
  },
  data() {
    return {
      savedData: {},
      isDataLoaded: false
    }
  },
  computed: {
    isDebug() {
      return this.$route.query.isDebug || false
    }
  },
  mounted() {

    //region Заполнение данных
    if (localStorage.getItem('savedData')) {
      this.savedData = JSON.parse(localStorage.getItem('savedData'));
      this.isDataLoaded = true;
    }
    //endregion

    // Перезагрузка страницы для повторного запроса access-check.js (см. todo)
    // todo: Переделать нормально. Быстрое решение для экономии времени. Нужно access-check дёргать каждый раз при загрузке в datesResults и jobsResults
    const isNeedReload = this.$route.query.isNeedReload;
    if (isNeedReload) {
      window.location = window.location.href.split("?")[0];
    }

  },
}
</script>

<style scoped lang="scss">

</style>