<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let resultToChange = null;
      this.unusedVars = resultToChange;
      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1
      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      // Опыт 0
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'Опыт разработки и реализации стратегии продвижения бренда в сфере ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт 1, О1
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'Навыки ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт 2
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q2, variant, language, '', '<br/><br/>');
        //this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт 3
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += 'Навыки ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q4, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы 1
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += 'Знание ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q7, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы 2
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += 'Знание ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q8, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы 3
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'Владение дополнительными инструментами разработки презентаций: ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // личные качества
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q10, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2
      // Опыт работы
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'Изучаю продукт, который продвигаю, от А до Я. Анализирую действия конкурентов и ситуацию на рынке.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';
      this['resultTextarea' + variant + isEn] += 'Стараюсь видеть картину целиком, избегать самоуспокоенности и видеть новые возможности.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += 'Постоянно думаю над тем, как улучшить позиционирование бренда – с точки зрения динамики спроса и экономии ресурсов компании.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += 'Разбираюсь в психологии потребителей, тригерах совершения покупки, принципах эмоциональной привязанности к бренду.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += 'Нацелен на плотное взаимодействие с разработчиками, производственным отделом и маркетологами – в интересах снижения всевозможных препятствий в цепочке продукт-клиент.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";

      // Опыт А
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q3, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт Б
      if (answersObject.q5) {
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q5, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Ошибки
      if (answersObject.q11?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Достижения
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // График
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском
      this['resultTextarea' + variant + isEn] += '<b>Professional skills:</b><br/><br/>';

      // Опыт 0
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'Experience developing and implementing a brand promotion strategy in ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт 1, О1
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'Skills in ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт 2
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q2, variant, language, '', '<br/><br/>');
        //this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт 3
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += 'Skills in ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q4, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы 1
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += 'Knowledge of ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q7, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы 2
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += 'Knowledge of ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q8, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы 3
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'Knowledge of additional presentation development tools: ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // личные качества
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Personal skills:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q10, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском
      // Опыт работы
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'I study the product that I\'m tasked with promoting from A to Z. I analyze the competition and their actions as well as the current situation on the market.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';
      this['resultTextarea' + variant + isEn] += 'I try to see the whole picture, avoid complacency, and remain open to new possibilities.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += 'I constantly think about how to improve brand positioning in terms of demand dynamics and saving company resources.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += 'I understand consumer psychology, purchase triggers, and the principles of emotional attachment to a brand.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += 'I know how critical it is to interact closely with developers, production department staff, and marketers to make sure we reduce all kinds of obstacles in the product-customer chain.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";

      // Опыт А
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q3, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт Б
      if (answersObject.q5) {
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q5, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Ошибки
      if (answersObject.q11?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Достижения
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // График
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>
