<template>
  <div class="auth">
    <div class="container">
      <div class="auth__form">
        <div class="auth__title">Восстановить</div>
        <div class="auth__text">Данные для входа в Ваш аккаунт</div>
        <div class="auth__body">

          <template v-if="isTokenError">
            <div class="auth__text-success">Извините, что-то пошло не так. Попробуйте запросить новый пароль</div>
            <div class="auth__link"><a href="#" @click.prevent="$router.push('/remind')">Восстановить пароль</a></div>

          </template>

          <template v-if="!isTokenError">
            <div class="auth__field">
              <input :type="fieldPasswordType" placeholder="Придумайте новый пароль" v-model="fieldPassword" @input="fieldPasswordCheck()">
              <div class="auth__password-switcher" @click="changePasswordType()"></div>
              <div class="auth__field-text auth__field-text_error" v-if="!isPasswordValid">Не менее 8 символов</div>
            </div>
            <div class="auth__field auth__field_middle-margin">
              <input :type="fieldPasswordConfirmationType" placeholder="Повторите новый пароль" v-model="fieldPasswordConfirmation" @input="fieldPasswordConfirmationCheck()">
              <div class="auth__password-switcher" @click="changePasswordConfirmationType()"></div>
              <div class="auth__field-text auth__field-text_error" v-if="!isPasswordConfirmationValid">Пароли не совпадают</div>
              <div class="auth__field-text auth__field-text_error" v-if="isPasswordGlobalError">Пожалуйста, проверьте пароли</div>
            </div>
            <div class="auth__button">
              <div class="btn btn_bg-dark" @click="changePassword()">Готово</div>
            </div>
          </template>

        </div>
      </div>
      <div class="auth__rules">Продолжая, Вы принимаете условия <router-link to="terms" target="_blank">Соглашения</router-link></div>

    </div>


    <div class="container">
      <div class="auth__form">
        <div class="auth__body">

        </div>
      </div>
    </div>

  </div>

</template>

<script>
// import {HTTP} from "@/http-common";
// import axios from 'axios'


export default {
  name: "RemindWithToken",
  props: ['token'],
  data() {
    return {
      fieldEmail:'',
      fieldPassword:'',
      fieldPasswordConfirmation:'',
      fieldPasswordType:'password',
      fieldPasswordConfirmationType:'password',
      passwordLengthNeed: 8,
      isPasswordValid: true,
      isPasswordConfirmationValid: true,
      isTokenError: true,
      isPasswordGlobalError: false
    }
  },
  computed: {
    // Классы для поля email
    isFieldsValid: function () {
      return this.isPasswordValid && this.isPasswordConfirmationValid
    },
  },
  methods: {
    // Проверка пароля
    fieldPasswordCheck() {
      if (this.fieldPassword.length < this.passwordLengthNeed) {
       this.isPasswordValid = false;
      } else {
        this.isPasswordValid = true;
      }
    },
    fieldPasswordConfirmationCheck() {
      if (this.fieldPasswordConfirmation.length < this.passwordLengthNeed) {
        this.isPasswordConfirmationValid = false;
      } else {
        this.isPasswordConfirmationValid = true;
      }
    },
    checkFieldPassword() {
      if (this.fieldPassword.length < this.passwordLengthNeed) {
        this.isPasswordError = true;
        this.isPasswordValid = false;
      } else {
        this.isPasswordError = false;
        this.isPasswordValid = true;
      }
    },
    changePasswordType() {
      if(this.fieldPasswordType === 'password') {
        this.fieldPasswordType = 'text'
      } else {
        this.fieldPasswordType = 'password'
      }
    },
    changePasswordConfirmationType() {
      if(this.fieldPasswordConfirmationType === 'password') {
        this.fieldPasswordConfirmationType = 'text'
      } else {
        this.fieldPasswordConfirmationType = 'password'
      }
    },

    changePassword() {

      // Перепроверяем пароли
      this.fieldPasswordCheck();
      this.fieldPasswordConfirmationCheck();

      if (this.isFieldsValid) {
        // HTTP.post('change-password', {
        // const instance = axios.create();
        // axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');

        // axios.post('change-password', {
        //   password: this.fieldPassword,
        //   password_confirmation: this.fieldPasswordConfirmation
        // })
        const password = this.fieldPassword;
        const password_confirmation = this.fieldPasswordConfirmation;

        this.$store.dispatch('changePassword', {password, password_confirmation})
            .then(resp => {
              // console.log('resp', resp);
              if (resp.status === 200) {
                this.isPasswordGlobalError = false;
                this.$router.push('/');
              }

            })
            .catch(err => {
              console.log('err', err);
              if (err.response.status === 422) {
                this.isPasswordGlobalError = true;
              } else {
                this.isTokenError = true;
              }

            })
      }

    },

  },
  mounted() {

    //region Отправялем токен и узнаём, можем ли мы восстановить пароль
    const token = this.$route.params.token;
    this.$store.dispatch('remind', {token})
        .then(() => {
          this.isTokenError = false
        })
        .catch(err => console.log(err))
    //endregion

    if (process.env.VUE_APP_TYPE === 'local') {
      this.fieldEmail = process.env.VUE_APP_USER_EMAIL
      // this.fieldPassword = 'password'
      // this.fieldPasswordConfirmation = 'password'
      // this.isPasswordValid = this
    }
  }
}
</script>

<style scoped>

</style>