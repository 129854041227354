<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      let resultToChange = null;
      this.unusedVars = resultToChange;
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1

      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      // Холодные продажи
      if (answersObject.q2?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q2['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Специализация
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'Опыт успешных ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += '-продаж';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // О5
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'Опыт продажи ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // О1
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Опыт ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // О2 и О3 внутри
      if (answersObject.q4) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q4);

        // Опыт 2
        if (answersObject.q4['4_1'] || answersObject.q4['4_2']) {
          this['resultTextarea' + variant + isEn] += 'Опыт ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ["4_3"]);
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 3 (О3)
        if (answersObject.q4?.['4_3']) {
          this['resultTextarea' + variant + isEn] += answersObject.q4['4_3'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

      }

      // О4
      if (answersObject.q5?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q5['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Госзакупки
      if (answersObject.q6?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q6['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Тендеры
      if (answersObject.q7?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q7['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += 'Знание ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q10, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q20?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q20['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества
      if (answersObject.q11) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q11, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2
      // Опыт работы
      if (answersObject.q19?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q19['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }


      this['resultTextarea' + variant + isEn] += 'Понимаю рынок, на котором работаю. Слежу за его развитием, действиями его ключевых игроков.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "Верю в то, что продаю. Вникаю в суть продукта, понимаю его достоинства и конкурентные преимущества."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";


      // Ошибки
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Достижения
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Физическое
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Духовное
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Шахматы
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Принципы
      if (answersObject.q17?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q17['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Мотивация
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // График
      if (answersObject.q21?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q21['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском
      this['resultTextarea' + variant + isEn] += '<b>Professional skills:</b><br/><br/>';

      // Холодные продажи
      if (answersObject.q2?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q2['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт успешных
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'Successful';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += 'sales';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // О5
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'Experience selling';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // О1
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Experience in';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // О2 и О3 внутри
      if (answersObject.q4) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q4);

        // Опыт 2
        if (answersObject.q4['4_1'] || answersObject.q4['4_2']) {
          this['resultTextarea' + variant + isEn] += 'Experience in';
          this['resultTextarea' + variant + isEn] += ' ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ["4_3"]);
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 3 (О3)
        if (answersObject.q4?.['4_3']) {
          this['resultTextarea' + variant + isEn] += answersObject.q4['4_3'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

      }

      // О4
      if (answersObject.q5?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q5['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Госзакупки
      if (answersObject.q6?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q6['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Тендеры
      if (answersObject.q7?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q7['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += 'Knowledge of ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q10, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q20?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q20['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества
      if (answersObject.q11) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Personal skills:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q11, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском
      // Опыт работы
      if (answersObject.q19?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q19['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }


      this['resultTextarea' + variant + isEn] += 'I understand the market I work in. I follow its development and the actions of its key stakeholders.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "I believe in what I sell. I go into the essence of the product and understand its merits and competitive advantages."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";


      // Ошибки
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Достижения
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Физическое
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Духовное
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Шахматы
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Принципы
      if (answersObject.q17?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q17['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Мотивация
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // График
      if (answersObject.q21?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q21['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>