<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let resultToChange = null;
      this.unusedVars = resultToChange;
      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1

      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      // Уже монтировали
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Опыт детализированной и разноплановой обработки изображений:';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Коллажирование
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += 'Опыт коллажирования';
        this['resultTextarea' + variant + isEn] += ' (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q4, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Цветокорреция
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'Опыт цветокоррекции и ретуширования';
        this['resultTextarea' + variant + isEn] += ' (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Редактирование
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += 'Опыт редактирования и стилизации';
        this['resultTextarea' + variant + isEn] += ' (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Дополнительный опыт
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'Знание основ и владение спецификой ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      //Английский
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // личные качества
      if (answersObject.q12) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q12, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2

      // Опыт работы
      if (answersObject.q11?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Делаете чаще
      if (answersObject.q5?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q5['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Делаете реже
      if (answersObject.q6?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q6['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Используете чаще
      if (answersObject.q7?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q7['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Используете реже
      if (answersObject.q8?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q8['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Восхизаетесь
      if (answersObject.q10?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }


      this['resultTextarea' + variant + isEn] += 'Искренне люблю свою работу. Считаю фоторетушь современным искусством.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "В каждом проекте использую творческий подход. Стараюсь применять неочевидные, смелые решения."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "Грамотно распределяю нагрузку, устанавливаю личные дедлайны для больших и небольших задач."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";

      // График
      if (answersObject.q14) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском

      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском

      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>
