<template>
  <li class="questionary__item questionary-item" :class="{'questionary-item_open': opened}">
    <div class="questionary-item__title">{{data.name}}</div>
    <!--<div class="questionary-item__toggles">-->
    <!--  <button class="questionary-item__toggle" :class="{'questionary-item__toggle_active': !isEng}" type="button" @click="isEng = false">Русский</button>-->
    <!--  <button class="questionary-item__toggle" :class="{'questionary-item__toggle_active': isEng}" type="button" @click="isEng = true">Английский</button>-->
    <!--</div>-->
    <div class="questionary-item__text" v-if="!isEng" v-html="data.version" ref="version"></div>
    <div class="questionary-item__text" v-if="isEng" v-html="data.version_en" ref="version_en"></div>

    <div class="questionary-item__actions">
      <button class="questionary-item__del" type="button" v-on:click="$emit('deleteRequest', data)">Удалить</button>

      <button class="questionary-item__copy" type="button" @click="copy" v-if="!isCopied">Скопировать</button>
      <button class="questionary-item__copy js-active" type="button" @click="copy" v-if="isCopied">Скопировано</button>

      <button class="questionary-item__lang" type="button" v-if="!isEng" @click="isEng = true">EN</button>
      <button class="questionary-item__lang" type="button" v-if="isEng" @click="isEng = false">RU</button>
    </div>
    <button class="questionary-item__more" type="button" @click="opened = !opened">Развернуть</button>
  </li>
</template>

<script>
export default {
  name: "SingleJob",
  props: ['data'],
  data() {
    return {
      opened: false,
      isEng: false,
      isCopied: false
    }
  },
  methods: {
    // Нажатие на "Скопировать"
    copy() {
      if (this.isEng){
        // this.$copyText(this.data.description_short_en);
        this.$copyText(this.$refs.version_en.innerText);
      } else {
        // this.$copyText(this.data.description_short);
        this.$copyText(this.$refs.version.innerText);
      }
      this.isCopied = true;
    }
  }
}
</script>

<style scoped>
.questionary-item__text {
  //min-height: 100px;
  //word-break: break-all;
}
</style>