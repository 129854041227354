<template>
  <div class="l-plans">
    <div class="l-container">
      <div class="l-plans__wrapper">


        <div class="l-plans__title">Оформить подписку</div>
        <!--<div class="l-plans__subtitle">Единый доступ к работе и знакомствам</div>-->

        <div class="l-plans__item">
          <!--<div class="l-plans__item-title">Для работы</div>-->
          <div class="l-plans__item-text">
            <ul>
              <li>50 профессий</li>
              <li>5000 уникальных фраз</li>
              <li>На русском и английском языках</li>
            </ul>
            <a href="#" @click="$router.push('/jobs')">Посмотреть профессии</a>
          </div>
          <!--<div class="l-plans__item-title">Для знакомств</div>-->
          <!--<div class="l-plans__item-text l-plans__item-text_small-margin">-->
          <!--  <ul>-->
          <!--    <li>15 разноплановых анкет</li>-->
          <!--    <li>1500 уникальных фраз</li>-->
          <!--    <li>На русском и английском языках</li>-->
          <!--  </ul>-->
          <!--</div>-->
          <a class="l-plans__item-button" href="#" @click.prevent.once="buy(1, 49)">Оформить</a>
<!--          <div class="l-plans__item-price"><i>990</i> <span>290</span> ₽ · 30 дн</div>-->
          <div class="l-plans__item-price">49 ₽ · 30 дн</div>
          <div class="l-plans__item-auto">без автопродления</div>
<!--          <div class="l-plans__item-auto">предложение действует 3 дня</div>-->
        </div>
        <!--<div class="l-plans__item">-->
        <!--  <div class="l-plans__item-title"><b>Best Me</b> Jobs</div>-->
        <!--  <div class="l-plans__item-text">-->
        <!--    <ul>-->
        <!--      <li>50 профессий</li>-->
        <!--      <li>5000 уникальных фраз</li>-->
        <!--      <li>На русском и английском языках</li>-->
        <!--      <li>Неограниченное количество опросов</li>-->
        <!--    </ul><a href="#" @click="$router.push('/jobs')">Посмотреть профессии</a>-->
        <!--  </div>-->
        <!--  <div class="l-plans__item-title">+ <b>Best Me</b> Dates</div>-->
        <!--  <div class="l-plans__item-text l-plans__item-text_small-margin">-->
        <!--    <ul>-->
        <!--      <li>15 разноплановых анкет</li>-->
        <!--      <li>1500 уникальных фраз</li>-->
        <!--      <li>На русском и английском языках</li>-->
        <!--    </ul>-->
        <!--  </div>-->
        <!--  &lt;!&ndash;<a class="l-plans__item-button" href="#" @click.prevent.once="buy(1, 249)">Купить</a>&ndash;&gt;-->
        <!--  <a class="l-plans__item-button" href="#" @click.prevent.once="buy(1, 249)">Купить</a>-->
        <!--  <div class="l-plans__item-price">249 ₽ · 30 дн</div>-->
        <!--  &lt;!&ndash;<div class="l-plans__item-price l-plans__item-price_old">249 ₽ · 30 дн</div>&ndash;&gt;-->
        <!--  &lt;!&ndash;<div class="l-plans__item-price">1 ₽ · 3 дн</div>&ndash;&gt;-->
        <!--  &lt;!&ndash;<div class="l-plans__item-price l-plans__item-price_additional">(без автопродления)</div>&ndash;&gt;-->
        <!--</div>-->
        <!--<div class="l-plans__item">-->
        <!--  <div class="l-plans__item-title"><b>Best Me</b> Dates</div>-->
        <!--  <div class="l-plans__item-text l-plans__item-text_small-margin">-->
        <!--    <ul>-->
        <!--      <li>5 разноплановых анкет</li>-->
        <!--      <li>500 уникальных фраз</li>-->
        <!--      <li>На русском языке</li>-->
        <!--    </ul>-->
        <!--  &lt;!&ndash;</div><a class="l-plans__item-button" href="#" @click.prevent.once="buy(2, 799)">Купить</a>&ndash;&gt;-->
        <!--  </div><a class="l-plans__item-button" href="#" @click.prevent.once="buy(2, 799)">Купить</a>-->
        <!--  <div class="l-plans__item-price">799 ₽ · 30 дн</div>-->
        <!--  &lt;!&ndash;<div class="l-plans__item-price l-plans__item-price_old">799 ₽ · 30 дн</div>&ndash;&gt;-->
        <!--  &lt;!&ndash;<div class="l-plans__item-price">1 ₽ · 3 дн</div>&ndash;&gt;-->
        <!--  &lt;!&ndash;<div class="l-plans__item-price l-plans__item-price_additional">(без автопродления)</div>&ndash;&gt;-->
        <!--</div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Payment",
  methods: {
    // Направление на покупку с главной страницы
    buy(type, amount) {
      if (!this.$store.getters.isAuthenticated) {
        this.$router.push({name: 'registration', params: {destination: 'pay', type: type, amount: amount}})
      } else {
        this.$router.push({name: 'pay', params: {destination: 'pay', type: type, amount: amount}})
      }
    },
  }
}
</script>

<style scoped>

.l-plans__item-price i {
  font-style: normal;
  text-decoration: line-through;
}
.l-plans__item-price span {
  color: orange;
}

.l-plans__item-price_additional {
  margin-top: 0;
  font-weight: 400;
}
.l-plans__item-auto {
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  margin-top: 5px;
  padding-left: 5px;
}

</style>