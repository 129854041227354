<template>
  <div class="page">
    <div class="container container_narrow">
      <h1 class="page__title">Выберите профессию</h1>
    </div>
    <main class="page__content container container_narrow">


      <div class="jobs">
        <div class="jobs__list jobs-list" v-for="(listItem, index) in jobs.list" v-bind:key="listItem.title" :class="{'jobs-list_show':openedArr[index] === true}">

          <div class="jobs-list__title" @click="open(index)">{{ listItem.name }}</div>
          <button class="jobs-list__show" type="button" @click="open(index)"></button>
          <div class="jobs-list__items">
            <ul>
              <li v-for="(item, index) in listItem.children" v-bind:key="index">
                <router-link :to="jobs.parentUrl + item.link">{{ item.title }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "JobsList",
  data() {
    return {
      // opened: null,
      openedArr: [false, false, false, false, false, false, false, false],
      jobs: {
        parentUrl: '/jobs/',
        list: [
          {
            'name': 'Продажи',
            'children': [
              {
                'title': 'Менеджер по продажам b2b',
                'link': 'b2b'
              },
              {
                'title': 'Менеджер по продажам b2c',
                'link': 'b2c'
              },
              {
                'title': 'Менеджер по продажам IT',
                'link': 'sales-it'
              },
              {
                'title': 'Менеджер по работе с маркетплейсами',
                'link': 'marketplace'
              },
              {
                'title': 'Менеджер по работе с торговыми сетями',
                'link': 'trading-networks'
              },
              {
                'title': 'Менеджер по продажам франшиз',
                'link': 'franchising'
              },
              {
                'title': 'Менеджер по работе с ключевыми клиентами',
                'link': 'key-account-manager'
              },
              {
                'title': 'Менеджер по работе с клиентами',
                'link': 'account-manager'
              },
              {
                'title': 'Оператор колл-центра',
                'link': 'call-center'
              },
              {
                'title': 'Оператор интернет-заказов',
                'link': 'online-orders'
              },
              {
                'title': 'Продавец-консультант',
                'link': 'adviser'
              }
            ]
          },
          {
            'name': 'Финансы',
            'children': [
              {
                'title': 'Экономист',
                'link': 'economist'
              },
              {
                'title': 'Бухгалтер',
                'link': 'accountant'
              },
              {
                'title': 'Бухгалтер по расчету заработной платы',
                'link': 'payroll'
              },
              {
                'title': 'Бухгалтер по первичной документации',
                'link': 'primary-documentation'
              }
            ]
          },
          {
            'name': 'Медперсонал',
            'children': [
              {
                'title': 'Стоматолог',
                'link': 'dentist'
              },
              {
                'title': 'Терапевт',
                'link': 'therapist'
              },
              {
                'title': 'Педиатр',
                'link': 'pediatrician'
              },
              {
                'title': 'Ассистент стоматолога',
                'link': 'dental-assistant'
              },
              {
                'title': 'Медсестра',
                'link': 'nurse-woman'
              },
              {
                'title': 'Медбрат',
                'link': 'nurse-man'
              },
              {
                'title': 'Санитар',
                'link': 'orderly'
              },
              {
                'title': 'Фармацевт-провизор',
                'link': 'pharmacist'
              },
              {
                'title': 'Фельдшер-лаборант',
                'link': 'laboratory'
              },
              {
                'title': 'Фельдшер скорой помощи',
                'link': 'ambulance'
              }
            ]
          },
          {
            'name': 'Маркетинг',
            'children': [
              {
                'title': 'Маркетинговый аналитик',
                'link': 'analyst'
              },
              {
                'title': 'Бренд-менеджер',
                'link': 'brand'
              },
              {
                'title': 'SMM-менеджер',
                'link': 'smm'
              },
              {
                'title': 'Менеджер по рекламе',
                'link': 'advertising'
              },
              // {
              //   'title': 'Интернет-маркетолог',
              //   'link': '#'
              // },
              // {
              //   'title': 'Пиар-менеджер',
              //   'link': '#'
              // }
            ]
          },
          {
            'name': 'Продакшн',
            'children': [
              {
                'title': 'Фотограф',
                'link': 'photographer'
              },
              {
                'title': 'Ретушер',
                'link': 'retoucher'
              },
              {
                'title': 'Видеооператор',
                'link': 'videographer'
              },
              {
                'title': 'Режиссер видеомонтажа',
                'link': 'video-editing'
              }
            ]
          },
          {
            'name': 'Дизайн',
            'children': [
              {
                'title': 'Графический дизайнер',
                'link': 'graphic-designer'
              },
              {
                'title': 'Веб-дизайнер',
                'link': 'web-designer'
              },
              {
                'title': 'Дизайнер интерьеров',
                'link': 'interior'
              },
              {
                'title': 'Моушн-дизайнер',
                'link': 'motion'
              }
            ]
          },
          {
            'name': 'АХО',
            'children': [
              {
                'title': 'Офис-менеджер',
                'link': 'office-manager'
              },
              {
                'title': 'Секретарь',
                'link': 'secretary'
              },
              {
                'title': 'Личный помощник',
                'link': 'assistant'
              },
              {
                'title': 'Делопроизводитель',
                'link': 'clerk'
              },
              {
                'title': 'Водитель',
                'link': 'driver'
              },
              {
                'title': 'Курьер',
                'link': 'courier'
              }
            ]
          },
          {
            'name': 'ИТ',
            'children': [
              {
                'title': 'Системный администратор',
                'link': 'system-administrator'
              },
              {
                'title': 'Frontend-разработчик',
                'link': 'frontend'
              },
              {
                'title': 'Backend-разработчик',
                'link': 'backend'
              },
              {
                'title': 'Верстальщик',
                'link': 'layout-maker'
              },
              {
                'title': 'QA',
                'link': 'qa'
              },
            ]
          },
        ]
      }
    }
  },
  methods: {
    open(listIndex) {
      if (this.openedArr[listIndex] === true) {
        this.$set(this.openedArr, listIndex, false)
      }
      else {
        this.$set(this.openedArr, listIndex, true)
      }
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.page {
  padding-top: 70px;
}
</style>