<template>
  <div class="page">
    <div class="examples">
      <div class="page__content container container_narrow">
        <div class="examples__item examples__item_manager">
          <div class="examples__item-title">Фотограф</div>
          <div class="js-tabs" data-initial="1">
            <div class="examples__item-toggles js-tabs__toggles"><a class="js-tabs__toggle_prev" href="#">Тезисно</a><a class="js-tabs__toggle_next active" href="#">Подробно</a></div>
            <div class="examples__item-texts">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div id="managerText1">
                      <p>Опыт разноплановой и многочасовой съемки: портрет, предмет, репортаж, реклама, интерьер, пейзаж, анималистика.</p>
                      <p>Опыт цветокоррекции и ретуширования (Photoshop, Capture One).</p>
                      <p>Опыт редактирования и стилизации (Illustrator, InDesign).</p>
                      <p>Понимание особенностей работы с пленочным фотоаппаратом.</p>
                      <p>Опыт продюссирования: разработка концепции, выбор локации, подготовка студии, подбор команды, скаутинг моделей.</p>
                      <p>Знание основ и владение спецификой видеосъемки, режиссуры монтажа, а также 2D и 3D анимации.</p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div id="managerText2" class="active">
                      <p>Занимаюсь фотографией более пяти лет.</p>
                      <p>Имею опыт съемки на пленочный фотоаппарат.</p>
                      <p>Люблю создавать атмосферные снимки, обладающие естественной глубиной (без искусственной цветокоррекции).</p>
                      <p>Специализируюсь на портретной съемке. Изучаю характер и манеры человека, стараюсь интуитивно улавливать его или ее задумчивость (а порой «безмыслие»).</p>
                      <p>Восхищаюсь работами Сары Мун. Мне нравится ее акварельный и, в какой-то степени, импрессионистский стиль.</p>
                      <p>Умею доносить свои идеи, грамотно и дипломатично защищать авторскую задумку.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="examples__delimiter">
        <div class="container container_narrow">Внутри каждой профессии<br>индивидуальная<br>лингвистическая<br>формула.</div>
      </div>
      <div class="page__content container container_narrow">
        <div class="examples__item examples__item_manager">
          <div class="examples__item-title">Продавец-консультант</div>
          <div class="js-tabs" data-initial="2">
            <div class="examples__item-toggles js-tabs__toggles"><a class="js-tabs__toggle_prev" href="#">Тезисно</a><a class="js-tabs__toggle_next active" href="#">Подробно</a></div>
            <div class="examples__item-texts">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div id="managerText3">
                      <p>Опыт приема и учета товара, выкладки продукции в торговом зале, контроля стоимости изделий и оперативной замены ценников.</p>
                      <p>Опыт поддержания чистоты и порядка в точке продаж, работы с кассовым аппаратом, ведения регулярной отчетности.</p>
                      <p>Знание базовых программ Microsoft Office.</p>
                      <p>Владение английским языком на уровне А1 (чтение и изъяснение будет для меня затруднительным).</p>
                      <p><br><br><b>Личные качества:</b><br></p>
                      <p>Дружелюбие. В общении с клиентом вежлива и тактична.</p>
                      <p>Самоконтроль. Умение совладать собой в стрессовых ситуациях.</p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div id="managerText4" class="active">
                      <p>Увлекаюсь интерьерным дизайном. Слежу за эргономикой точки продаж. Если замечаю неудобства, цветовые или иные несоответствия, вношу изменения.</p>
                      <p>Для меня всегда важен не только индивидуальный, но общекомандный результат. В прошлом сумела убедить собственника в пользе аромомаркетинга. Считаю, что приятный фоновый запах – залог увеличения продаж и повышения лояльности клиентов.</p>
                      <p>Отличаюсь высокой степенью организованности и самодисциплины. Понимаю, что даже малейшее опоздание может серьезно подвести коллег и иметь негативные последствия для бизнеса.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="examples__delimiter">
        <div class="container container_narrow">Благодаря Вашим ответам<br>слова, словосочетания<br>и целые фразы создают<br>изящный завершенный<br>текст.</div>
      </div>
      <div class="page__content container container_narrow">
        <div class="examples__item examples__item_manager">
          <div class="examples__item-title">Бухгалтер по расчету заработной платы</div>
          <div class="js-tabs" data-initial="3">
            <div class="examples__item-toggles js-tabs__toggles"><a class="js-tabs__toggle_prev" href="#">Тезисно</a><a class="js-tabs__toggle_next active" href="#">Подробно</a></div>
            <div class="examples__item-texts">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div id="managerText5">
                      <p>Опыт начисления и расчета заработной платы, отпускных, надбавок, командировочных, материальной помощи.</p>
                      <p>Опыт расчета удержаний по исполнительным листам, включая алименты.</p>
                      <p>Навыки расчета компенсаций за неиспользованный отпуск при увольнении, за работу в праздничные и выходные дни, при увольнении по соглашению сторон.</p>
                      <p>Навыки расчета пособий в связи с временной нетрудоспособностью, по уходу за ребенком в возрасте до одного года, а также до трех и пяти лет.</p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div id="managerText6" class="active">
                      <p>Обладаю навыками ведения участка по расчету заработной платы в компании среднего размера (численностью до 250 сотрудников).</p>
                      <p>Инициативна. Если вижу возможность для оптимизации, действую. Ранее сумела добиться внедрения в компании нового программного обеспечения, благодаря чему процесс расчета заработной платы и прочих начислений стал четче и быстрее.</p>
                      <p>Регулярно бегаю. Развиваю выносливость и самодисциплину.</p>
                      <p>Пишу стихи. Смогу составить нетривиальную поздравительную открытку в легкой рифмованной форме.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="examples__delimiter examples__delimiter_last">
        <div class="container container_narrow">Формула<br>составлена человеком.<br>Невидимым образом<br>она общается с Вами<br>на одном языке.<a class="examples__delimiter-link" href="#" @click="$router.push('/')">&lt;   вернуться на главную</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
// import { Swiper } from "vue-awesome-swiper";
// import 'swiper/css/swiper.css';
// import 'swiper/css';

function frontJs() {
  const jsTabs = [...document.querySelectorAll('.js-tabs')];
  jsTabs.map((el) => {
    const initialSwiperSlide = el.getAttribute('data-initial') ? Number(el.getAttribute('data-initial')) : 0;
    const swiperInstance = new Swiper(el.querySelector('.swiper-container'), {allowTouchMove: false, autoHeight: true, initialSlide: initialSwiperSlide,});
    el.querySelector('.js-tabs__toggle_prev').addEventListener('click', (e) => {
      e.preventDefault();
      if (e.target.classList.contains('active')) {
        return false;
      }
      el.querySelector('.js-tabs__toggles .active').classList.remove('active');
      e.target.classList.add('active');
      swiperInstance.slidePrev();
    });
    el.querySelector('.js-tabs__toggle_next').addEventListener('click', (e) => {
      e.preventDefault();
      if (e.target.classList.contains('active')) {
        return false;
      }
      el.querySelector('.js-tabs__toggles .active').classList.remove('active');
      e.target.classList.add('active');
      swiperInstance.slideNext();
    });
  });
}

export default {
  name: "Examples",
  mounted() {
    // todo: Переделать нормально
    frontJs()
  },
}
</script>

<style scoped>
.page {
  padding-top: 80px;
}
</style>