<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let resultToChange = null;
      this.unusedVars = resultToChange;
      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1

      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      // Опыт 1
      if (answersObject.q2) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q2);

        // Опыт 1
        if (answersObject.q2?.['2_1'] || answersObject.q2?.['2_2']) {
          this['resultTextarea' + variant + isEn] += 'Опыт ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ['2_3', '2_4', '2_5', '2_6']);
          this['resultTextarea' + variant + isEn] += ' ';
          this['resultTextarea' + variant + isEn] += 'в соответствии со стандартами медицинской помощи';
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 1.1
        if (answersObject.q2?.['2_3']) {
          this['resultTextarea' + variant + isEn] += answersObject.q2['2_3'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Опыт 2
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Опыт оказания медицинской помощи ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт 1
      if (answersObject.q2) {
        // Опыт 3
        if (answersObject.q2?.['2_6']) {
          this['resultTextarea' + variant + isEn] += answersObject.q2['2_6'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 4
        if (answersObject.q2?.['2_5']) {
          this['resultTextarea' + variant + isEn] += answersObject.q2['2_5'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 5
        if (answersObject.q2?.['2_4']) {
          this['resultTextarea' + variant + isEn] += answersObject.q2['2_4'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Опыт 6
      if (answersObject.q5?.['0'][variantFull]?.[language]) {
        if (!answersObject.q5['5_3']) {
          this['resultTextarea' + variant + isEn] += answersObject.q5['0'][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // личные качества
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q8, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2

      // Опыт работы
      if (answersObject.q9?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q9['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Главный опыт
      if (answersObject.q1?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q1['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт Б
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q4, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'Имею опыт использования ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Постоянные клиенты
      if (answersObject.q7?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q7['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q10?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт руководства
      if (answersObject.q11?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += 'Имею опыт руководства';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += 'медперсоналом';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском

      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском

      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>
