<template>
  <div class="container container_main">
    <div class="text-page">
      <a class="btn btn_back" href="#" @click="$router.push('/')">&lt; Назад</a>
      <h1>Произошла ошибка при платеже</h1>
      <p>Пожалуйста, <a href="#" @click="repay">попробуйте ещё раз</a> или свяжитесь с нами <a href="mailto: info@bestme.nl">info@bestme.nl</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentFail",
  mounted() {
    if (process.env.VUE_APP_TYPE === 'local') {
      console.log('Ошибка в платеже (PaymentFail)');
      console.log('this.$route.query', this.$route.query);
    }
    const orderId = this.$route.query.order_id;
    // const orderType = this.$route.query.type;
    const localOrderId = localStorage.getItem('premiumOrderId');
    // const localOrderType = localStorage.getItem('premiumType');
    if (orderId === localOrderId) {
      localStorage.removeItem('premiumOrderId');
      localStorage.removeItem('premiumType');
    }


  },
  methods: {
    repay() {
      const orderId = this.$route.query.order_id;
      this.$router.push({name:'pay', params: {type: orderId}});
    }
  }
}
</script>

<style scoped>
.text-page {
  padding-bottom: 80px;
}
</style>