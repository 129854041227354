<template>
  <li class="questionary__item questionary-item" :class="{'questionary-item_open': opened}">
    <div class="questionary-item__title">{{data.name}}</div>
    <div class="questionary-item__toggles">
      <button class="questionary-item__toggle" :class="{'questionary-item__toggle_active': isShort}" type="button" @click="isShort = true; isCopied = false">Тезисно</button>
      <button class="questionary-item__toggle" :class="{'questionary-item__toggle_active': !isShort}" type="button" @click="isShort = false; isCopied = false">Подробно</button>
    </div>
    <div class="questionary-item__text" v-if="isShort">
      <div v-if="!isEng" v-html="data.description_short" ref="description_short"></div>
      <div v-if="isEng">
        <div v-if="data.description_short_en" v-html="data.description_short_en" ref="description_short_en"></div>
        <div v-else>Для этой профессии результаты опроса пока доступны только на русском языке.</div>
      </div>
    </div>
    <div class="questionary-item__text" v-if="!isShort" v-bind:class="{'blur-container': !isAccess}">
      <div v-if="!isEng" v-html="data.description" ref="description"></div>

      <blur isJob v-if="!isAccess"></blur>

      <div v-if="isEng">
        <div v-if="data.description_en" v-html="data.description_en" ref="description_en"></div>
        <div v-else>Для этой профессии результаты опроса пока доступны только на русском языке.</div>
      </div>
    </div>

    <div class="questionary-item__actions">
      <button class="questionary-item__del" type="button" v-on:click="$emit('deleteRequest', data)">Удалить</button>

      <button class="questionary-item__copy" type="button" @click="copy" v-if="!isCopied">Скопировать</button>
      <button class="questionary-item__copy js-active" type="button" @click="copy" v-if="isCopied">Скопировано</button>

      <button class="questionary-item__lang" type="button" v-if="!isEng" @click="isEng = true; isCopied = false">EN</button>
      <button class="questionary-item__lang" type="button" v-if="isEng" @click="isEng = false; isCopied = false">Ru</button>
    </div>
    <button class="questionary-item__more" type="button" @click="opened = !opened">Развернуть</button>
  </li>
</template>

<script>
import Blur from "@/components/Blur";
export default {
  name: "SingleJob",
  components: {Blur},
  props: ['data'],
  data() {
    return {
      opened: false,
      isShort: false,
      isEng: false,
      isCopied: false,
      isAccess: true
    }
  },
  methods: {
    // Нажатие на "Скопировать"
    copy() {
      if (this.isShort) {
        if (this.isEng){
          // this.$copyText(this.data.description_short_en);
          this.$copyText(this.$refs.description_short_en.innerText);
        } else {
          // this.$copyText(this.data.description_short);
          this.$copyText(this.$refs.description_short.innerText);
        }
      } else {
        if (this.isEng){
          // this.$copyText(this.data.description_en);
          this.$copyText(this.$refs.description_en.innerText);

        } else {
          // this.$copyText(this.data.description);
          this.$copyText(this.$refs.description.innerText);

          // if (this.$refs.description.$el) {
          //   this.$copyText(this.$refs.description.$el.textContent);
          // }
        }
      }

      this.isCopied = true;

    }
  }
}

</script>

<style scoped>
.questionary-item__text {
  //min-height: 100px;
  //word-break: break-all;
}
</style>