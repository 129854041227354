<template>
  <header class="header" :class="[{header_open:isHeaderOpened}, headerFrontClass]">
    <div :class="headerContainerClass"><a class="header__logo" href="/" @click.prevent="$router.push('/')">{{ siteName }}</a>
      <div class="header__info" v-if="$store.getters.isAuthenticated && !isFront && !isLanding">{{ userText }}</div>
      <button class="header__burger-button" type="button" @click="openMenu()"><span>Меню</span></button>
      <nav class="header__menu">
        <ul>
          <li><a href="#" @click.prevent="clickMenuLink('jobs')"><b>Профессии</b></a></li>
          <!--<li><a href="#" @click.prevent="clickMenuLink('dates')"><b>Для знакомств</b></a></li>-->
          <template v-if="!this.$store.getters.getPremiumExpired">
            <li><a href="#" @click.prevent="clickMenuLink('pay')"><b>Оформить подписку</b></a></li>
          </template>
          <template v-if="$store.getters.isAuthenticated">
            <li><a href="#" @click.prevent="clickMenuLink('about')"><b>Мои описания</b> <span class="header__menu-counter">{{ count }}</span><div>Личный кабинет</div></a></li>
          </template>
          <template v-else>
            <li class="header__item-last"><a href="#" @click.prevent="clickMenuLink('about')"><b>Мои описания</b> <span class="header__menu-counter">{{ count }}</span><div>Личный кабинет</div></a></li>
            <li class="header__item-login"><a href="#" @click.prevent="clickMenuLink('login')">Войти</a></li>
            <li class="header__item-login"><a href="#" @click.prevent="clickMenuLink('registration')">Зарегистрироваться</a></li>
          </template>

        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import * as moment from "moment";
export default {
  name: "Menu",
  data() {
    return {
      isHeaderOpened: false,
      count: 0,
      headerFrontClass: '',
      headerContainerClass: 'container',
      siteName: "B.",
      premiumCountdown: null,
      ifHasPremium: false
    }
  },
  computed: {
    isFront() {
      return this.$route.name === 'Front'
    },
    isLanding() {
      return this.$route.name === 'Front' || this.$route.name === 'Chat'
    },
    userText: function () {
      let result = '';

      let type = null;
      // let typeText = null;
      if (this.$store.getters.getPremiumType) {
        type = parseInt(this.$store.getters.getPremiumType);
        if (type === 1) {
          // typeText = 'Jobs + Dates';
        }
        else if (type === 2) {
          // typeText = 'Dates';
        }
      }

      let countdown = null;
      if (this.$store.getters.getPremiumExpired) {
        countdown = moment(moment.now()).diff(moment(this.$store.getters.getPremiumExpired, "YYYY-MM-DD"), "days");
        countdown = Math.abs(countdown);
      }

      // if (typeText && countdown) {
      //   result = typeText + ' · ' + countdown + ' дн';
      // } else if (typeText) {
      //   result = typeText;
      // } else if (countdown) {
      //   result = countdown + ' дн';
      // }

      if (countdown) {
        result = countdown + ' дн';
      }

      return result
    }
  },
  watch: {
    userText: function () {
    },

    // $route (to,from){
    $route(to) {
      // console.log('to', to);
      // console.log('this.$route.name',this.$route.name);
      if (to.name === 'Front' || to.name === 'Chat') {
        this.headerFrontClass = 'header_landing'
        this.headerContainerClass = 'l-container'
        this.siteName = 'Best Me'
      }
      else {
        this.headerFrontClass = ''
        this.headerContainerClass = 'container'
        this.siteName = 'B.'
      }
    }
  },
  mounted() {
    if (this.$route.name === 'Front' || this.$route.name === 'Chat') {
      this.headerFrontClass = 'header_landing'
      this.headerContainerClass = 'l-container'
      this.siteName = 'Best Me'
    }

    // this.setPremiumInfo();
    //
    // this.$store.dispatch('setPremium');
    //
    // // Initialize inside mounted to ensure store is within scope
    // const { getters } = this.$store;
    //
    // const thisWorks = () => {
    //   const globalCompanies = getters.getPremiumType;
    //   console.log('globalCompanies', globalCompanies);
    //   // this.premiumType = getters.getPremiumType;
    // }

    // thisWorks();

    // this.premiumType = getters.getPremiumType;
    // this.setPremiumInfo();


  },
  methods: {
    // Клик по пункту меню
    clickMenuLink(link) {
      // console.log('click');
      const path = '/' + link;
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
      this.isHeaderOpened = false;
    },
    // Открываем меню
    openMenu() {
      this.isHeaderOpened = !this.isHeaderOpened;
      if (this.isHeaderOpened) {
        this.count = this.$store.getters.getUserSaved
      }
    },

  },

}
</script>

<style scoped lang="scss">
.header__menu li div {
  font-size: 15px;
}

.header__burger-button {
  height: 25px;
  width: 30px;
  span, &::before, &::after {
    height: 3px;
    width: 100%;
  }
  span {
    top: 11px;
  }
}

.header_open .header__burger-button {
  &::after {
    transform: translateY(2px) translateX(-2px) rotate(-45deg);
  }
}

</style>