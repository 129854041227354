import {router} from "./main.js"

import Front from "@/pages/Front";
import Dates from "@/pages/Dates";
import Jobs from "@/pages/Jobs";
import Auth from "@/pages/Auth";
import Remind from "@/pages/Remind";
import Registration from "@/pages/Registration";

import store from './store'
import Logout from "@/pages/Logout";
import Privacy from "@/pages/Privacy";
import RemindWithToken from "@/pages/RemindWithToken";
import NotFound from "@/pages/NotFound";
// import Chat from "@/pages/Chat";
import About from "@/pages/About";
import Terms from "@/pages/Terms";
import PaymentFail from "@/pages/PaymentFail";
import PaymentSuccess from "@/pages/PaymentSuccess";
import Pay from "@/pages/Pay";
import JobsQuestions from "@/pages/JobsQuestions";
import JobsList from "@/pages/JobsList";
import Faq from "@/pages/Faq";
import ConfirmEmail from "@/pages/ConfirmEmail";
import Security from "@/pages/Security";
import DatesSimple from "@/pages/DatesSimple";
import DatesHard from "@/pages/DatesHard";
import Examples from "@/pages/Examples";
import Admin from "@/pages/Admin";
import Want from "@/pages/Want";
import Latest from "@/pages/Latest";
import PayAction from "@/pages/PayAction";

// Если авторизован, запрещаем доступ к некоторым страницам
const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

// Если не авторизован, отправляем на авторизацию
const ifAuthenticated = (to, from, next) => {
  // console.log('localStorage.getItem(\'token\')', localStorage.getItem('token'));
  if (localStorage.getItem('token')) {
    // localStorage.setItem('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYTIwNTBkM2E2NjZmMzA2M2ZhNmNlODJmNTBlYzY1YzgwYzM5YWM2ZjllNzY5ODVlNWNlZTFlOWRjMWY5ZjRjOGI2OGNlNWRhNGVmYmExYzAiLCJpYXQiOjE2MDg5NzcxMDEsIm5iZiI6MTYwODk3NzEwMSwiZXhwIjoxNjQwNTEzMTAxLCJzdWIiOiIxNSIsInNjb3BlcyI6W119.ISOyal1If-vb5FzDZkvWadBkO8CttJwwnbf9rZfPT1B7x8HAmHhCaXZQnP8t5ivAvlpNRC1gS4tKkxUB_7jeA-xBkZNa22s3fTHfsvB-yrqPn3_FZO3r-tWBQ4Pg0GRU0QsdFjh6-svnKqjrB5CCcRsMe7bed-Sa7Vtqh2sx6DWg7i_Y96vgBmjSAECqH_EFSY3Y0uq3xTAr0HbObJexillZVRziXItf2XoDOBTGT-tLFnN-SJ5ToYA8rYdhv0ZCkEfkyejahQFoI9BcmrOXcGdj53x1kK8Z4ASHwEBoqrqMO3-dcNPBmMhyFCYBkfkcZkaTb9PpvrshKs2RjHEsebAAdxAfHU5Ju-UQM3S63iSffrh4HudFKPNG8GTrHHf1P9xapIYkUWp_6iGW-MU5hU1_Nt-sUr6E8oEdoL9jowAtEznuaWXOpJIhy0ivo-TW4yI28AqSbBhcD4gjtFc0F2fuMoxLJ3rfFO47GNQHR4AeZlgHYIEfOhNFbJmRrcw6rmo7lDMfJ0dpvPC8mlj3V7fDOtQ_g3oCmU3JYU4URxIUKJ4wUhGXxpvY_27y7jFI3ne7kRJUpn_QIqtT9_95HIRAweiP00SooKM_fsJFszbkzwvW5l0BsdUFBU1dlgyU1__swKDq9WvuOmUzq7QcpHSvBnjY7uLh8a1iAPbRLgo');
    // axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`}
    store.dispatch('checkToken');
    if (!localStorage.getItem('token')) {
      next('/registration')
    }
    next()
    // return
  } else {
    next('/registration')
  }
}

// Подписка на jobs + dates
// const ifPremium = (to, from, next) => {
//   let premiumType = 0;
//   if (localStorage.getItem('premiumOrderId')) {
//     // store.dispatch('setPremium', {order_id: localStorage.getItem('premiumOrderId')}).then((resp) => {
//     store.dispatch('setPremium').then((resp) => {
//       if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
//         console.log('Check "setPremium"', resp.data)
//       }
//       premiumType = resp.data.type;
//       if (premiumType === 1) {
//         next()
//         return
//       } else {
//         next('/pay')
//         return
//       }
//     }).catch(err => {
//       if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
//         console.log(err)
//         console.log(err.response);
//       }
//       // Токен закончился или не актуален. Отправляем на авторизацию
//       store.dispatch("logout")
//       next('/login')
//       return
//       // if (err.response.status) {
//       // }
//     });
//   } else {
//     if (!localStorage.getItem('token')) {
//       if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
//         console.log('not token');
//       }
//       next('/registration')
//       return
//     } else {
//       next('/pay')
//       return
//     }
//   }
//   // next('/')
// }

// todo: Отрефакторить
const isPayAccess = (to, from, next) => {
  let premiumType = 0;

  // Проверка на токен
  if (!localStorage.getItem('token')) {
    if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
      console.log('empty token (isPayAccess)');
    }
    // next('/registration')
    // return next(false)
    next()
    return
  }

  // if (localStorage.getItem('premiumOrderId')) {
    // store.dispatch('setPremium', {order_id: localStorage.getItem('premiumOrderId')}).then((resp) => {
    store.dispatch('setPremium').then((resp) => {
      if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
        console.log('Check "setPremium"', resp.data)
      }
      premiumType = resp.data.type;

      if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
        console.log(resp.data);
      }

      // Если подписка закончилась пускаем на /pay для оплаты
      if (resp.data.is_active === false) {
        next()
        return
      } else {
        if (premiumType === 1) {
          next('/want')
          return
        } else {
          next()
          return
        }
      }


    }).catch(err => {
      if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
        console.log(err)
        console.log(err.response);
      }
      // Токен закончился или не актуален. Отправляем на авторизацию
      if (err.response.status === 401) {
        // store.dispatch("logout")
        // next('/login')
        return
      }
    });
  // }

  next()
  return
}

// Проверка на админа
const isAdmin = (to, from, next) => {
  // Проверка на токен
  if (!localStorage.getItem('token')) {
    if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
      console.log('empty token');
    }
    next('/registration')
    return
  }

  store.dispatch('isAdminCheck').then((resp) => {
    if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
      console.log('Check "isAdmin"', resp.data)
      console.log(resp.data);
    }

    if (resp.data.isAdmin === true) {
      next()
      return
    }
    else {
      next('/')
    }
  }).catch(err => {
    if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
      console.log('routes isAdmin', err);
      console.log('routes isAdmin', err.response);
    }
    // Токен закончился или не актуален. Отправляем на авторизацию
    if (err.response.status === 401) {
      store.dispatch("logout")
      next('/login')
      return
    }
    else {
      next('/')
      return
    }
  });

  next()
  return
}

export const routes = [
  {
    path: "/",
    component: Front,
    name: "Front",
    meta: {
      title: "Best Me – Красиво о себе",
      metaTags: [
        {
          name: 'description',
          content: 'Генератор резюме, сопроводительного письма и даже анкеты для приложений знакомств. Сочинять о себе не нужно. Best Me всё сочинит за Вас.'
        },
        {
          property: 'og:description',
          content: 'Генератор резюме, сопроводительного письма и даже анкеты для приложений знакомств. Сочинять о себе не нужно. Best Me всё сочинит за Вас.'
        }
      ]
    }
  },
  {
    path: "/admin",
    component: Admin,
    name: "Admin",
    beforeEnter: isAdmin,
    meta: {
      title: "Административная панель",
    }
  },
  {
    name: "want",
    path: "/want",
    component: Want
  },
  {
    name: "latest",
    path: "/latest",
    component: Latest
  },
  {
    name: "dates",
    path: "/dates",
    // beforeEnter: ifAuthenticated && (ifPremium || ifPremium2),
    //beforeEnter: ifAuthenticated && ifPremium,
    component: Dates
  },
  // Версия попроще (с my.bestinme.ru)
  {
    name: "dates1",
    path: "/dates1",
    //beforeEnter: ifAuthenticated && ifPremium,
    component: DatesSimple,
    props: {
      isEnglish: true,
    },
  },
  // Версия посложнее
  {
    name: "dates2",
    path: "/dates2",
    //beforeEnter: ifAuthenticated && ifPremium,
    component: DatesHard,
    props: {
      isEnglish: true,
    },
  },
  {
    // Можно посмотреть анониму список
    path: "/jobs",
    component: Jobs,
    props: true,
    meta: {
      title: "Для работы"
    },
    children: [
      {
        name: "jobs",
        path: '',
        component: JobsList,
        props: true,
      },
      //region ПРОДАЖИ
      {
        path: 'b2b',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_B2B',
          subject: "Менеджер по продажам b2b"
        },
        meta: {
          title: "Менеджер по продажам b2b - Best Me Jobs"
        },
      },
      {
        path: 'b2c',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_B2C',
          subject: "Менеджер по продажам b2c"
        },
        meta: {
          title: "Менеджер по продажам B2C - Best Me Jobs"
        },
      },
      {
        path: 'sales-it',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_ИТ',
          subject: "Менеджер по продажам IT"
        },
        meta: {
          title: "Менеджер по продажам IT - Best Me Jobs"
        },
      },
      {
        path: 'account-manager',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Клиенты',
          subject: "Менеджер по работе с клиентами"
        },
        meta: {
          title: "Менеджер по работе с клиентами - Best Me Jobs"
        },
      },
      {
        path: 'key-account-manager',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Ключевые_клиенты',
          subject: "Менеджер по работе с ключевыми клиентами"
        },
        meta: {
          title: "Менеджер по работе с ключевыми клиентами - Best Me Jobs"
        },
      },
      {
        path: 'marketplace',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Маркетплейсы',
          subject: "Менеджер по работе с маркетплейсами"
        },
        meta: {
          title: "Менеджер по работе с маркетплейсами - Best Me Jobs"
        },
      },
      {
        path: 'online-orders',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Оператор_интернет_заказов',
          subject: "Оператор интернет-заказов"
        },
        meta: {
          title: "Оператор интернет-заказов - Best Me Jobs"
        },
      },
      {
        path: 'call-center',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Оператор_колл_центра',
          subject: "Оператор колл-центра"
        },
        meta: {
          title: "Оператор колл-центра - Best Me Jobs"
        },
      },
      {
        path: 'adviser',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Продавец_консультант',
          subject: "Продавец-консультант"
        },
        meta: {
          title: "Продавец-консультант - Best Me Jobs"
        },
      },
      {
        path: 'trading-networks',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Торговые_сети',
          subject: "Менеджер по работе с торговыми сетями"
        },
        meta: {
          title: "Менеджер по работе с торговыми сетями - Best Me Jobs"
        },
      },
      {
        path: 'franchising',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Франчайзинг',
          subject: "Менеджер по продаже франшиз"
        },
        meta: {
          title: "Менеджер по продаже франшиз - Best Me Jobs"
        },
      },
      //endregion
      //region АХО
      {
        path: 'driver',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_водитель',
          subject: "Водитель"
        },
        meta: {
          title: "Водитель - Best Me Jobs"
        },
      },
      {
        path: 'clerk',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_делопроизводитель',
          subject: "Делопроизводитель"
        },
        meta: {
          title: "Делопроизводитель - Best Me Jobs"
        },
      },
      {
        path: 'courier',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_курьер',
          subject: "Курьер"
        },
        meta: {
          title: "Курьер - Best Me Jobs"
        },
      },
      {
        path: 'assistant',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_личный_помощник',
          subject: "Личный помощник"
        },
        meta: {
          title: "Личный помощник - Best Me Jobs"
        },
      },
      {
        path: 'office-manager',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_офис_менеджер',
          subject: "Офис-менеджер"
        },
        meta: {
          title: "Офис-менеджер - Best Me Jobs"
        },
      },
      {
        path: 'secretary',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_секретарь',
          subject: "Секретарь"
        },
        meta: {
          title: "Секретарь - Best Me Jobs"
        },
      },
      //endregion
      //region МЕДПЕРСОНАЛ
      {
        path: 'dental-assistant',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Ассистент_Стоматолога',
          subject: "Ассистент стоматолога"
        },
        meta: {
          title: "Ассистент стоматолога - Best Me Jobs"
        },
      },
      {
        path: 'nurse-man',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Медбрат',
          subject: "Медбрат",
          preSex: 'man'
        },
        meta: {
          title: "Медбрат - Best Me Jobs"
        },
      },
      {
        path: 'nurse-woman',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Медсестра',
          subject: "Медсестра",
          preSex: 'woman'
        },
        meta: {
          title: "Медсестра - Best Me Jobs"
        },
      },
      {
        path: 'pediatrician',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Педиатр',
          subject: "Педиатр"
        },
        meta: {
          title: "Педиатр - Best Me Jobs"
        },
      },
      {
        path: 'orderly',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Санитар',
          subject: "Санитар"
        },
        meta: {
          title: "Санитар - Best Me Jobs"
        },
      },
      {
        path: 'dentist',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Стоматолог',
          subject: "Стоматолог"
        },
        meta: {
          title: "Стоматолог - Best Me Jobs"
        },
      },
      {
        path: 'therapist',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Терапевт',
          subject: "Терапевт"
        },
        meta: {
          title: "Терапевт - Best Me Jobs"
        },
      },
      {
        path: 'pharmacist',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Фармацевт',
          subject: "Фармацевт"
        },
        meta: {
          title: "Фармацевт - Best Me Jobs"
        },
      },
      {
        path: 'laboratory',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Фельдшер_Лаборант',
          subject: "Фельдшер-лаборант"
        },
        meta: {
          title: "Фельдшер-лаборант - Best Me Jobs"
        },
      },
      {
        path: 'ambulance',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Фельдшер_Скорой_Помощи',
          subject: "Фельдшер скорой помощи"
        },
        meta: {
          title: "Фельдшер скорой помощи - Best Me Jobs"
        },
      },
      //endregion
      //region ДИЗАЙН
      {
        path: 'web-designer',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Веб_дизайнер',
          subject: "Веб-дизайнер"
        },
        meta: {
          title: "Веб-дизайнер - Best Me Jobs"
        },
      },
      {
        path: 'graphic-designer',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Графический_дизайнер',
          subject: "Графический дизайнер"
        },
        meta: {
          title: "Графический дизайнер - Best Me Jobs"
        },
      },
      {
        path: 'interior',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Дизайнер_интерьеров',
          subject: "Дизайнер интерьеров"
        },
        meta: {
          title: "Дизайнер интерьеров - Best Me Jobs"
        },
      },
      {
        path: 'motion',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Моушн_дизайнер',
          subject: "Моушн-дизайнер"
        },
        meta: {
          title: "Моушн-дизайнер - Best Me Jobs"
        },
      },
      //endregion
      //region ИТ
      {
        path: 'system-administrator',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_сисадмин',
          subject: "Системный администратор"
        },
        meta: {
          title: "Системный администратор - Best Me Jobs"
        },
      },
      {
        path: 'frontend',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_frontend',
          subject: "Frontend-разработчик"
        },
        meta: {
          title: "Frontend-разработчик - Best Me Jobs"
        },
      },
      {
        path: 'backend',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_backend',
          subject: "Backend-разработчик"
        },
        meta: {
          title: "Backend-разработчик - Best Me Jobs"
        },
      },
      {
        path: 'layout-maker',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_верстальщик',
          subject: "Верстальщик"
        },
        meta: {
          title: "Верстальщик - Best Me Jobs"
        },
      },
      {
        path: 'qa',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_QA',
          subject: "QA"
        },
        meta: {
          title: "QA - Best Me Jobs"
        },
      },
      //endregion
      //region ФИНАНСЫ
      {
        path: 'primary-documentation',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Бухгалтер_по_первичной_документации',
          subject: "Бухгалтер по первичной документации"
        },
        meta: {
          title: "Бухгалтер по первичной документации - Best Me Jobs"
        },
      },
      {
        path: 'payroll',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Бухгалтер_по_расчету_заработной_платы',
          subject: "Бухгалтер по расчету заработной платы"
        },
        meta: {
          title: "Бухгалтер по расчету заработной платы - Best Me Jobs"
        },
      },
      {
        path: 'accountant',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Бухгалтер',
          subject: "Бухгалтер"
        },
        meta: {
          title: "Бухгалтер - Best Me Jobs"
        },
      },
      {
        path: 'economist',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Экономист',
          subject: "Экономист"
        },
        meta: {
          title: "Экономист - Best Me Jobs"
        },
      },
      //endregion
      //region МАРКЕТИНГ
      {
        path: 'brand',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Бренд_менеджер',
          subject: "Бренд-менеджер"
        },
        meta: {
          title: "Бренд-менеджер - Best Me Jobs"
        },
      },
      {
        path: 'analyst',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Маркетолог_аналитик',
          subject: "Маркетолог-аналитик"
        },
        meta: {
          title: "Маркетолог-аналитик - Best Me Jobs"
        },
      },
      {
        path: 'advertising',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Менеджер_по_рекламе',
          subject: "Менеджер по рекламе"
        },
        meta: {
          title: "Менеджер по рекламе - Best Me Jobs"
        },
      },
      {
        path: 'smm',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_SMM_менеджер',
          subject: "SMM-менеджер"
        },
        meta: {
          title: "SMM-менеджер - Best Me Jobs"
        },
      },
      //endregion
      //region ПРОДАКШН
      {
        path: 'video-editing',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_видеомонтаж',
          subject: "Режиссер видеомонтажа"
        },
        meta: {
          title: "Режиссер видеомонтажа - Best Me Jobs"
        },
      },
      {
        path: 'videographer',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_видеооператор',
          subject: "Видеооператор"
        },
        meta: {
          title: "Видеооператор - Best Me Jobs"
        },
      },
      {
        path: 'retoucher',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_Ретушер',
          subject: "Ретушер"
        },
        meta: {
          title: "Ретушер - Best Me Jobs"
        },
      },
      {
        path: 'photographer',
        component: JobsQuestions,
        //beforeEnter: ifAuthenticated && ifPremium,
        props: {
          file: 'Картотека_фотограф',
          subject: "Фотограф"
        },
        meta: {
          title: "Фотограф - Best Me Jobs"
        },
      },
      //endregion
      //region

      //endregion
    ]
  },
  // {
  //   path: "/chat",
  //   name: "Chat",
  //   component: Chat,
  //   meta: {
  //     title: "Best Me Chat"
  //   }
  // },
  {
    path: "/about",
    component: About,
    // beforeEnter: ifAuthenticated,
    meta: {
      title: "Мои описания"
    }
  },
  {
    name: "login",
    path: "/login",
    component: Auth,
    beforeEnter: ifNotAuthenticated,
    meta: {
      title: "Авторизация"
    }
  },
  {
    name: "registration",
    path: "/registration",
    component: Registration,
    beforeEnter: ifNotAuthenticated,
    meta: {
      title: "Регистрация"
    }
  },
  {
    name: "remind",
    path: "/remind",
    component: Remind,
    beforeEnter: ifNotAuthenticated,
    meta: {
      title: "Восстановление пароля"
    }
  },
  {
    path: "/reset-password/:token",
    component: RemindWithToken,
    // beforeEnter: ifNotAuthenticated,
    meta: {
      title: "Восстановление пароля"
    }
  },
  {
    name: "confirm-email",
    path: "/confirm-email/:hash",
    component: ConfirmEmail,
  },
  {
    name: "logout",
    path: "/logout",
    component: Logout,
    beforeEnter: ifAuthenticated,
    meta: {
      title: "Выход"
    }
  },
  {
    path: "/terms",
    component: Terms,
    meta: {
      title: "Соглашение"
    }
  },
  {
    path: "/faq",
    component: Faq,
    meta: {
      title: "Вопросы и ответы"
    }
  },
  {
    path: "/privacy",
    component: Privacy,
    meta: {
      title: "Политика конфиденциальности"
    }
  },
  {
    name: "pay",
    path: "/pay",
    beforeEnter: isPayAccess,
    component: Pay,
  },
  {
    name: "payAction",
    path: "/pay-action",
    beforeEnter: isPayAccess,
    component: PayAction,
  },
  {
    path: "/payment-success",
    component: PaymentSuccess,
    // beforeEnter: ifAuthenticated,
    meta: {
      title: "Платёж прошел успешно"
    }
  },
  {
    name: "paymentFail",
    path: "/payment-fail",
    component: PaymentFail,
    // beforeEnter: ifAuthenticated,
    meta: {
      title: "Ошибка при платеже"
    }
  },
  {
    path: "/security",
    component: Security,
    meta: {
      title: "Оплата и безопасность платежей"
    }
  },
  {
    path: "/examples",
    component: Examples,
    meta: {
      title: "Примеры"
    }
  },
  {
    path: '/.well-known/apple-developer-merchantid-domain-association',
    name: 'StripeApplePay',
    redirect: '/.well-known/apple-developer-merchantid-domain-association'
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: {
      title: "Страница не найдена"
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
];