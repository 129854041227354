<template>
  <div class="container container_main">

    <div class="lds-ring" v-if="!isLoaded"><div></div><div></div><div></div><div></div></div>

    <div class="question" v-if="isLoaded">
      <div class="question__wrapper">
        <div class="question__header">
          <div class="question__title">Вам ближе</div>
          <div class="question__subtitle">Это для Вашего описания</div>
        </div>
        <div class="question__variants question__variants_checkbox">
          <div class="question__item question-item" @click="$router.push({name:'dates2'})"><img src="img/content/dates1@2x.png" width="322" height="175"></div>
          <div class="question__item question-item" @click="$router.push({name:'dates1'})"><img src="img/content/dates2@2x.png" width="322" height="150"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Метрика и аналитика
// import Vue from "vue";
// import VueYandexMetrika from 'vue-yandex-metrika'
// import store from "@/store";
// Vue.use(VueYandexMetrika, {
//   id: 66407710,
//   env: process.env.VUE_APP_TYPE
//   // debug: true
// })

export default {
  name: "Dates",
  components: {
  },
  data() {
    return {
      isLoaded: false
    }
  },
  mounted() {

    this.isLoaded = true;

    // if (this.$store.getters.getPremiumOrder) {
    //   store.dispatch('setPremium', {order_id: this.$store.getters.getPremiumOrder}).then((resp) => {
    //     let premiumType = resp.data.type;
    //     if (premiumType === 2) {
    //       this.$router.push('dates3')
    //     }
    //     // Данные загружены
    //     this.isLoaded = true;
    //   });
    // } else if (this.$store.getters.getPremiumType === 2) {
    //   this.$router.push('dates3')
    // }


    if (process.env.VUE_APP_TYPE === 'local') {
      // this.isDebugMode = true;
    }
  }
}
</script>

<style scoped>
.question-item {
  cursor: pointer;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #333;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #333 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


</style>
