<template>
  <div class="container container_main">
    <div class="text-page">
      <a class="btn btn_back" href="#" @click="$router.push('/')">&lt; На главную</a>
      <h1>Ваш электронный ящик успешно подтверждён. Сейчас вы будете перенаправлены на главную страницу</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmEmail",
  props: ['hash'],
  computed: {
    isDebug() {
      return this.$route.query.isDebug || false
    },
  },
  mounted() {
    const hash = this.$route.params.hash;
    this.confirm(hash)
    console.log('hash', hash);
  },
  methods: {
    confirm(hash) {

      this.$store.dispatch('confirmEmail', { hash })
          .then((resp) => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log('resp', resp);
            }
            this.$router.push('/')
          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log(err)
              console.log(err.response);
            }
            this.$router.push('/')
          })
    }
  }
}
</script>

<style scoped>
.text-page {
  padding-bottom: 80px;
}
</style>