// слова для замены рода слова. Слово/словочетание в мужском роде должно стоять на 1 месте
export default [
  ['аккуратен', 'аккуратна'],
  ['активен', 'активна'],
  ['был', 'была'],
  ['Был', 'Была'],
  ['вежлив', 'вежлива'],
  ['Верстал', 'Верстала'],
  ['внимателен', 'внимательна'],
  ['внимательным', 'внимательной'],
  ['выбрал', 'выбрала'],
  ['вынослив', 'вынослива'],
  ['выполнял', 'выполняла'],
  ['Выработал', 'Выработала'],
  ['гибким', 'гибкой'],
  ['Готов', 'Готова'],
  ['готов', 'готова'],
  ['готовил', 'готовила'],
  ['дисциплинирован', 'дисциплинирована'],
  ['добивался', 'добивалась'],
  ['добился', 'добилась'],
  ['допускал', 'допускала'],
  ['допустил', 'допустила'],
  ['заключил', 'заключила'],
  ['Знаком', 'Знакома'],
  ['Инициативен', 'Инициативна'],
  ['Клиентоориентирован', 'Клиентоориентирована'],
  ['любознателен', 'любознательна'],
  ['мотивирован', 'мотивирована'],
  ['наблюдателен', 'наблюдательна'],
  ['настойчивым', 'настойчивой'],
  ['Нацелен', 'Нацелена'],
  ['обеспечивал', 'обеспечивала'],
  ['обрабатывал', 'обрабатывала'],
  ['оказывал', 'оказывала'],
  ['организован', 'организована'],
  ['осторожен', 'осторожна'],
  ['Осуществлял', 'Осуществляла'],
  ['педантичен', 'педантична'],
  ['первым', 'первой'],
  ['подтянул', 'подтянула'],
  ['Поклонник', 'Поклонница'],
  ['поклонником', 'поклонницей'],
  ['помешан', 'помешана'],
  ['помогал', 'помогала'],
  ['предусмотрительным', 'предусмотрительной'],
  ['Проанализировал', 'Проанализировала'],
  ['проводил', 'проводила'],
  ['профессионален', 'профессиональна'],
  ['проявил', 'проявила'],
  ['рад', 'рада'],
  ['разработал', 'разработала'],
  ['Расположен', 'Расположена'],
  ['рассудителен', 'рассудительна'],
  ['решал', 'решала'],
  ['санитаром', 'санитаркой'],
  ['Сверхорганизованн', 'Сверхорганизованна'],
  ['сделал', 'сделала'],
  ['сдержан', 'сдержана'],
  ['Склонен', 'Склонна'],
  ['сконцентрирован', 'сконцентрирована'],
  ['скрупулезен', 'скрупулезна'],
  ['смог', 'смогла'],
  ['собран', 'собрана'],
  ['соединил', 'соединила'],
  ['создал', 'создала'],
  ['Сообразителен', 'Сообразительна'],
  ['спланировал', 'спланировала'],
  ['Способен', 'Способна'],
  ['среагировал', 'среагировала'],
  ['Стал', 'Стала'],
  ['Сторонник', 'Сторонница'],
  ['Стрессоустойчив', 'Стрессоустойчива'],
  ['сумел', 'сумела'],
  ['тактичен', 'тактична'],
  ['Требователен', 'Требовательна'],
  ['Убежден', 'Убеждена'],
  ['уверен', 'уверена'],
  ['увеличивал', 'увеличивала'],
  ['упускал', 'упускала'],
  ['упустил', 'упустила'],
  ['успел', 'успела'],
  ['Устойчив', 'Устойчива'],
  ['Участвовал', 'Участвовала'],
  ['учтив', 'учтива'],
  ['Формировал', 'Формировала']
]

// // todo: Убрать повторы
// // слова для замены рода слова. Слово/словочетание в мужском роде должно стоять на 1 месте
// export default [
//   ['Нацелен', 'Нацелена'],
//   ['допускал', 'допускала'],
//   ['проявил', 'проявила'],
//   ['упускал', 'упускала'],
//   ['добился', 'добилась'],
//   ['сумел', 'сумела'],
//   ['Сторонник', 'Сторонница'],
//   ['активен', 'активна'],
//   ['Поклонник', 'Поклонница'],
//   ['мотивирован', 'мотивирована'],
//   ['Готов', 'Готова'],
//   ['Выработал', 'Выработала'],
//   ['сдержан', 'сдержана'],
//   ['рассудителен', 'рассудителена'],
//   ['смог', 'смогла'],
//   ['допустил', 'допустила'],
//   ['внимателен', 'внимательна'],
//   ['педантичен', 'педантична'],
//   ['выполнял', 'выполняла'],
//   ['собран', 'собрана'],
//   ['Осуществлял', 'Осуществляла'],
//   ['внимательным', 'внимательной'],
//   ['предусмотрительным', 'предусмотрительной'],
//   ['Стал', 'Стала'],
//   ['решал', 'решала'],
//   ['соединил', 'соединила'],
//   ['подтянул', 'подтянула'],
//   ['успел', 'успела'],
//   ['Убежден', 'Убеждена'],
//   ['готовил', 'готовила'],
//   ['проводил', 'проводила'],
//   ['заключил', 'заключила'],
//   ['настойчивым', 'настойчивой'],
//   ['гибким', 'гибкой'],
//   ['спланировал', 'спланировала'],
//   ['организовал', 'организовала'],
//   ['разработал', 'разработала'],
//   ['выполнял', 'выполняла'],
//   ['был', 'была'],
//   ['Знаком', 'Знакома'],
//   ['выбрал', 'выбрала'],
//   ['рад', 'рада'],
//   ['Был', 'Была'],
//   ['Верстал', 'Верстала'],
//   ['Формировал', 'Формировала'],
//   ['Участвовал', 'Участвовала'],
//   ['внимателен', 'внимательна'],
//   ['наблюдателен', 'наблюдательна'],
//   ['осторожен', 'осторожна'],
//   ['вежлив', 'вежлива'],
//   ['тактичен', 'тактична'],
//   ['Стрессоустойчив', 'Стрессоустойчива'],
//   ['вынослив', 'вынослива'],
//   ['обрабатывал', 'обрабатывала'],
//   ['оказывал', 'оказывала'],
//   ['обеспечивал', 'обеспечивала'],
//   ['санитаром', 'санитаркой'],
//   ['Клиентоориентирован', 'Клиентоориентирована'],
//   ['профессионален', 'профессиональна'],
//   ['Сообразителен', 'Сообразительна'],
//   ['Способен', 'Способна'],
//   ['любознателен', 'любознательна'],
//   ['Устойчив', 'Устойчива'],
//   ['Сверхорганизованн', 'Сверхорганизованна'],
//   ['дисциплинирован', 'дисциплинирована'],
//   ['первым', 'первой'],
//   ['увеличивал', 'увеличивала'],
//   ['добивался', 'добивалась'],
//   ['сконцентрирован', 'сконцентрирована'],
//   ['скрупулезен', 'скрупулезна'],
//   ['Расположен', 'Расположена'],
//   ['аккуратен', 'аккуратна'],
//   ['организован', 'организована'],
//   ['Склонен', 'Склонна'],
//   ['Выработал', 'Выработала'],
//   ['учтив', 'учтива'],
//   ['среагировал', 'среагировала'],
//   ['сделал', 'сделала'],
//   ['упустил', 'упустила'],
//   ['помешан', 'помешана'],
//   ['поклонником', 'поклонницей'],
//   ['Требователен', 'Требовательна'],
//   ['Инициативен', 'Инициативна'],
// ]
