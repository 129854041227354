<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let resultToChange = null;
      this.unusedVars = resultToChange;
      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1

      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      // Adobe
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'Опыт работы с инструментами Adobe:';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Проектирование
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += 'Опыт работы с инструментами проектирования:';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Верстка 1, Верстка 2
      if (answersObject.q11) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q11);

        // Верстка 1
        if (answersObject.q11['11_1'] || answersObject.q11['11_2'] || answersObject.q11['11_3'] || answersObject.q11['11_4'] || answersObject.q11['11_5'] || answersObject.q11['11_6'] || answersObject.q11['11_7'] || answersObject.q11['11_8'] || answersObject.q11['11_9']) {
          this['resultTextarea' + variant + isEn] += 'Опыт верстки на ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ["11_10"]);
          this['resultTextarea' + variant + isEn] += '.<br/><br/>';
        }

        // Верстка 2
        if (answersObject.q11?.['11_10']) {
          this['resultTextarea' + variant + isEn] += answersObject.q11['11_10'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

      }

      // HTML
      if (answersObject.q12) {
        this['resultTextarea' + variant + isEn] += 'Знание ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q12, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Рисунок (иллюстрации)
      if (answersObject.q3?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q3['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Планшет
      if (answersObject.q4?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q4['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Таск-трекеры
      if (answersObject.q14) {
        this['resultTextarea' + variant + isEn] += 'Опыт работы с таск-трекерами:<br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q14, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'Знание базовых программ Microsoft Office и инструментов Google.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      // Опыт руководства, Опыт делегирования
      if (answersObject.q15 && answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества
      if (answersObject.q17) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q17, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2
      // Опыт работы
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Уже делали
      if (answersObject.q5) {
        this['resultTextarea' + variant + isEn] += 'Имею опыт создания ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q5, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Увлекает больше всего
      if (answersObject.q6?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q6['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Также интересно
      if (answersObject.q7?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q7['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Сильная компетенция
      if (answersObject.q8?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q8['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Предпочтение 1
      if (answersObject.q9?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q9['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Предпочтение 2
      if (answersObject.q10?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт делегирования, Опыт руководства
      if (answersObject.q15 && answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества 2
      if (answersObject.q17) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q17);

        if (answersObject.q17['17_4'] || answersObject.q17['17_5'] || answersObject.q17['17_7']) {
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(resultToChange, variant, language, ['17_1', '17_2', '17_3', '17_6', '17_8'], '<br/><br/>');
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // График
      if (answersObject.q19) {
        this['resultTextarea' + variant + isEn] += answersObject.q19['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском
      this['resultTextarea' + variant + isEn] += '<b>Professional skills:</b><br/><br/>';

      // Adobe
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'Experience with Adobe:';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Проектирование
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += 'Experience with UX/UI design tools:';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Верстка 1, Верстка 2
      if (answersObject.q11) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q11);

        // Верстка 1
        if (answersObject.q11['11_1'] || answersObject.q11['11_2'] || answersObject.q11['11_3'] || answersObject.q11['11_4'] || answersObject.q11['11_5'] || answersObject.q11['11_6'] || answersObject.q11['11_7'] || answersObject.q11['11_8'] || answersObject.q11['11_9']) {
          this['resultTextarea' + variant + isEn] += 'Experience in HTML publishing using ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ["11_10"]);
          this['resultTextarea' + variant + isEn] += '.<br/><br/>';
        }

        // Верстка 2
        if (answersObject.q11?.['11_10']) {
          this['resultTextarea' + variant + isEn] += answersObject.q11['11_10'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

      }

      // HTML
      if (answersObject.q12) {
        this['resultTextarea' + variant + isEn] += 'Knowledge of ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q12, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Рисунок (иллюстрации)
      if (answersObject.q3?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q3['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Планшет
      if (answersObject.q4?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q4['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Таск-трекеры
      if (answersObject.q14) {
        this['resultTextarea' + variant + isEn] += 'Experience using task trackers:<br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q14, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'Knowledge of basic Microsoft Office and Google Docs.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      // Опыт руководства, Опыт делегирования
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества
      if (answersObject.q17) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Personal skills:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q17, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском
      // Опыт работы
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Уже делали
      if (answersObject.q5) {
        this['resultTextarea' + variant + isEn] += 'I have experience in creating ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q5, variant, language);
        this['resultTextarea' + variant + isEn] += '.<br/><br/>';
      }

      // Увлекает больше всего
      if (answersObject.q6?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q6['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Также интересно
      if (answersObject.q7?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q7['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Сильная компетенция
      if (answersObject.q8?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q8['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Предпочтение 1
      if (answersObject.q9?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q9['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Предпочтение 2
      if (answersObject.q10?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт делегирования, Опыт руководства
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества 2
      if (answersObject.q17) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q17);

        if (answersObject.q17['17_4'] || answersObject.q17['17_5'] || answersObject.q17['17_7']) {
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(resultToChange, variant, language, ['17_1', '17_2', '17_3', '17_6', '17_8'], '<br/><br/>');
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }




      // График
      if (answersObject.q19) {
        this['resultTextarea' + variant + isEn] += answersObject.q19['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>
