<template>
  <div>
    <div class="result result_1" v-if="isShowResults[0]">
      <div class="result__wrapper">

        <button @click="getAccess()">reload</button>

        <div class="result__header">
          <div class="result__title">Вариант 1</div>

          <div class="result__buttons">
            <div class="result__pagination">
              <div class="result__pagination-item result__pagination-item_dot js-active" @click="changeResultPager(1)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(2)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(3)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(4)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(5)"></div>
            </div>
            <div class="btn btn_result-nav btn_result-nav-next" @click="changeResult(1, 'next')"></div>
          </div>
        </div>

        <div class="result__text" ref="result1" v-bind:class="language === 'en' ? 'js-nodisplay' : ''">
          <div v-html="resultsObject.result1"></div>
          <div v-bind:class="{'blur-container ': !isAccess}" >
            <blur isFirst v-if="!isAccess"></blur>
          </div>
        </div>

        <div class="result__text" ref="result1en" v-bind:class="language === 'ru' ? 'js-nodisplay' : ''">
          <div v-html="resultTextarea1en"></div>
        </div>

      </div>
    </div>

    <div class="result result_2" v-if="isShowResults[1]">
      <div class="result__wrapper">

        <div class="result__header">
          <div class="result__title">Вариант 2</div>
          <div class="result__buttons">
            <div class="btn btn_result-nav btn_result-nav-prev" @click="changeResult(2, 'prev')"></div>
            <div class="result__pagination">
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(1)"></div>
              <div class="result__pagination-item result__pagination-item_dot js-active" @click="changeResultPager(2)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(3)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(4)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(5)"></div>
            </div>
            <div class="btn btn_result-nav btn_result-nav-next" @click="changeResult(2, 'next')"></div>
          </div>
        </div>
        <div class="result__text" ref="result2" v-bind:class="[{'blur-container ': !isAccess}, language === 'en' ? 'js-nodisplay' : '']">
          <div v-html="resultsObject.result2"></div>
          <blur v-if="!isAccess"></blur>
        </div>

        <div class="result__text" ref="result2en" v-bind:class="language === 'ru' ? 'js-nodisplay' : ''">
          <div v-html="resultTextarea2en"></div>
        </div>

      </div>
    </div>

    <div class="result result_3" v-if="isShowResults[2]">
      <div class="result__wrapper">

        <div class="result__header">
          <div class="result__title">Вариант 3</div>
          <div class="result__buttons">
            <div class="btn btn_result-nav btn_result-nav-prev" @click="changeResult(3, 'prev')"></div>
            <div class="result__pagination">
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(1)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(2)"></div>
              <div class="result__pagination-item result__pagination-item_dot js-active" @click="changeResultPager(3)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(4)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(5)"></div>
            </div>
            <div class="btn btn_result-nav btn_result-nav-next" @click="changeResult(3, 'next')"></div>
          </div>
        </div>

        <div class="result__text" ref="result3" v-bind:class="[{'blur-container ': !isAccess}, language === 'en' ? 'js-nodisplay' : '']">
          <div v-html="resultsObject.result3"></div>
          <blur v-if="!isAccess"></blur>
        </div>

        <div class="result__text" ref="result3en" v-bind:class="language === 'ru' ? 'js-nodisplay' : ''">
          <div v-html="resultTextarea3en"></div>
        </div>

      </div>

    </div>

    <div class="result result_4" v-if="isShowResults[3]">
      <div class="result__wrapper">

        <div class="result__header">
          <div class="result__title">Вариант 4</div>
          <div class="result__buttons">
            <div class="btn btn_result-nav btn_result-nav-prev" @click="changeResult(4, 'prev')"></div>
            <div class="result__pagination">
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(1)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(2)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(3)"></div>
              <div class="result__pagination-item result__pagination-item_dot js-active" @click="changeResultPager(4)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(5)"></div>
            </div>
            <div class="btn btn_result-nav btn_result-nav-next" @click="changeResult(4, 'next')"></div>
          </div>
        </div>

        <div class="result__text" ref="result4" v-bind:class="[{'blur-container ': !isAccess}, language === 'en' ? 'js-nodisplay' : '']">

          <div v-html="resultsObject.result4"></div>
          <blur v-if="!isAccess"></blur>
        </div>

        <div class="result__text" ref="result4en" v-bind:class="language === 'ru' ? 'js-nodisplay' : ''">
          <div v-html="resultTextarea4en"></div>
        </div>

      </div>
    </div>

    <div class="result result_5" v-if="isShowResults[4]">
      <div class="result__wrapper">

        <div class="result__header">
          <div class="result__title">Вариант 5</div>
          <div class="result__buttons">
            <div class="btn btn_result-nav btn_result-nav-prev" @click="changeResult(5, 'prev')"></div>
            <div class="result__pagination">
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(1)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(2)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(3)"></div>
              <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(4)"></div>
              <div class="result__pagination-item result__pagination-item_dot js-active" @click="changeResultPager(5)"></div>
            </div>
          </div>
        </div>

        <div class="result__text" ref="result5" v-bind:class="[{'blur-container ': !isAccess}, language === 'en' ? 'js-nodisplay' : '']">
          <div v-html="resultsObject.result5"></div>
          <blur v-if="!isAccess"></blur>
        </div>

        <div class="result__text" ref="result5en" v-bind:class="language === 'ru' ? 'js-nodisplay' : ''">
          <div v-html="resultTextarea5en"></div>
        </div>

        <div class="result__buttons result__buttons_full" v-if="isAccess">
          <!--<div class="btn btn_grey btn_grey_big btn_save_all" @click="changeResult(5, 'next')">Сохранить все варианты</div>-->

          <div class="btn btn_grey btn_save_all" @click.once="saveResult" v-if="!isSaved">Сохранить</div>
          <div class="btn btn_grey btn_save_all js-active" type="button" v-if="isSaved">Сохранено</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import * as moment from "moment";
import * as checkAccess from "@/plugins/access-check";
import Blur from "@/components/Blur";

export default {
  name: "DatesResults",
  props: {
    resultsObject: Object
  },
  components: {
    Blur
  },
  data() {
    return {
      isNeed: false,

      // Данные
      dataArray: null,
      resultsTitles: [],

      // Debug
      isDebugMode: false,
      isDebugShow: false,
      isShowDebugBtn: false,
      isDebugAnswersShow: false,

      // Пол
      sex: null,

      // Язык
      language: "ru",

      // Цель
      goalSelected: null,
      goalSelectedName: null,
      goals: [
        {name: 'Любовь', value: 'love', description: 'В перспективе - семья'},
        {name: 'Общение', value: 'friendship', description: 'Возможно, близкое'},
        {name: 'Встречи', value: 'meet', description: 'Без обязательств'}
      ],


      // Вход
      isEntered: false,

      // Дан ли ответ на главный вопрос
      isQuestionMain1: false,
      isQuestionMain2: false,
      isQuestionMain3: false,
      isQuestionMain4: false,
      isQuestionMain5: false,

      // Текущий вопрос
      currentQuestion: 0,


      // Показ результатов
      isResultReady: false,
      isBeforeResult: false,

      // возраст
      user: {
        day: null,
        month: null,
        year: null,
      },
      age: 0,
      ageDenied: false,
      // рост
      growth: null,
      // Случайные фразы для варианта 4
      customPhrase: ['- Я не похож на Киллиана Мерфи.',
        '- Я не похож на Тома Харди.',
        '- Я не похож на Джейка Джилленхола.',
        '- Я не похож на Криса Хемсворта.',
        '- Я не похож на Генри Кавилла.',
        '- Я не похож на Криса Пайна.',
        '- Я не похож на Райана Гослинга.',
        '- Я не похож на Криса Эванса.',
        '- Я не похож на Дэвида Бекхэма.'
      ],
      customPhraseEn: ['- Don’t look like Cillian Murphy.',
        '- Don’t look like Tom Hardy.',
        '- Don’t look like Jake Gyllenhaal.',
        '- Don’t look like Chris Hemsworth.',
        '- Don’t look like Henry Cavill.',
        '- Don’t look like Chris Pine.',
        '- Don’t look like Ryan Gosling.',
        '- Don’t look like Chris Evans.',
        '- Don’t look like David Beckham.'
      ],
      answers: [],
      answersObject: {},

      // Текущий прогресс вопросов
      currentProgress: 0,

      //region Результаты
      // Показ шагов
      isShowResults: [true, false, false, false, false],
      //region textarea с результатами
      resultSubject: null,
      resultTextarea1: null,
      resultTextarea2: null,
      resultTextarea3: null,
      resultTextarea4: null,
      resultTextarea5: null,
      resultTextarea1en: null,
      resultTextarea2en: null,
      resultTextarea3en: null,
      resultTextarea4en: null,
      resultTextarea5en: null,
      //endregion
      // Сохраните все результаты
      isShowResultAll: false,
      // Отправить на email
      isShowResultEmail: false,
      // Отправлено на email
      isShowResultSent: false,
      //endregion

      // Отправка формы
      ajaxRequest: false,
      postResults: [],

      // Сохраненное
      isSaved: false,

      // Доступ
      isAccess: false

    };
  },
  methods: {

    setResults() {
    if (this.sex === 'man') {
      if (this.goalSelected === 'love') {

        //region Результат 1
        this.resultTextarea1 = this.growth + ' см.';
        this.resultTextarea1 += ' ';
        this.resultTextarea1 += this.answersObject.q2 ? this.answersObject.q2[0].variant1.ru + ' и ' : '';
        this.resultTextarea1 += this.answersObject.q3 ? this.answersObject.q3[0].variant1.ru + ' мужчина. ' : '';
        this.resultTextarea1 += 'Ищу интересную девушку для серьезных отношений. ';
        this.resultTextarea1 += this.answersObject.q4 ? this.answersObject.q4[0].variant1.ru : '';
        this.resultTextarea1 += this.answersObject.q5 ? ' ' + this.answersObject.q5[0].variant1.ru : '';
        this.resultTextarea1 += this.answersObject.q6 ? ' ' + this.answersObject.q6[0].variant1.ru : '';
        this.resultTextarea1 += this.answersObject.q7 ? ' Небольшая слабость - ' + this.answersObject.q7[0].variant1.ru : '';
        this.resultTextarea1 += this.answersObject.q8 ? ' В девушке ценю ' + this.answersObject.q8[0].variant1.ru + ' и ' : '';
        this.resultTextarea1 += this.answersObject.q9 ? this.answersObject.q9[0].variant1.ru + '.' : '';
        //endregion

        //region Результат 1 Английский
        this.resultTextarea1en = this.growth + ' cm.';
        this.resultTextarea1en += ' ';
        this.resultTextarea1en += this.answersObject.q2 ? this.answersObject.q2[0].variant1.en + ' and ' : '';
        this.resultTextarea1en += this.answersObject.q3 ? this.answersObject.q3[0].variant1.en + '. ' : '';
        this.resultTextarea1en += 'Looking for serious relationship. ';
        this.resultTextarea1en += this.answersObject.q4 ? this.answersObject.q4[0].variant1.en + '.' : '';
        this.resultTextarea1en += this.answersObject.q5 ? ' ' + this.answersObject.q5[0].variant1.en + '.' : '';
        this.resultTextarea1en += this.answersObject.q6 && this.answersObject.q6[0].variant1.en ? ' ' + this.answersObject.q6[0].variant1.en + '.' : '';
        this.resultTextarea1en += this.answersObject.q7 ? this.answersObject.q7[0].variant1.en + ' – is my indulgence.' : '';
        this.resultTextarea1en += this.answersObject.q8 ? ' Hope to find a girl who’s ' + this.answersObject.q8[0].variant1.en + ' and ' : '';
        this.resultTextarea1en += this.answersObject.q9 ? this.answersObject.q9[0].variant1.en + '.' : '';
        //endregion

        //region Результат 2
        this.resultTextarea2 = this.growth + ' см. ';
        //{{ /* Работа */ }}
        this.resultTextarea2 += this.answersObject.q1 ? this.answersObject.q1[1].variant2.ru + '<br><br>' : '';
        // {{ /* X1 */ }}
        this.resultTextarea2 += this.answersObject.q2 ? this.answersObject.q2[1].variant2.ru + '<br><br>' : '';
        // {{ /* С1 */ }}
        this.resultTextarea2 += this.answersObject.q4 ? this.answersObject.q4[1].variant2.ru + '<br><br>' : '';
        // {{ /* Музыка */ }}
        this.resultTextarea2 += this.answersObject.q10 ? this.answersObject.q10[1].variant2.ru + '<br><br>' : '';
        // {{ /* Мечтаю */ }}
        this.resultTextarea2 += this.answersObject.q12 ? this.answersObject.q12[1].variant2.ru + '<br><br>' : '';
        // {{ /* Детство */ }}
        this.resultTextarea2 += this.answersObject.q13 ? this.answersObject.q13[1].variant2.ru + '<br><br>' : '';
        //endregion

        //region Результат 2 Английский
        this.resultTextarea2en = this.growth + ' cm. ';
        //{{ /* Работа */ }}
        this.resultTextarea2en += this.answersObject.q1 ? this.answersObject.q1[1].variant2.en + '<br><br>' : '';
        // {{ /* X1 */ }}
        this.resultTextarea2en += this.answersObject.q2 ? this.answersObject.q2[1].variant2.en + '<br><br>' : '';
        // {{ /* С1 */ }}
        this.resultTextarea2en += this.answersObject.q4 ? this.answersObject.q4[1].variant2.en + '<br><br>' : '';
        // {{ /* Музыка */ }}
        this.resultTextarea2en += this.answersObject.q10 ? this.answersObject.q10[1].variant2.en + '<br><br>' : '';
        // {{ /* Мечтаю */ }}
        this.resultTextarea2en += this.answersObject.q12 ? this.answersObject.q12[1].variant2.en + '<br><br>' : '';
        // {{ /* Детство */ }}
        this.resultTextarea2en += this.answersObject.q13 ? this.answersObject.q13[1].variant2.en + '<br><br>' : '';
        //endregion

        //region Результат 3
        this.resultTextarea3 = this.growth + ' см.';
        this.resultTextarea3 += '<br><br>';

        // {{ /* Работа */ }}
        this.resultTextarea3 += this.answersObject.q1 && this.answersObject.q1[2].variant3 ? this.answersObject.q1[2].variant3.ru + '<br><br>' : '';
        // {{ /* X1 */ }}
        this.resultTextarea3 += this.answersObject.q2 && this.answersObject.q2[2].variant3 ? this.answersObject.q2[2].variant3.ru + '<br><br>' : '';
        // {{ /* X2 */ }}
        this.resultTextarea3 += this.answersObject.q3 && this.answersObject.q3[2].variant3 ? this.answersObject.q3[2].variant3.ru + '<br><br>' : '';
        // {{ /* С2 */ }}
        this.resultTextarea3 += this.answersObject.q5 && this.answersObject.q5[2].variant3 ? this.answersObject.q5[2].variant3.ru + '<br><br>' : '';
        // {{ /* П/П */ }}
        this.resultTextarea3 += this.answersObject.q6 && this.answersObject.q6[2].variant3 ? this.answersObject.q6[2].variant3.ru + '<br><br>' : '';
        // {{ /* Кино */ }}
        this.resultTextarea3 += this.answersObject.q11 && this.answersObject.q11[2].variant3.ru ? this.answersObject.q11[2].variant3.ru + '<br><br>' : '';
        // {{ /* Думаю */ }}
        this.resultTextarea3 += this.answersObject.q14 && this.answersObject.q14[2].variant3 ? this.answersObject.q14[2].variant3.ru + '<br><br>' : '';
        //endregion

        //region Результат 3 Английский
        this.resultTextarea3en = this.growth + ' cm.';
        this.resultTextarea3en += '<br><br>';

        // {{ /* Работа */ }}
        this.resultTextarea3en += this.answersObject.q1 && this.answersObject.q1[2].variant3 ? this.answersObject.q1[2].variant3.en + '<br><br>' : '';
        // {{ /* X1 */ }}
        this.resultTextarea3en += this.answersObject.q2 && this.answersObject.q2[2].variant3 ? this.answersObject.q2[2].variant3.en + '<br><br>' : '';
        // {{ /* X2 */ }}
        this.resultTextarea3en += this.answersObject.q3 && this.answersObject.q3[2].variant3 ? this.answersObject.q3[2].variant3.en + '<br><br>' : '';
        // {{ /* С2 */ }}
        this.resultTextarea3en += this.answersObject.q5 && this.answersObject.q5[2].variant3 ? this.answersObject.q5[2].variant3.en + '<br><br>' : '';
        // {{ /* П/П */ }}
        this.resultTextarea3en += this.answersObject.q6 && this.answersObject.q6[2].variant3 ? this.answersObject.q6[2].variant3.en + '<br><br>' : '';
        // {{ /* Кино */ }}
        this.resultTextarea3en += this.answersObject.q11 && this.answersObject.q11[2].variant3.en ? this.answersObject.q11[2].variant3.en + '<br><br>' : '';
        // {{ /* Думаю */ }}
        this.resultTextarea3en += this.answersObject.q14 && this.answersObject.q14[2].variant3 ? this.answersObject.q14[2].variant3.en + '<br><br>' : '';
        //endregion

        //region Результат 4
        // {{ /* Мужчины - Любовь */ }}
        this.resultTextarea4 = '';
        this.resultTextarea4 += this.growth + ' см.';
        this.resultTextarea4 += '<br><br>';
        this.resultTextarea4 += 'В поиске серьезных отношений.';
        this.resultTextarea4 += '<br><br>';
        this.resultTextarea4 += 'Мои плюсы:';
        this.resultTextarea4 += '<br><br>';
        // {{ /* Работа */ }}
        this.resultTextarea4 += this.answersObject.q1 ? this.answersObject.q1[3].variant4.ru + '<br><br>' : '';
        // {{ /* X1 */ }}
        this.resultTextarea4 += this.answersObject.q2 ? this.answersObject.q2[3].variant4.ru + '<br><br>' : '';
        // {{ /* X2 */ }}
        this.resultTextarea4 += this.answersObject.q3 ? this.answersObject.q3[3].variant4.ru + '<br><br>' : '';
        this.resultTextarea4 += 'Мои минусы:';
        this.resultTextarea4 += '<br><br>';
        // {{ /* ЭПВ */ }}
        this.resultTextarea4 += this.answersObject.q15 ? this.answersObject.q15[3].variant4.ru + '<br><br>' : '';
        // {{ /* ЭТПВ */ }}
        this.resultTextarea4 += this.answersObject.q16 ? this.answersObject.q16[3].variant4.ru + '<br><br>' : '';
        this.resultTextarea4 += this.getCustomPhrase();
        //endregion

        //region Результат 4 Английский
        // {{ /* Мужчины - Любовь */ }}
        this.resultTextarea4en = '';
        this.resultTextarea4en += this.growth + ' cm.';
        this.resultTextarea4en += '<br><br>';
        this.resultTextarea4en += 'Looking for serious relationship.';
        this.resultTextarea4en += '<br><br>';
        this.resultTextarea4en += 'Pros:';
        this.resultTextarea4en += '<br><br>';
        // {{ /* Работа */ }}
        this.resultTextarea4en += this.answersObject.q1 ? this.answersObject.q1[3].variant4.en + '<br><br>' : '';
        // {{ /* X1 */ }}
        this.resultTextarea4en += this.answersObject.q2 ? this.answersObject.q2[3].variant4.en + '<br><br>' : '';
        // {{ /* X2 */ }}
        this.resultTextarea4en += this.answersObject.q3 ? this.answersObject.q3[3].variant4.en + '<br><br>' : '';
        this.resultTextarea4en += 'Cons:';
        this.resultTextarea4en += '<br><br>';
        // {{ /* ЭПВ */ }}
        this.resultTextarea4en += this.answersObject.q15 ? this.answersObject.q15[3].variant4.en + '<br><br>' : '';
        // {{ /* ЭТПВ */ }}
        this.resultTextarea4en += this.answersObject.q16 ? this.answersObject.q16[3].variant4.en + '<br><br>' : '';
        this.resultTextarea4en += this.getCustomPhrase('en');
        //endregion

        //region Результат 5
        // {{ /* X1 */ }}
        this.resultTextarea5 = 'Не ленюсь. ';
        this.resultTextarea5 += this.answersObject.q2 && this.answersObject.q2[4].variant5.ru ? this.answersObject.q2[4].variant5.ru : '';
        this.resultTextarea5 += ' ';
        this.resultTextarea5 += 'Не ищу идеала.';
        {
          { /* X2 */
          }
        }
        this.resultTextarea5 += ' ';
        this.resultTextarea5 += this.answersObject.q3 && this.answersObject.q3[4].variant5.ru ? this.answersObject.q3[4].variant5.ru : '';
        this.resultTextarea5 += ' ';
        this.resultTextarea5 += 'Не требую от девушки слишком много.';
        this.resultTextarea5 += '<br><br>';
        this.resultTextarea5 += 'И пункт № 3 – это чистая правда 😃';
        this.resultTextarea5 += '<br><br>';
        this.resultTextarea5 += this.growth + ' см.';
        //endregion

        //region Результат 5 Английский
        // {{ /* X1 */ }}
        this.resultTextarea5en = 'Work hard. ';
        this.resultTextarea5en += this.answersObject.q2 && this.answersObject.q2[4].variant5.en ? this.answersObject.q2[4].variant5.en : '';
        this.resultTextarea5en += ' ';
        this.resultTextarea5en += 'Don’t look for a perfect girl.';
        /* X2 */
        this.resultTextarea5en += ' ';
        this.resultTextarea5en += this.answersObject.q3 && this.answersObject.q3[4].variant5.en ? this.answersObject.q3[4].variant5.en : '';
        this.resultTextarea5en += ' ';
        this.resultTextarea5en += 'Don’t expect too much from a relationship.';
        this.resultTextarea5en += '<br><br>';
        this.resultTextarea5en += 'And #3 is total truth 😃';
        this.resultTextarea5en += '<br><br>';
        this.resultTextarea5en += this.growth + ' сm.';
        //endregion

      }

      else if (this.goalSelected === 'meet') {

        //region Результат 1
        if (this.growth) {
          if (this.growth > 188) {
            this.resultTextarea1 = 'Высокий. Выше, чем ты на каблуках.';
          }
          else {
            this.resultTextarea1 = this.growth + ' см.';
          }
          this.resultTextarea1 += '<br><br>';
        }
        // {{ /* Х1 */ }}
        this.resultTextarea1 += this.answersObject.q3 && this.answersObject.q3[0].variant1.ru ? this.answersObject.q3[0].variant1.ru + '<br><br>' : '';
        // {{ /* Х2 */ }}
        this.resultTextarea1 += this.answersObject.q4 && this.answersObject.q4[0].variant1.ru ? this.answersObject.q4[0].variant1.ru + '<br><br>' : '';
        // {{ /* С2 */ }}
        this.resultTextarea1 += this.answersObject.q2 && this.answersObject.q2[0].variant1.ru ? this.answersObject.q2[0].variant1.ru + '<br><br>' : '';
        // {{ /* Музыка */ }}
        this.resultTextarea1 += this.answersObject.q7 && this.answersObject.q7[0].variant1.ru ? this.answersObject.q7[0].variant1.ru + '<br><br>' : '';
        // {{ /* Думаю */ }}
        this.resultTextarea1 += this.answersObject.q14 && this.answersObject.q14[0].variant1.ru ? this.answersObject.q14[0].variant1.ru + '<br><br>' : '';
        // {{ /* Лыжи */ }}
        this.resultTextarea1 += this.answersObject.q22 && this.answersObject.q22[0].variant1.ru ? this.answersObject.q22[0].variant1.ru + '<br><br>' : '';
        // (Слишком) серьезных отношений пока не ищу.
        this.resultTextarea1 += '(Слишком) серьезных отношений пока не ищу.';
        //endregion

        //region Результат 1 Английский
        if (this.growth) {
          this.resultTextarea1en = this.growth + ' cm.';
          this.resultTextarea1en += '<br><br>';
        }
        // {{ /* Х1 */ }}
        this.resultTextarea1en += this.answersObject.q3 && this.answersObject.q3[0].variant1.en ? this.answersObject.q3[0].variant1.en + '<br><br>' : '';
        // {{ /* Х2 */ }}
        this.resultTextarea1en += this.answersObject.q4 && this.answersObject.q4[0].variant1.en ? this.answersObject.q4[0].variant1.en + '<br><br>' : '';
        // {{ /* С2 */ }}
        this.resultTextarea1en += this.answersObject.q2 && this.answersObject.q2[0].variant1.en ? this.answersObject.q2[0].variant1.en + '<br><br>' : '';
        // {{ /* Музыка */ }}
        this.resultTextarea1en += this.answersObject.q7 && this.answersObject.q7[0].variant1.en ? this.answersObject.q7[0].variant1.en + '<br><br>' : '';
        // {{ /* Думаю */ }}
        this.resultTextarea1en += this.answersObject.q14 && this.answersObject.q14[0].variant1.en ? this.answersObject.q14[0].variant1.en + '<br><br>' : '';
        // {{ /* Лыжи */ }}
        this.resultTextarea1en += this.answersObject.q22 && this.answersObject.q22[0].variant1.en ? this.answersObject.q22[0].variant1.en + '<br><br>' : '';
        // (Слишком) серьезных отношений пока не ищу.
        this.resultTextarea1en += 'P.S. Not looking for (too much) serious relationship.';
        //endregion

        //region Результат 2
        this.resultTextarea2 = '';
        if (this.growth) {
          this.resultTextarea2 += this.growth + ' см.';
        }
        this.resultTextarea2 += '<br><br>';
        // {{ /* Х1 и Х2 */}}
        this.resultTextarea2 += this.answersObject.q3 && this.answersObject.q4 && this.answersObject.q3[1].variant2 && this.answersObject.q4[1].variant2 ? this.answersObject.q3[1].variant2.ru + ' и ' + this.answersObject.q4[1].variant2.ru + '.' + '<br><br>' : '';
        // {{ /* С1 */}}
        this.resultTextarea2 += this.answersObject.q1 && this.answersObject.q1[1].variant2 ? this.answersObject.q1[1].variant2.ru + ' 👍' + '<br><br>' : '';
        // {{ /* Кухня */}}
        if (this.answersObject.q9) {
          this.resultTextarea2 += this.answersObject.q9 && this.answersObject.q9[0].variant2 ? 'Любимая кухня – ' + this.answersObject.q9[0].variant2.ru + '<br><br>' : '';
        }
        // {{ /* Мечтаю */}}
        if (this.answersObject.q12) {
          this.resultTextarea2 += this.answersObject.q12 && this.answersObject.q12[1].variant2 ? 'Мечтаю посетить ' + this.answersObject.q12[1].variant2.ru + '<br><br>' : '';
        }
        // {{ /* Внешность */}}
        if (this.answersObject.q24) {
          this.resultTextarea2 += this.answersObject.q24 && this.answersObject.q24[0].variant2 ? this.answersObject.q24[0].variant2.ru : '';
        }
        //endregion

        //region Результат 2 Английский
        this.resultTextarea2en = '';
        if (this.growth) {
          this.resultTextarea2en += this.growth + ' cm.';
        }
        this.resultTextarea2en += '<br><br>';
        // {{ /* Х1 и Х2 */}}
        this.resultTextarea2en += this.answersObject.q3 && this.answersObject.q4 && this.answersObject.q3[1].variant2 && this.answersObject.q4[1].variant2 ? this.answersObject.q3[1].variant2.en + ' ' + this.answersObject.q4[1].variant2.en + '.' + '<br><br>' : '';
        // {{ /* С1 */}}
        this.resultTextarea2en += this.answersObject.q1 && this.answersObject.q1[1].variant2 ? this.answersObject.q1[1].variant2.en + '👍' + '<br><br>' : '';
        // {{ /* Кухня */}}
        if (this.answersObject.q9) {
          this.resultTextarea2en += this.answersObject.q9 && this.answersObject.q9[0].variant2 ? 'Favourite cuisine – ' + this.answersObject.q9[0].variant2.en + '<br><br>' : '';
        }
        // {{ /* Мечтаю */}}
        if (this.answersObject.q12) {
          this.resultTextarea2en += this.answersObject.q12 && this.answersObject.q12[1].variant2 ? 'Would love to visit ' + this.answersObject.q12[1].variant2.en + '<br><br>' : '';
        }
        // {{ /* Внешность */}}
        if (this.answersObject.q24) {
          this.resultTextarea2en += this.answersObject.q24 && this.answersObject.q24[0].variant2 ? this.answersObject.q24[0].variant2.en : '';
        }

        //endregion

        //region Результат 3
        // {{ /* Мужчины - Встречи */ }}
        this.resultTextarea3 = '';
        // {{ /* Город */ }}
        this.resultTextarea3 += this.answersObject.q23 && this.answersObject.q23[0].text_result ? 'Любимый город – ' + this.answersObject.q23[0].text_result + '.' + '<br><br>' : '';
        // {{ /* Фильм */ }}
        this.resultTextarea3 += this.answersObject.q23 && this.answersObject.q23[1].text_result ? 'Любимый фильм – ' + this.answersObject.q23[1].text_result + '.' + '<br><br>' : '';
        // {{ /* Кухня */ }}
        this.resultTextarea3 += this.answersObject.q9 ? 'Любимая кухня – ' + this.answersObject.q9[0].variant3.ru + '.' + '<br><br>' : '';
        this.resultTextarea3 += 'Любимое время – в компании с тобой 🍓';
        this.resultTextarea3 += '<br><br>';
        this.resultTextarea3 += this.growth + ' см.';
        //endregion

        //region Результат 3 Английский
        // {{ /* Мужчины - Встречи */ }}
        this.resultTextarea3en = '';
        // {{ /* Город */ }}
        this.resultTextarea3en += this.answersObject.q23 && this.answersObject.q23[0].text_result ? 'My favourite city – ' + this.transliteration(this.answersObject.q23[0].text_result) + '.' + '<br><br>' : '';
        // {{ /* Фильм */ }}
        this.resultTextarea3en += this.answersObject.q23 && this.answersObject.q23[1].text_result ? 'My favourite film – ' + this.transliteration(this.answersObject.q23[1].text_result) + '.' + '<br><br>' : '';
        // {{ /* Кухня */ }}
        this.resultTextarea3en += this.answersObject.q9 ? 'My favourite cuisine – ' + this.answersObject.q9[0].variant3.en + '.' + '<br><br>' : '';
        this.resultTextarea3en += 'My favourite night – together with you 🍓';
        this.resultTextarea3en += '<br><br>';
        this.resultTextarea3en += this.growth + ' cm.';
        //endregion

        //region Результат 4
        // {{ /* Мужчины - Встречи */ }}
        this.resultTextarea4 = 'Все просто )';
        this.resultTextarea4 += '<br><br>';
        // {{ /* Х1 и Х2, С1, Кино */ }}
        this.resultTextarea4 += 'Я:';
        this.resultTextarea4 += this.answersObject.q3 ? this.answersObject.q3[3].variant4.ru + '' : '';
        this.resultTextarea4 += ' и ';
        this.resultTextarea4 += this.answersObject.q4 ? this.answersObject.q4[3].variant4.ru + ', ' : '';
        this.resultTextarea4 += this.answersObject.q1 ? this.answersObject.q1[3].variant4.ru + ', ' : '';
        this.resultTextarea4 += this.answersObject.q8 ? this.answersObject.q8[3].variant4.ru + '.' : '';
        this.resultTextarea4 += '<br><br>';
        // {{ /* Д1, Д2 */ }}
        this.resultTextarea4 += 'Ты:';
        this.resultTextarea4 += this.answersObject.q5 ? this.answersObject.q5[3].variant4.ru + ', ' : '';
        this.resultTextarea4 += this.answersObject.q6 ? this.answersObject.q6[0].variant4.ru + '.' : '';
        this.resultTextarea4 += '<br><br>';
        this.resultTextarea4 += 'Мы: встречаемся и болтаем о жизни.';
        this.resultTextarea4 += '<br><br>';
        this.resultTextarea4 += 'Единственное: я не в поиске серьезных отношений.';
        this.resultTextarea4 += '<br><br>';
        this.resultTextarea4 += 'Мой рост – ' + this.growth + ' см.';
        //endregion

        //region Результат 4 Английский
        // {{ /* Мужчины - Встречи */ }}
        this.resultTextarea4en = 'As simple as that 👇';
        this.resultTextarea4en += '<br><br>';
        // {{ /* Х1 и Х2, С1, Кино */ }}
        this.resultTextarea4en += 'Me: ';
        this.resultTextarea4en += this.answersObject.q3 ? this.answersObject.q3[3].variant4.en + '' : '';
        this.resultTextarea4en += ' ';
        this.resultTextarea4en += this.answersObject.q4 ? this.answersObject.q4[3].variant4.en + ', ' : '';
        this.resultTextarea4en += this.answersObject.q1 ? this.answersObject.q1[3].variant4.en + ', ' : '';
        this.resultTextarea4en += this.answersObject.q8 ? this.answersObject.q8[3].variant4.en + '.' : '';
        this.resultTextarea4en += '<br><br>';
        // {{ /* Д1, Д2 */ }}
        this.resultTextarea4en += 'You: ';
        this.resultTextarea4en += this.answersObject.q5 ? this.answersObject.q5[3].variant4.en + ', ' : '';
        this.resultTextarea4en += this.answersObject.q6 ? this.answersObject.q6[0].variant4.en + '.' : '';
        this.resultTextarea4en += '<br><br>';
        this.resultTextarea4en += 'We: meet and have a lovely chat.';
        this.resultTextarea4en += '<br><br>';
        this.resultTextarea4en += 'Not looking for too much serious relationship though.';
        this.resultTextarea4en += '<br><br>';
        this.resultTextarea4en += 'I’m ' + this.growth + ' cm tall.';
        //endregion

        //region Результат 5
        // {{ /* Мужчины - Встречи */ }}
        // {{ /* Д1 и Д2 */ }}
        this.resultTextarea5 = 'Мой идеал прост –';
        this.resultTextarea5 += this.answersObject.q5 && this.answersObject.q5[4].variant5.ru ? this.answersObject.q5[4].variant5.ru : '';
        this.resultTextarea5 += ' и ';
        this.resultTextarea5 += this.answersObject.q6 && this.answersObject.q6[0].variant5.ru ? this.answersObject.q6[0].variant5.ru : '';
        this.resultTextarea5 += ' девушка.';
        this.resultTextarea5 += '<br><br>';
        // {{ /* ЭПВ */ }}
        this.resultTextarea5 += this.answersObject.q15 && this.answersObject.q15[4].variant5.ru ? this.answersObject.q15[4].variant5.ru + '<br><br>' : '';
        // {{ /* ЭТПВ 2 */ }}
        this.resultTextarea5 += this.answersObject.q16 && this.answersObject.q16[4].variant5.ru ? this.answersObject.q16[4].variant5.ru + '<br><br>' : '';
        // {{ /* С2 */ }}
        this.resultTextarea5 += this.answersObject.q2 && this.answersObject.q2[4] && this.answersObject.q2[4].variant5.ru ? this.answersObject.q2[4].variant5.ru + '<br><br>' : '';
        // {{ /* Объяснить */ }}
        this.resultTextarea5 += this.answersObject.q21 && this.answersObject.q21[0].variant5.ru ? this.answersObject.q21[0].variant5.ru + '<br><br>' : '';
        // {{ /* У/Г/Р */ }}
        this.resultTextarea5 += this.answersObject.q20 && this.answersObject.q20[4].variant5.ru ? this.answersObject.q20[4].variant5.ru + '<br><br>' : '';
        this.resultTextarea5 += 'Рост ' + this.growth + ' см.';
        //endregion

        //region Результат 5 Английский
        // {{ /* Мужчины - Встречи */ }}
        // {{ /* Д1 и Д2 */ }}
        this.resultTextarea5en = 'Looking for a ';
        this.resultTextarea5en += this.answersObject.q5 && this.answersObject.q5[4].variant5.en ? this.answersObject.q5[4].variant5.en : '';
        // this.resultTextarea5en += ' and ';
        this.resultTextarea5en += ' ';
        this.resultTextarea5en += this.answersObject.q6 && this.answersObject.q6[0].variant5.en ? this.answersObject.q6[0].variant5.en : '';
        this.resultTextarea5en += ' girl.';
        this.resultTextarea5en += '<br><br>';
        // {{ /* ЭПВ */ }}
        this.resultTextarea5en += this.answersObject.q15 && this.answersObject.q15[4].variant5.en ? this.answersObject.q15[4].variant5.en + '<br><br>' : '';
        // {{ /* ЭТПВ 2 */ }}
        this.resultTextarea5en += this.answersObject.q16 && this.answersObject.q16[4].variant5.en ? this.answersObject.q16[4].variant5.en + '<br><br>' : '';
        // {{ /* Объяснить */ }}
        this.resultTextarea5en += this.answersObject.q21 && this.answersObject.q21[0].variant5.en ? this.answersObject.q21[0].variant5.en + '<br><br>' : '';
        // {{ /* У/Г/Р */ }}
        this.resultTextarea5en += this.answersObject.q20 && this.answersObject.q20[4].variant5.en ? this.answersObject.q20[4].variant5.en + '<br><br>' : '';
        this.resultTextarea5en += this.growth + ' cm.';
        //endregion

      }

      else {

        //region Результат 1
        if (this.growth) {
          this.resultTextarea1 = this.growth + ' см. ';
        }

        // {{ /* Х1 */ }}

        this.resultTextarea1 += this.answersObject.q3 && this.answersObject.q3[0].variant1.ru !== '' ? this.answersObject.q3[0].variant1.ru + '<br><br>' : '';
        // {{ /* С1 */ }}
        this.resultTextarea1 += this.answersObject.q1 && this.answersObject.q1[0].variant1.ru !== '' ? this.answersObject.q1[0].variant1.ru + '<br><br>' : '';
        // {{ /* Музыка */ }}
        this.resultTextarea1 += this.answersObject.q5 && this.answersObject.q5[0].variant1.ru !== '' ? this.answersObject.q5[0].variant1.ru + '<br><br>' : '';
        // {{ /* Мечтаю */ }}
        this.resultTextarea1 += this.answersObject.q8 && this.answersObject.q8[0].variant1.ru !== '' ? this.answersObject.q8[0].variant1.ru + '<br><br>' : '';
        // {{ /* Детство */ }}
        this.resultTextarea1 += this.answersObject.q12 && this.answersObject.q12[0].variant1.ru !== '' ? this.answersObject.q12[0].variant1.ru + '<br><br>' : '';

        //endregion

        //region Результат 1 Английский
        if (this.growth) {
          this.resultTextarea1en = this.growth + ' cm. ';
        }

        // {{ /* Х1 */ }}
        this.resultTextarea1en += this.answersObject.q3 && this.answersObject.q3[0].variant1.en !== '' ? this.answersObject.q3[0].variant1.en + '<br><br>' : '';
        // {{ /* С1 */ }}
        this.resultTextarea1en += this.answersObject.q1 && this.answersObject.q1[0].variant1.en !== '' ? this.answersObject.q1[0].variant1.en + '<br><br>' : '';
        // {{ /* Музыка */ }}
        this.resultTextarea1en += this.answersObject.q5 && this.answersObject.q5[0].variant1.en !== '' ? this.answersObject.q5[0].variant1.en + '<br><br>' : '';
        // {{ /* Мечтаю */ }}
        this.resultTextarea1en += this.answersObject.q8 && this.answersObject.q8[0].variant1.en !== '' ? this.answersObject.q8[0].variant1.en + '<br><br>' : '';
        // {{ /* Детство */ }}
        this.resultTextarea1en += this.answersObject.q12 && this.answersObject.q12[0].variant1.en !== '' ? this.answersObject.q12[0].variant1.en + '<br><br>' : '';

        //endregion

        //region Результат 2
        this.resultTextarea2 = '';
        this.resultTextarea2 += this.growth + ' см.';
        this.resultTextarea2 += '<br><br>';
        // {{ /* Мужчины - Дружба */ }}
        this.resultTextarea2 += 'Причины, по которым со мной лучше не встречаться:';
        this.resultTextarea2 += '<br><br>';
        // {{ /* ЭПВ 1 */ }}
        this.resultTextarea2 += this.answersObject.q9 && this.answersObject.q9[1].variant2 ? this.answersObject.q9[1].variant2.ru + '<br><br>' : '';
        // {{ /* ЭТПВ 1 */ }}
        this.resultTextarea2 += this.answersObject.q10 && this.answersObject.q10[1].variant2 ? this.answersObject.q10[1].variant2.ru + '<br><br>' : '';
        // {{ /* ИПВ 1 */ }}
        this.resultTextarea2 += this.answersObject.q11 && this.answersObject.q11[1].variant2 ? this.answersObject.q11[1].variant2.ru + '<br><br>' : '';
        this.resultTextarea2 += 'Если сможешь с этим жить, смахивай вправо.';
        //endregion

        //region Результат 2 Английский
        this.resultTextarea2en = '';
        this.resultTextarea2en += this.growth + ' cm.';
        this.resultTextarea2en += '<br><br>';
        // {{ /* Мужчины - Дружба */ }}
        this.resultTextarea2en += 'Reasons why you don’t wanna date me:';
        this.resultTextarea2en += '<br><br>';
        // {{ /* ЭПВ 1 */ }}
        this.resultTextarea2en += this.answersObject.q9 && this.answersObject.q9[1].variant2 ? this.answersObject.q9[1].variant2.en + '<br><br>' : '';
        // {{ /* ЭТПВ 1 */ }}
        this.resultTextarea2en += this.answersObject.q10 && this.answersObject.q10[1].variant2 ? this.answersObject.q10[1].variant2.en + '<br><br>' : '';
        // {{ /* ИПВ 1 */ }}
        this.resultTextarea2en += this.answersObject.q11 && this.answersObject.q11[1].variant2 ? this.answersObject.q11[1].variant2.en + '<br><br>' : '';
        this.resultTextarea2en += 'If you’re ok with that, swipe right.';
        //endregion

        //region Результат 3
        // {{ /* Мужчины - Дружба */ }}
        this.resultTextarea3 = '«У него высокий IQ» - The Times';
        this.resultTextarea3 += '<br><br>';
        // {{ /* Х2 */ }}
        this.resultTextarea3 += this.answersObject.q4 && this.answersObject.q4[2].variant3.ru ? this.answersObject.q4[2].variant3.ru + '<br><br>' : '';
        // {{ /* Х1 */ }}
        this.resultTextarea3 += this.answersObject.q3 && this.answersObject.q3[2].variant3.ru ? this.answersObject.q3[2].variant3.ru + '<br><br>' : '';
        // {{ /* С1 */ }}
        this.resultTextarea3 += this.answersObject.q1 && this.answersObject.q1[2].variant3.ru ? this.answersObject.q1[2].variant3.ru + '<br><br>' : '';
        this.resultTextarea3 += '«Хватит сочинять чужие цитаты» - моя первая девушка.';
        this.resultTextarea3 += '<br><br>';
        this.resultTextarea3 += '«Мой рост – ' + this.growth + ' см» - я.';
        //endregion

        //region Результат 3 Английский
        // {{ /* Мужчины - Дружба */ }}
        this.resultTextarea3en = '\'He’s very clever\' – The Times';
        this.resultTextarea3en += '<br><br>';
        // {{ /* Х2 */ }}
        this.resultTextarea3en += this.answersObject.q4 && this.answersObject.q4[2].variant3.en ? this.answersObject.q4[2].variant3.en + '<br><br>' : '';
        // {{ /* Х1 */ }}
        this.resultTextarea3en += this.answersObject.q3 && this.answersObject.q3[2].variant3.en ? this.answersObject.q3[2].variant3.en + '<br><br>' : '';
        // {{ /* С1 */ }}
        this.resultTextarea3en += this.answersObject.q1 && this.answersObject.q1[2].variant3.en ? this.answersObject.q1[2].variant3.en + '<br><br>' : '';
        this.resultTextarea3en += '‘Stop faking up quotes’ – my fist girl-friend.';
        this.resultTextarea3en += '<br><br>';
        this.resultTextarea3en += '«I’m ' + this.growth + ' cm tall’» - me.';
        //endregion

        //region Результат 4
        if (this.growth) {
          this.resultTextarea4 = this.growth + ' см.';
          this.resultTextarea4 += '<br><br>';
        }
        // {{ /* Х1 */ }}
        this.resultTextarea4 += this.answersObject.q3 && this.answersObject.q3[3].variant4.ru ? this.answersObject.q3[3].variant4.ru + '<br><br>' : '';
        // {{ /* Х2 */ }}
        this.resultTextarea4 += this.answersObject.q4 && this.answersObject.q4[3].variant4.ru ? this.answersObject.q4[3].variant4.ru + '<br><br>' : '';
        // {{ /* С2 */ }}
        this.resultTextarea4 += this.answersObject.q2 && this.answersObject.q2[3].variant4.ru ? this.answersObject.q2[3].variant4.ru + '<br><br>' : '';
        // {{ /* Кино */ }}
        this.resultTextarea4 += this.answersObject.q6 && this.answersObject.q6[3].variant4.ru ? this.answersObject.q6[3].variant4.ru + '<br><br>' : '';
        // {{ /* Кухня */ }}
        this.resultTextarea4 += this.answersObject.q7 && this.answersObject.q7[0].variant4.ru ? this.answersObject.q7[0].variant4.ru + '<br><br>' : '';
        // {{ /* Думаю */ }}
        this.resultTextarea4 += this.answersObject.q13 && this.answersObject.q13[3].variant4.ru ? this.answersObject.q13[3].variant4.ru : '';
        //endregion

        //region Результат 4 Английский
        if (this.growth) {
          this.resultTextarea4en = this.growth + ' cm.';
          this.resultTextarea4en += '<br><br>';
        }
        // {{ /* Х1 */ }}
        this.resultTextarea4en += this.answersObject.q3 && this.answersObject.q3[3].variant4.en ? this.answersObject.q3[3].variant4.en + '<br><br>' : '';
        // {{ /* Х2 */ }}
        this.resultTextarea4en += this.answersObject.q4 && this.answersObject.q4[3].variant4.en ? this.answersObject.q4[3].variant4.en + '<br><br>' : '';
        // {{ /* С2 */ }}
        this.resultTextarea4en += this.answersObject.q2 && this.answersObject.q2[3].variant4.en ? this.answersObject.q2[3].variant4.en + '<br><br>' : '';
        // {{ /* Кино */ }}
        this.resultTextarea4en += this.answersObject.q6 && this.answersObject.q6[3].variant4.en ? this.answersObject.q6[3].variant4.en + '<br><br>' : '';
        // {{ /* Кухня */ }}
        this.resultTextarea4en += this.answersObject.q7 && this.answersObject.q7[0].variant4.en ? this.answersObject.q7[0].variant4.en + '<br><br>' : '';
        // {{ /* Думаю */ }}
        this.resultTextarea4en += this.answersObject.q13 && this.answersObject.q13[3].variant4.en ? this.answersObject.q13[3].variant4.en : '';
        //endregion

        //region Результат 5
        if (this.growth) {
          this.resultTextarea5 = 'Рост ' + this.growth + ' см.';
          this.resultTextarea5 += '<br><br>';
        }
        // {{ /* ЭПВ 1.1 */ }}
        this.resultTextarea5 += this.answersObject.q14 && this.answersObject.q14[0].variant5.ru ? this.answersObject.q14[0].variant5.ru : '';
        this.resultTextarea5 += '<br><br>';
        // {{ /* ЭТПВ 1.1 */ }}
        this.resultTextarea5 += this.answersObject.q15 && this.answersObject.q15[0].variant5.ru ? this.answersObject.q15[0].variant5.ru : '';
        this.resultTextarea5 += '<br><br>';
        // {{ /* Романтик */ }}
        this.resultTextarea5 += this.answersObject.q16 && this.answersObject.q16[0].variant5.ru ? this.answersObject.q16[0].variant5.ru : '';
        this.resultTextarea5 += '<br><br>';
        // {{ /* Объяснить */ }}
        this.resultTextarea5 += this.answersObject.q17 && this.answersObject.q17[0].variant5.ru ? this.answersObject.q17[0].variant5.ru : '';
        this.resultTextarea5 += '<br><br>';
        // {{ /* Лыжи */ }}
        this.resultTextarea5 += this.answersObject.q18 && this.answersObject.q18[0].variant5.ru ? this.answersObject.q18[0].variant5.ru : '';
        //endregion

        //region Результат 5 Английский
        if (this.growth) {
          this.resultTextarea5en = this.growth + ' cm.';
          this.resultTextarea5en += '<br><br>';
        }
        // {{ /* ЭПВ 1.1 */ }}
        this.resultTextarea5en += this.answersObject.q14 && this.answersObject.q14[0].variant5.en ? this.answersObject.q14[0].variant5.en : '';
        this.resultTextarea5en += '<br><br>';
        // {{ /* ЭТПВ 1.1 */ }}
        this.resultTextarea5en += this.answersObject.q15 && this.answersObject.q15[0].variant5.en ? this.answersObject.q15[0].variant5.en : '';
        this.resultTextarea5en += '<br><br>';
        // {{ /* Романтик */ }}
        this.resultTextarea5en += this.answersObject.q16 && this.answersObject.q16[0].variant5.en ? this.answersObject.q16[0].variant5.en : '';
        this.resultTextarea5en += '<br><br>';
        // {{ /* Объяснить */ }}
        this.resultTextarea5en += this.answersObject.q17 && this.answersObject.q17[0].variant5.en ? this.answersObject.q17[0].variant5.en : '';
        this.resultTextarea5en += '<br><br>';
        // {{ /* Лыжи */ }}
        this.resultTextarea5en += this.answersObject.q18 && this.answersObject.q18[0].variant5.en ? this.answersObject.q18[0].variant5.en : '';
        //endregion

      }
    }

    if (this.sex === "woman") {
      // {{ /* Женщины - Любовь */ }}
      if (this.goalSelected === 'love') {
        //region Русский
        //region Результат 1
        // {{ /* Женщины - Любовь - Russian */ }}
        this.resultTextarea1 = '';
        this.resultTextarea1 += this.answersObject.q1 && this.answersObject.q1[0].variant1 ? this.answersObject.q1[0].variant1.ru : '';
        this.resultTextarea1 += ' и ';
        this.resultTextarea1 += this.answersObject.q2 && this.answersObject.q2[0].variant1 ? this.answersObject.q2[0].variant1.ru + ' девушка.' + ' ' : '';
        this.resultTextarea1 += this.answersObject.q3 && this.answersObject.q3[0].variant1 ? this.answersObject.q3[0].variant1.ru + ' ' : '';
        this.resultTextarea1 += this.answersObject.q4 && this.answersObject.q4[0].variant1 ? this.answersObject.q4[0].variant1.ru + ' ' : '';
        this.resultTextarea1 += this.answersObject.q10 && this.answersObject.q10[0].variant1 ? this.answersObject.q10[0].variant1.ru + ' ' : '';
        this.resultTextarea1 += 'Мечтаю встретить мужчину, для которого семья – это главное.';
        this.resultTextarea1 += '<br><br>';
        this.resultTextarea1 += 'Мой рост – ' + this.growth + ' см.';
        //endregion

        //region Результат 2
        // {{ /* Женщины - Любовь */ }}
        this.resultTextarea2 = 'Ищу друга на всю жизнь.';
        this.resultTextarea2 += '<br><br>';
        // {{ /* Голос */ }}
        this.resultTextarea2 += 'Хочу узнать тебя поближе. ';
        this.resultTextarea2 += this.answersObject.q7 && this.answersObject.q7[0].variant2 ? this.answersObject.q7[0].variant2.ru : '';
        this.resultTextarea2 += ' понять, что занимает твои мысли, о чем ты думаешь перед сном и где ты мечтаешь пожить 😊';
        this.resultTextarea2 += '<br><br>';
        // {{ /* Мечтаю пожить */ }}
        this.resultTextarea2 += this.answersObject.q8 && this.answersObject.q8[0].variant2 ? this.answersObject.q8[0].variant2.ru : '';
        this.resultTextarea2 += '<br><br>';
        this.resultTextarea2 += this.growth + ' см. ';
        this.resultTextarea2 += this.answersObject.q11 && this.answersObject.q11[0].variant2 ? this.answersObject.q11[0].variant2.ru : '';
        //endregion

        //region Результат 3
        //{{ /* Женщины - Любовь */ }}
        this.resultTextarea3 = this.growth + '. ';
        this.resultTextarea3 += 'Я новенькая ) Буду рада знакомству с интересным и свободным мужчиной.';
        this.resultTextarea3 += '<br><br>';
        this.resultTextarea3 += 'Если ты именно такой мужчина, расскажи, что тебя увлекает.';
        this.resultTextarea3 += '<br><br>';
        this.resultTextarea3 += 'Возможно, тебя манит космос. Здорово! Я как раз собиралась на парад планет )';
        this.resultTextarea3 += '<br><br>';
        this.resultTextarea3 += 'Возможно, ты сильно любишь море. Или все-таки горы?';
        this.resultTextarea3 += ' ';
        this.resultTextarea3 += this.answersObject.q14 && this.answersObject.q14[0].variant3 && this.answersObject.q14[0].variant3.ru ? this.answersObject.q14[0].variant3.ru : '';
        this.resultTextarea3 += '<br><br>';
        this.resultTextarea3 += 'Скорее всего, ты увлечен каким-то видом спорта. Класс! Хочется послушать.';
        this.resultTextarea3 += ' ';
        this.resultTextarea3 += this.answersObject.q16 && this.answersObject.q16[0].variant3 && this.answersObject.q16[0].variant3.ru ? this.answersObject.q16[0].variant3.ru : '';
        this.resultTextarea3 += '<br><br>';
        this.resultTextarea3 += 'RU';
        this.resultTextarea3 += this.answersObject.q11 && this.answersObject.q11[0].variant3 && this.answersObject.q11[0].variant3.ru ? ' / ' + this.answersObject.q11[0].variant3.ru : '';
        //endregion

        //region Результат 4
        // {{ /* Женщины - Любовь */ }}
        // {{ /* Рост. Статус. Дети */ }}
        this.resultTextarea4 = this.growth + '.';
        this.resultTextarea4 = ' ';
        this.resultTextarea4 += this.answersObject.q18 && this.answersObject.q18[0].variant4 && this.answersObject.q18[0].variant4.ru ? this.answersObject.q18[0].variant4.ru + '&nbsp;' : '';
        this.resultTextarea4 += this.answersObject.q17 && this.answersObject.q17[0].variant4 && this.answersObject.q17[0].variant4.ru ? this.answersObject.q17[0].variant4.ru : '';
        this.resultTextarea4 += '<br><br>';
        this.resultTextarea4 += 'Готова';
        this.resultTextarea4 += ' ';
        this.resultTextarea4 += this.answersObject.q18 && this.answersObject.q18[0].variant4 && this.answersObject.q18[0].variant4.ru && this.answersObject.q18[0].variant4.ru === 'В разводе.' ? 'снова ' : '';
        this.resultTextarea4 += 'влюбиться.';
        this.resultTextarea4 += '<br><br>';
        this.resultTextarea4 += 'И готова узнать тебя поближе.';
        this.resultTextarea4 += '<br><br>';
        this.resultTextarea4 += 'Интересно, что ты делаешь с девяти до шести.';
        this.resultTextarea4 += '<br><br>';
        this.resultTextarea4 += 'Интересно, как ты мыслишь и к чему стремишься.';
        this.resultTextarea4 += '<br><br>';
        this.resultTextarea4 += 'Мне даже интересно, где прошло твое детство и кто был твоим лучшим другом )';
        this.resultTextarea4 += '<br><br>';
        this.resultTextarea4 += 'Пиши!';
        this.resultTextarea4 += '<br><br>';
        this.resultTextarea4 += '🇷🇺';
        this.resultTextarea4 += this.answersObject.q11 && this.answersObject.q11[0].variant4 && this.answersObject.q11[0].variant4.ru ? this.answersObject.q11[0].variant4.ru : '';
        this.resultTextarea4 += 'Ловеласам, ONS ⛔';
        //endregion

        //region Результат 5
        // {{ /* Женщины - Любовь */ }}
        this.resultTextarea5 = this.growth + '. ';
        this.resultTextarea5 += 'В поиске серьезных отношений.';
        this.resultTextarea5 += '<br><br>';
        // {{ /* Достоинство и Х1 */ }}
        this.resultTextarea5 += this.answersObject.q5 && this.answersObject.q5[0].variant5.ru ? this.answersObject.q5[0].variant5.ru : '';
        this.resultTextarea5 += ' и ';
        this.resultTextarea5 += this.answersObject.q1 && this.answersObject.q1[0].variant5.ru ? this.answersObject.q1[0].variant5.ru + '.' : '';
        this.resultTextarea5 += '<br><br>';
        // {{ /* IQ */ }}
        this.resultTextarea5 += this.answersObject.q6 && this.answersObject.q6[0].variant5.ru ? this.answersObject.q6[0].variant5.ru + '<br><br>' : '';
        // {{ /* Есть идея */ }}
        this.resultTextarea5 += this.answersObject.q15 && this.answersObject.q15[0].variant5.ru ? this.answersObject.q15[0].variant5.ru + '<br><br>' : '';
        // {{ /* Аллергия */ }}
        this.resultTextarea5 += this.answersObject.q12 && this.answersObject.q12[0].variant5.ru ? this.answersObject.q12[0].variant5.ru + '<br><br>' : '';
        // {{ /* Думаю */ }}
        this.resultTextarea5 += this.answersObject.q9 && this.answersObject.q9[0].variant5.ru ? this.answersObject.q9[0].variant5.ru + '<br><br>' : '';
        // {{ /* Рост мужчины */ }}
        this.resultTextarea5 += 'P.S.';
        this.resultTextarea5 += this.answersObject.q13 && this.answersObject.q13[0].variant5.ru ? this.answersObject.q13[0].variant5.ru + '<br><br>' : '';
        this.resultTextarea5 += 'P.S.S. Предложения интимного характера не рассматриваю.';
        //endregion
        //endregion

        //region Английский
        //region Результат 1 En
        // {{ /* Женщины - Любовь - English */ }}
        this.resultTextarea1en = '';
        // {{ /* X1 */ }}
        this.resultTextarea1en += this.answersObject.q1 && this.answersObject.q1[0].variant1 ? this.answersObject.q1[0].variant1.en : '';
        this.resultTextarea1en += ' and ';
        // {{ /* X2 */ }}
        this.resultTextarea1en += this.answersObject.q2 && this.answersObject.q2[0].variant1 ? this.answersObject.q2[0].variant1.en : '';
        this.resultTextarea1en += '.';
        this.resultTextarea1en += ' ';
        // {{ /* X3 */ }}
        this.resultTextarea1en += this.answersObject.q3 && this.answersObject.q3[0].variant1 ? this.answersObject.q3[0].variant1.en : '';
        // {{ /* Мне нравится */ }}
        this.resultTextarea1en += ' ';
        this.resultTextarea1en += this.answersObject.q4 && this.answersObject.q4[0].variant1 ? this.answersObject.q4[0].variant1.en : '';
        this.resultTextarea1en += '&nbsp;';
        // {{ /* Готовка */ }}
        this.resultTextarea1en += this.answersObject.q10 && this.answersObject.q10[0].variant1 ? this.answersObject.q10[0].variant1.en : '';
        this.resultTextarea1en += ' ';
        this.resultTextarea1en += 'Would love to meet a man who wants to have a family.';
        this.resultTextarea1en += '<br><br>';
        this.resultTextarea1en += this.growth + ' cm.';
        //endregion

        //region Результат 2 En
        // {{ /* Женщины - Любовь */ }}
        this.resultTextarea2en = 'Looking for a soulmate.';
        this.resultTextarea2en += '<br><br>';
        // {{ /* Голос */ }}
        this.resultTextarea2en += 'And keen to know you better.';
        this.resultTextarea2en += ' ';
        this.resultTextarea2en += this.answersObject.q7 && this.answersObject.q7[0].variant2 ? this.answersObject.q7[0].variant2.en : '';
        this.resultTextarea2en += 'and learn what you think about – during your day and before you go to bed 😊';
        this.resultTextarea2en += '<br><br>';
        this.resultTextarea2en += 'Where do you want to live? Me?';
        this.resultTextarea2en += ' ';
        // {{ /* Мечтаю пожить */ }}
        this.resultTextarea2en += this.answersObject.q8 && this.answersObject.q8[0].variant2 ? this.answersObject.q8[0].variant2.en + '<br><br>' : '';
        this.resultTextarea2en += this.growth + ' cm.';
        //endregion

        //region Результат 3 En
        this.resultTextarea3en = this.growth + '.';
        this.resultTextarea3en += ' ';
        this.resultTextarea3en += 'Just subscribed. Just looking for a single man.';
        this.resultTextarea3en += '<br><br>';
        this.resultTextarea3en += 'So if you’re single, I’ve got some questions for you 😉';
        this.resultTextarea3en += '<br><br>';
        this.resultTextarea3en += 'Are you into the outer space? Wow! Than you must be a Star Wars fan 😊';
        this.resultTextarea3en += '<br><br>';
        this.resultTextarea3en += 'Sea or mountains?';
        this.resultTextarea3en += this.answersObject.q14 && this.answersObject.q14[0].variant3 ? this.answersObject.q14[0].variant3.en : '';
        this.resultTextarea3en += '<br><br>';
        this.resultTextarea3en += 'Your favourite sport?';
        this.resultTextarea3en += this.answersObject.q16 && this.answersObject.q16[0].variant3 ? this.answersObject.q16[0].variant3.en : '';
        //endregion

        // {{ /* Женщины - Любовь */ }}
        // {{ /* Рост. Статус. Дети */ }}
        this.resultTextarea4en = this.growth + '.&nbsp;';
        this.resultTextarea4en += this.answersObject.q18 && this.answersObject.q18[0].variant4 && this.answersObject.q18[0].variant4.en ? this.answersObject.q18[0].variant4.en + '&nbsp;' : '';
        this.resultTextarea4en += this.answersObject.q17 && this.answersObject.q17[0].variant4 && this.answersObject.q17[0].variant4.en ? this.answersObject.q17[0].variant4.en : '';
        this.resultTextarea4en += '<br><br>';
        this.resultTextarea4en += 'Would love to fall in love';
        this.resultTextarea4en += this.answersObject.q18 && this.answersObject.q18[0].variant4 && this.answersObject.q18[0].variant4.ru === 'В разводе.' ? ' again' : '';
        this.resultTextarea4en += '.';
        this.resultTextarea4en += '<br><br>';
        this.resultTextarea4en += 'If you either, let’s have a chit-chat.';
        this.resultTextarea4en += '<br><br>';
        this.resultTextarea4en += 'Tell me what’s your day like? And what’s keeping you busy right now?';
        this.resultTextarea4en += '<br><br>';
        this.resultTextarea4en += 'Do you remember yourself as a child? Please feel free to share your story too 😊';
        this.resultTextarea4en += '<br><br>';
        this.resultTextarea4en += 'No ons, pls.';
        //endregion

        //region Результат 5 En
        // {{ /* Женщины - Любовь */ }}
        this.resultTextarea5en = this.growth + '.';
        this.resultTextarea5en = ' ';
        this.resultTextarea5en += 'Looking for serious relationships.';
        this.resultTextarea5en += '<br><br>';
        // {{ /* Х1 и Достоинство */ }}
        this.resultTextarea5en += this.answersObject.q1 && this.answersObject.q1[0].variant5.en !== '' ? this.answersObject.q1[0].variant5.en : '';
        this.resultTextarea5en += ' and ';
        this.resultTextarea5en += this.answersObject.q5 && this.answersObject.q5[0].variant5.en !== '' ? this.answersObject.q5[0].variant5.en : '';
        this.resultTextarea5en += '.';
        this.resultTextarea5en += '<br><br>';
        // {{ /* IQ */ }}
        this.resultTextarea5en += this.answersObject.q6 && this.answersObject.q6[0].variant5.en !== '' ? this.answersObject.q6[0].variant5.en + '<br><br>' : '';
        // {{ /* Есть идея */ }}
        this.resultTextarea5en += this.answersObject.q15 && this.answersObject.q15[0].variant5.en !== '' ? this.answersObject.q15[0].variant5.en + '<br><br>' : '';
        // {{ /* Аллергия */ }}
        this.resultTextarea5en += this.answersObject.q12 && this.answersObject.q12[0].variant5.en !== '' ? this.answersObject.q12[0].variant5.en + '<br><br>' : '';
        // {{ /* Думаю */ }}
        this.resultTextarea5en += this.answersObject.q9 && this.answersObject.q9[0].variant5.en !== '' ? this.answersObject.q9[0].variant5.en + '<br><br>' : '';
        // {{ /* Рост мужчины */ }}
        this.resultTextarea5en += 'P.S.';
        this.resultTextarea5en += this.answersObject.q13 && this.answersObject.q13[0].variant5.en ? this.answersObject.q9[0].variant5.en + '<br><br>' : '';
        this.resultTextarea5en += 'P.S.S. No ons pls.';
        //endregion
        //endregion
      }
      // {{ /* Женщины - Дружба */ }}
      else if (this.goalSelected === 'friendship') {
        //region Русский
        //region Результат 1
        this.resultTextarea1 = this.growth + ' см.';
        this.resultTextarea1 += ' ';
        this.resultTextarea1 += 'Хочу общаться.';
        this.resultTextarea1 += '<br><br>';
        this.resultTextarea1 += 'Давай решим, с чего начнем.';
        this.resultTextarea1 += '<br><br>';
        // {{ /* Музыка */ }}
        this.resultTextarea1 += this.answersObject.q1 && this.answersObject.q1[0].variant1 ? this.answersObject.q1[0].variant1.ru : '';
        this.resultTextarea1 += '<br><br>';
        // {{ /* Кино */ }}
        this.resultTextarea1 += this.answersObject.q2 && this.answersObject.q2[0].variant1 ? this.answersObject.q2[0].variant1.ru : '';
        this.resultTextarea1 += '<br><br>';
        // {{ /* Мода */ }}
        this.resultTextarea1 += this.answersObject.q7 && this.answersObject.q7[0].variant1 ? this.answersObject.q7[0].variant1.ru : '';
        this.resultTextarea1 += '<br><br>';
        // {{ /* Ин язык */ }}
        this.resultTextarea1 += this.answersObject.q14 && this.answersObject.q14[0].variant1 ? this.answersObject.q14[0].variant1.ru : '';
        //endregion

        //region Результат 2
        // {{ /* Женщины - Дружба */ }}
        this.resultTextarea2 = this.growth + ' см.';
        this.resultTextarea2 += '<br><br>';
        this.resultTextarea2 += 'Познакомлюсь с интересным и свободным мужчиной.';
        this.resultTextarea2 += '<br><br>';
        // {{ /* Музыка */ }}
        this.resultTextarea2 += this.answersObject.q1 && this.answersObject.q1[0].variant2.ru !== '' ? this.answersObject.q1[0].variant2.ru : '';
        this.resultTextarea2 += '<br><br>';
        // {{ /* Архитектура */ }}
        this.resultTextarea2 += this.answersObject.q5 && this.answersObject.q5[0].variant2.ru !== '' ? this.answersObject.q5[0].variant2.ru : '';
        this.resultTextarea2 += '<br><br>';
        // {{ /* Живопись */ }}
        this.resultTextarea2 += this.answersObject.q4 && this.answersObject.q4[0].variant2.ru !== '' ? this.answersObject.q4[0].variant2.ru : '';
        this.resultTextarea2 += '<br><br>';
        // {{ /* IQ */ }}
        this.resultTextarea2 += this.answersObject.q10 && this.answersObject.q10[0].variant2.ru !== '' ? this.answersObject.q10[0].variant2.ru : '';
        this.resultTextarea2 += '<br><br>';
        // {{ /* Рост мужчины */ }}
        this.resultTextarea2 += this.answersObject.q15 && this.answersObject.q15[0].variant2.ru !== '' ? this.answersObject.q15[0].variant2.ru : '';
        this.resultTextarea2 += '<br><br>';
        // {{ /* Ин язык */ }}
        this.resultTextarea2 += '🇷🇺';
        this.resultTextarea2 += this.answersObject.q14 && this.answersObject.q14[0].variant2.ru !== '' ? this.answersObject.q14[0].variant2.ru : '';
        //endregion

        //region Результат 3
        // {{ /* Женщины - Дружба */ }}
        this.resultTextarea3 = this.growth + ' см.';
        this.resultTextarea3 += '<br><br>';
        this.resultTextarea3 += 'Мне интересно узнать о твоих увлечениях.';
        this.resultTextarea3 += '<br><br>';
        // {{ /* Музыка */ }}
        this.resultTextarea3 += this.answersObject.q1 && this.answersObject.q1[0].variant3 ? this.answersObject.q1[0].variant3.ru : '';
        this.resultTextarea3 += '<br><br>';
        // {{ /* Дизайн */ }}
        this.resultTextarea3 += this.answersObject.q3 && this.answersObject.q3[0].variant3 ? this.answersObject.q3[0].variant3.ru : '';
        this.resultTextarea3 += '<br><br>';
        // {{ /* Кино */ }}
        this.resultTextarea3 += this.answersObject.q2 && this.answersObject.q2[0].variant3 ? this.answersObject.q2[0].variant3.ru : '';
        this.resultTextarea3 += '<br><br>';
        // {{ /* Apple */ }}
        this.resultTextarea3 += this.answersObject.q18 && this.answersObject.q18[1].results[0].variant3 ? this.answersObject.q18[1].results[0].variant3.ru : '';
        this.resultTextarea3 += '<br><br>';
        // {{ /* Думаю */ }}
        this.resultTextarea3 += this.answersObject.q9 && this.answersObject.q9[0].variant3 ? this.answersObject.q9[0].variant3.ru : '';
        this.resultTextarea3 += '<br><br>';
        this.resultTextarea3 += 'P.S. Женатые мужчины, пожалуйста, свайп влево.';
        this.resultTextarea3 += '<br><br>';
        // {{ /* Ин язык */ }}
        this.resultTextarea3 += 'RU';
        this.resultTextarea3 += this.answersObject.q14 && this.answersObject.q14[0].variant3 ? ' / ' + this.answersObject.q14[0].variant3.ru : '';
        //endregion

        //region Результат 4
        // {{ /* Женщины - Дружба */ }}
        // {{ /* Рост. Статус. Дети. */ }}
        this.resultTextarea4 = this.growth + '.';
        this.resultTextarea4 = ' ';
        this.resultTextarea4 += this.answersObject.q21 && this.answersObject.q21[0].variant4.ru ? this.answersObject.q21[0].variant4.ru + '&nbsp;' : '';
        this.resultTextarea4 += this.answersObject.q20 && this.answersObject.q20[0].variant4.ru ? this.answersObject.q20[0].variant4.ru : '';
        this.resultTextarea4 += '<br><br>';
        // {{ /* Достоинство. */ }}
        this.resultTextarea4 += this.answersObject.q6 && this.answersObject.q6[0].variant4.ru ? this.answersObject.q6[0].variant4.ru : '';
        this.resultTextarea4 += '<br><br>';
        // {{ /* Мужчина */ }}
        this.resultTextarea4 += this.answersObject.q11 && this.answersObject.q11[0].variant4.ru ? this.answersObject.q11[0].variant4.ru : '';
        this.resultTextarea4 += '<br><br>';
        this.resultTextarea4 += 'Открыта к общению на любые темы.';
        this.resultTextarea4 += '<br><br>';
        // {{ /* Природа */ }}
        this.resultTextarea4 += this.answersObject.q16 && this.answersObject.q16[0].variant4.ru ? this.answersObject.q16[0].variant4.ru : '';
        this.resultTextarea4 += '<br><br>';
        // {{ /* Сообщение */ }}
        this.resultTextarea4 += this.answersObject.q12 && this.answersObject.q12[0].variant4.ru ? this.answersObject.q12[0].variant4.ru : '';
        this.resultTextarea4 += '<br><br>';
        this.resultTextarea4 += 'На интимные предложения не отвечаю❗';
        //endregion

        //region Результат 5
        // {{ /* Женщины - Дружба */ }}
        this.resultTextarea5 = this.growth + '.';
        this.resultTextarea5 += ' ';
        this.resultTextarea5 += 'Давай начнем с простого.';
        this.resultTextarea5 += '<br><br>';
        // {{ /* Цвет */ }}
        this.resultTextarea5 += 'Мне интересно, какой у тебя любимый цвет.';
        this.resultTextarea5 += ' ';
        this.resultTextarea5 += this.answersObject.q8 && this.answersObject.q8[0].variant5.ru ? 'Мой выбор – ' + this.answersObject.q8[0].variant5.ru : '';
        this.resultTextarea5 += '<br><br>';
        // {{ /* Спорт */ }}
        this.resultTextarea5 += 'Мне интересно, какой вид спорта тебя увлекает.';
        this.resultTextarea5 += ' ';
        this.resultTextarea5 += this.answersObject.q17 && this.answersObject.q17[0].variant5.ru ? this.answersObject.q17[0].variant5.ru : '';
        this.resultTextarea5 += '<br><br>';
        // {{ /* Футболист */ }}
        this.resultTextarea5 += 'Мне даже интересен твой выбор в бесконечном споре - Роналду vs Месси';
        this.resultTextarea5 += ' ';
        this.resultTextarea5 += this.answersObject.q18 && this.answersObject.q18[0].results[0].variant5 ? this.answersObject.q18[0].results[0].variant5.ru : '';
        this.resultTextarea5 += '<br><br>';
        // {{ /* Кроссовки */ }}
        this.resultTextarea5 += 'И мне немного любопытно (я снова про цвет) – ты наденешь белые или черные кроссовки?';
        this.resultTextarea5 += ' ';
        this.resultTextarea5 += this.answersObject.q18 && this.answersObject.q18[2].results[0].variant5 ? this.answersObject.q18[2].results[0].variant5.ru : '';
        this.resultTextarea5 += '<br><br>';
        // {{ /* Город */ }}
        this.resultTextarea5 += this.answersObject.q13 && this.answersObject.q13[0].variant5.ru ? this.answersObject.q13[0].variant5.ru + '<br><br>' : '';
        // {{ /* Кофе */ }}
        this.resultTextarea5 += this.answersObject.q19 && this.answersObject.q19[0].variant5.ru ? this.answersObject.q19[0].variant5.ru : '';
        //endregion
        // endregion

        //region Английский
        //region Результат 1 - En
        this.resultTextarea1en = this.growth;
        this.resultTextarea1en += ' ';
        this.resultTextarea1en += 'Looking for small talks on any subjects...';
        this.resultTextarea1en += '<br><br>';
        // {{ /* Музыка */ }}
        this.resultTextarea1en += this.answersObject.q1 && this.answersObject.q1[0].variant1 ? this.answersObject.q1[0].variant1.en : '';
        this.resultTextarea1en += '<br><br>';
        //   {{ /* Кино */ }}
        this.resultTextarea1en += this.answersObject.q2 && this.answersObject.q2[0].variant1 ? this.answersObject.q2[0].variant1.en : '';
        this.resultTextarea1en += '<br><br>';
        // {{ /* Мода */ }}
        this.resultTextarea1en += this.answersObject.q7 && this.answersObject.q7[0].variant1 ? this.answersObject.q7[0].variant1.en : '';
        //endregion

        //region Результат 2 - En
        // {{ /* Женщины - Дружба */ }}
        this.resultTextarea2en = this.growth + '.';
        this.resultTextarea2en += ' ';
        this.resultTextarea2en += 'Would love to meet a single man.';
        this.resultTextarea2en += '<br><br>';
        // {{ /* Музыка */ }}
        this.resultTextarea2en += this.answersObject.q1 && this.answersObject.q1[0].variant2.en !== '' ? this.answersObject.q1[0].variant2.en : '';
        this.resultTextarea2en += '<br><br>';
        // {{ /* Архитектура */ }}
        this.resultTextarea2en += this.answersObject.q5 && this.answersObject.q5[0].variant2.en !== '' ? this.answersObject.q5[0].variant2.en : '';
        this.resultTextarea2en += '<br><br>';
        // {{ /* Живопись */ }}
        this.resultTextarea2en += this.answersObject.q4 && this.answersObject.q4[0].variant2.en !== '' ? this.answersObject.q4[0].variant2.en : '';
        this.resultTextarea2en += '<br><br>';
        // {{ /* IQ */ }}
        this.resultTextarea2en += this.answersObject.q10 && this.answersObject.q10[0].variant2.en !== '' ? this.answersObject.q10[0].variant2.en : '';
        this.resultTextarea2en += '<br><br>';
        // {{ /* Рост мужчины */ }}
        this.resultTextarea2en += this.answersObject.q15 && this.answersObject.q15[0].variant2.en !== '' ? this.answersObject.q15[0].variant2.en : '';
        this.resultTextarea2en += '<br><br>';
        //endregion

        //region Результат 3 - En
        // {{ /* Женщины - Дружба */ }}
        this.resultTextarea3en = this.growth + '.';
        this.resultTextarea3en += ' ';
        this.resultTextarea3en += 'Looking for interesting conversations on things we both share.';
        this.resultTextarea3en += '<br><br>';
        // {{ /* Музыка */ }}
        this.resultTextarea3en += this.answersObject.q1 && this.answersObject.q1[0].variant3 ? this.answersObject.q1[0].variant3.en : '';
        this.resultTextarea3en += '<br><br>';
        // {{ /* Дизайн */ }}
        this.resultTextarea3en += this.answersObject.q3 && this.answersObject.q3[0].variant3 ? this.answersObject.q3[0].variant3.en : '';
        this.resultTextarea3en += '<br><br>';
        // {{ /* Кино */ }}
        this.resultTextarea3en += this.answersObject.q2 && this.answersObject.q2[0].variant3 ? this.answersObject.q2[0].variant3.en : '';
        this.resultTextarea3en += '<br><br>';
        // {{ /* Apple */ }}
        this.resultTextarea3en += this.answersObject.q18 && this.answersObject.q18[1].results[0].variant3 ? this.answersObject.q18[1].results[0].variant3.en : '';
        this.resultTextarea3en += '<br><br>';
        // {{ /* Думаю */ }}
        this.resultTextarea3en += this.answersObject.q9 && this.answersObject.q9[0].variant3 ? this.answersObject.q9[0].variant3.en : '';
        this.resultTextarea3en += '<br><br>';
        this.resultTextarea3en += 'P.S. no ons pls';
        //endregion

        //region Результат 4 - En
        // {{ /* Женщины - Дружба */ }}
        // {{ /* Рост. Статус. Дети. */ }}
        this.resultTextarea4en = this.growth + '.&nbsp;';
        this.resultTextarea4en += this.answersObject.q21 && this.answersObject.q21[0].variant4.en ? this.answersObject.q21[0].variant4.en + '&nbsp;' : '';
        this.resultTextarea4en += this.answersObject.q20 && this.answersObject.q20[0].variant4.en ? this.answersObject.q20[0].variant4.en : '';
        this.resultTextarea4en += '<br><br>';
        // {{ /* Достоинство. */ }}
        this.resultTextarea4en += this.answersObject.q6 && this.answersObject.q6[0].variant4.en ? this.answersObject.q6[0].variant4.en : '';
        this.resultTextarea4en += '<br><br>';
        // {{ /* Мужчина */ }}
        this.resultTextarea4en += this.answersObject.q11 && this.answersObject.q11[0].variant4.en ? this.answersObject.q11[0].variant4.en : '';
        this.resultTextarea4en += '<br><br>';
        // {{ /* Природа */ }}
        this.resultTextarea4en += this.answersObject.q16 && this.answersObject.q16[0].variant4.en ? this.answersObject.q16[0].variant4.en : '';
        this.resultTextarea4en += '<br><br>';
        // {{ /* Сообщение */ }}
        this.resultTextarea4en += this.answersObject.q12 && this.answersObject.q12[0].variant4.en ? this.answersObject.q12[0].variant4.en : '';
        this.resultTextarea4en += '<br><br>';
        this.resultTextarea4en += 'No ons please.';
        //endregion

        //region Результат 5 - En
        // {{ /* Женщины - Дружба */ }}
        this.resultTextarea5en = this.growth + '.';
        this.resultTextarea5en += ' ';
        this.resultTextarea5en += 'Four simple questions for you. Ready?';
        this.resultTextarea5en += '<br><br>';
        // {{ /* Цвет */ }}
        this.resultTextarea5en += 'Your favourite colour?';
        this.resultTextarea5en += ' ';
        this.resultTextarea5en += this.answersObject.q8 && this.answersObject.q8[0].variant5.en ? this.answersObject.q8[0].variant5.en : '';
        this.resultTextarea5en += '<br><br>';
        // {{ /* Спорт */ }}
        this.resultTextarea5en += 'Your favourite sport?';
        this.resultTextarea5en += ' ';
        this.resultTextarea5en += this.answersObject.q17 && this.answersObject.q17[0].variant5.en ? this.answersObject.q17[0].variant5.en : '';
        this.resultTextarea5en += '<br><br>';
        // {{ /* Футболист */ }}
        this.resultTextarea5en += 'Ronaldo or Messi?';
        this.resultTextarea5en += ' ';
        this.resultTextarea5en += this.answersObject.q18 && this.answersObject.q18[0].results[0].variant5.en ? this.answersObject.q18[0].results[0].variant5.en : '';
        this.resultTextarea5en += '<br><br>';
        // {{ /* Кроссовки */ }}
        this.resultTextarea5en += 'White sneakers or black sneakers?';
        this.resultTextarea5en += ' ';
        this.resultTextarea5en += this.answersObject.q18 && this.answersObject.q18[2].results[0].variant5.en ? this.answersObject.q18[2].results[0].variant5.en : '';
        this.resultTextarea5en += '<br><br>';
        // {{ /* Город */ }}
        this.resultTextarea5en += this.answersObject.q13 && this.answersObject.q13[0].variant5.en ? this.answersObject.q13[0].variant5.en : '';
        // {{ /* Кофе */ }}
        this.resultTextarea5en += this.answersObject.q19 && this.answersObject.q19[0].variant5.en ? this.answersObject.q19[0].variant5.en : '';
        //endregion
        // endregion
      }
    }

    //region Сохранение данных для dates
    let dates = {
      result1: this.resultTextarea1,
      result2: this.resultTextarea2,
      result3: this.resultTextarea3,
      result4: this.resultTextarea4,
      result5: this.resultTextarea5,
      result1en: this.resultTextarea1en,
      result2en: this.resultTextarea2en,
      result3en: this.resultTextarea3en,
      result4en: this.resultTextarea4en,
      result5en: this.resultTextarea5en
    }

    this.saveDataToAnonymous(dates);
    //endregion

  },

    // Сохранение данных для анонима
    saveDataToAnonymous(dates) {
      this.$store.dispatch('setSavedData', {dates});
    },

    openFirstResult() {
      this.isBeforeResult = false;
      this.isResultReady = true;

      // this.$gtag.event('show_result');
    },

    // Скопировать результат
    copyResult: function (number) {
      let text = '';

      if (this.language === "ru") {
        text = this.$refs['result' + number].innerText
      }
      else {
        text = this.$refs['result' + number + 'en'].innerText
      }

      // Копировать
      this.$copyText(text);
      // Сменить текст на кнопке
      //this.$refs['resultBtn' + number].innerText = 'Скопировано';
    },

    // Ходим по шагам результатов на стрелочках
    changeResult: function (currentStep, action) {

      // Скрываем все результаты
      this.isShowResults = [false, false, false, false, false];

      // console.log('isShowResults', this.isShowResults);
      if (currentStep === 1 && action === 'prev') {
        this.isShowResults[0] = true;
        // this.isShowResult1 = true;
      }
      else if (action === 'next') {
        // заполнение данных
        // this["resultTextarea" + currentStep] = this.$refs['result' + currentStep].innerText;
        // this["resultTextarea" + currentStep + "en"] = this.$refs['result' + currentStep + 'en'].innerText;

        // Меняем шаг
        this.isShowResults[currentStep] = true;

        if (currentStep === 5) {
          this.isShowResultAll = true;
        }

      }
      else {
        // console.log('currentStep', currentStep - 1);
        this.isShowResults[currentStep - 2] = true;
        // var prevStep = currentStep - 1;
        // console.log('else');
      }

      // console.log('res', this.isShowResults);

      // console.log('step', currentStep);
      // console.log('action', action);
    },

    // Ходим по шагам результатов
    changeResultPager: function (step) {

      // Скрываем все результаты
      this.isShowResults = [false, false, false, false, false];

      let currentStep = step - 1;

      // if (this.$refs['result' + currentStep]) {
      //   this["resultTextarea" + currentStep] = this.$refs['result' + currentStep].innerText;
      // }

      // if (this.$refs['result' + currentStep + 'en']) {
      //   this["resultTextarea" + currentStep + "en"] = this.$refs['result' + currentStep + 'en'].innerText;
      // }

      this.isShowResults[currentStep] = true;

    },


    // Сохранить результаты
    saveResult() {
      this.isSaved = true;

      let self = this;

      console.log('res', this['resultTextarea1']);

      axios({
        url: process.env.VUE_APP_API + 'dates-arr',
        data: {
          dates: [
            {
              name: 'Версия 5',
              version: this['resultTextarea5'],
              version_en: this['resultTextarea5en']
            },
            {
              name: 'Версия 4',
              version: this['resultTextarea4'],
              version_en: this['resultTextarea4en']
            },
            {
              name: 'Версия 3',
              version: this['resultTextarea3'],
              version_en: this['resultTextarea3en']
            },
            {
              name: 'Версия 2',
              version: this['resultTextarea2'],
              version_en: this['resultTextarea2en']
            },
            {
              name: 'Версия 1',
              version: this['resultTextarea1'],
              version_en: this['resultTextarea1en']
            }
          ]
        },
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then(function (response) {

            if (process.env.VUE_APP_TYPE === 'local' || (self.$route.query && self.$route.query.isDebug === true)) {
              console.log('response', response);
            }

            // Обновляем "Обо мне" (+5)
            // self.$store.commit('updateUserSavedPlus1');
            self.$store.commit('updateUserSavedPlus', 5);


          })
          .catch(function (error) {
            if (process.env.VUE_APP_TYPE === 'local' || (self.$route.query && self.$route.query.isDebug === true)) {
              console.log(error)
              console.log(error.response);
            }
          });


    },

    transliteration(word) {
      var answer = '';
      var converter = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',
        'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
        'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
        'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch',
        'ш': 'sh', 'щ': 'sch', 'ь': '', 'ы': 'y', 'ъ': '',
        'э': 'e', 'ю': 'yu', 'я': 'ya',

        'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D',
        'Е': 'E', 'Ё': 'E', 'Ж': 'Zh', 'З': 'Z', 'И': 'I',
        'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N',
        'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T',
        'У': 'U', 'Ф': 'F', 'Х': 'H', 'Ц': 'C', 'Ч': 'Ch',
        'Ш': 'Sh', 'Щ': 'Sch', 'Ь': '', 'Ы': 'Y', 'Ъ': '',
        'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya'
      };

      for (var i = 0; i < word.length; ++i) {
        if (converter[word[i]] == undefined) {
          answer += word[i];
        }
        else {
          answer += converter[word[i]];
        }
      }

      return answer;
    },

    getAccess() {
      this.isDataLoaded = true;
      //region Проверка платной подписки
      if (localStorage.getItem('token')) {
        checkAccess.isAccess.then((res) => {
          if (res === true) {
            this.isAccess = true;
          }
          else {
            this.isAccess = false;
          }
        });
      }
      //endregion
    },

  },
  mounted() {

    //region Проверка платной подписки
    this.getAccess();
    //endregion

    if (process.env.VUE_APP_TYPE === 'local') {
      // this.isDebugMode = true;
    }

    //region Заполнение данных
    // Быстрое решение на коленках, чтобы не переписывать все переменные
    // todo: Рефакторинг
    this.resultTextarea1 = this.resultsObject.result1;
    this.resultTextarea2 = this.resultsObject.result2;
    this.resultTextarea3 = this.resultsObject.result3;
    this.resultTextarea4 = this.resultsObject.result4;
    this.resultTextarea5 = this.resultsObject.result5;
    this.resultTextarea1en = this.resultsObject.result1en;
    this.resultTextarea2en = this.resultsObject.result2en;
    this.resultTextarea3en = this.resultsObject.result3en;
    this.resultTextarea4en = this.resultsObject.result4en;
    this.resultTextarea5en = this.resultsObject.result5en;
    //endregion

    // Заполнение данных
    this.answersObject = this.resultsObject;
    this.setResults();

  }
}
</script>

<style scoped>

</style>