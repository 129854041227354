<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let resultToChange = null;
      this.unusedVars = resultToChange;
      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1

      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      // 3d
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'Владение инструментами проектирования и 3D-визуализации: ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Adobe
      if (answersObject.q2) {
        if (!answersObject.q2?.['2_3']) {
          this['resultTextarea' + variant + isEn] += 'Владение инструментами Adobe: ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language, '2_3');
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // CorelDRAW
        if (answersObject.q2?.['2_3']) {
          this['resultTextarea' + variant + isEn] += 'Знание: ';
          this['resultTextarea' + variant + isEn] += answersObject.q2['2_3'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Иллюстрации
      if (answersObject.q3?.['0'][variantFull]?.[language]) {
        if (!answersObject.q3?.['3_2']) {
          this['resultTextarea' + variant + isEn] += answersObject.q3['0'][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Планшет
      if (answersObject.q4?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q4['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Особенности 1
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += 'Понимание инженерно-технических особенностей жилых и нежилых помещений: ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q7, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Особенности 2
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += 'Понимание специфики: ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q8, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт 1
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'Опыт ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт 2
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += 'Опыт ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q10, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт 3
      if (answersObject.q11) {
        this['resultTextarea' + variant + isEn] += 'Опыт ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q11, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Ассортимент
      if (answersObject.q12) {
        this['resultTextarea' + variant + isEn] += 'Знание ассортимента в различном ценовом сегменте: ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q12, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // История
      if (answersObject.q15) {
        this['resultTextarea' + variant + isEn] += 'Знание истории';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q15, variant, language, '', ' и ');
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'Знание базовых программ Microsoft Office и инструментов Google.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      // Английский
      if (answersObject.q21?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q21['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества
      if (answersObject.q20) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q20);

        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(resultToChange, variant, language, ['20_7', '20_8'], '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2
      // Опыт работы
      if (answersObject.q19?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q19['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'Интересуюсь различными стилями. Понимаю преимущества и визуальные риски каждого из них.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      // Главное предпочтение
      if (answersObject.q5?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q5['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Вас особенно увлекает
      if (answersObject.q6?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q6['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Подписаны
      if (answersObject.q13) {
        this['resultTextarea' + variant + isEn] += 'Слежу за тенденциями. Вдохновляюсь примерами ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q13, variant, language);
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Восхищаетесь
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Бренды
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества 2
      if (answersObject.q20) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q20);

        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(resultToChange, variant, language, ['20_1', '20_2', '20_3', '20_4', '20_5'], '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Таск-трекеры
      if (answersObject.q17) {
        this['resultTextarea' + variant + isEn] += 'Имею опыт работы с таск-трекерами';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q17, variant, language, ['20_1', '20_2', '20_3', '20_4', '20_5']);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт делегирования, Опыт руководства
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // График
      if (answersObject.q22?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q22['0'][variantFull][language];
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском
      this['resultTextarea' + variant + isEn] += '<b>Professional skills:</b><br/><br/>';

      // 3d
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'Experience with prototyping and 3D visualization tools: ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Adobe
      if (answersObject.q2) {
        if (!answersObject.q2?.['2_3']) {
          this['resultTextarea' + variant + isEn] += 'Experience with Adobe: ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language, '2_3');
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // CorelDRAW
        if (answersObject.q2?.['2_3']) {
          this['resultTextarea' + variant + isEn] += 'Knowledge of ';
          this['resultTextarea' + variant + isEn] += answersObject.q2['2_3'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

      }

      // Иллюстрации
      if (answersObject.q3?.['0'][variantFull]?.[language]) {
        if (!answersObject.q3?.['3_2']) {
          this['resultTextarea' + variant + isEn] += answersObject.q3['0'][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Планшет
      if (answersObject.q4?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q4['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Особенности 1
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += 'Understanding of residential and non-residential engineering: ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q7, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Особенности 2
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += 'Understanding of the specifics of ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q8, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт 1
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'Experience in ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт 2
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += 'Experience in ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q10, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт 3
      if (answersObject.q11) {
        this['resultTextarea' + variant + isEn] += 'Experience in maintaining documentation at all stages of project implementation:';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q11, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Ассортимент
      if (answersObject.q12) {
        this['resultTextarea' + variant + isEn] += 'Knowledge of various price segments: ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q12, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // История
      if (answersObject.q15) {
        this['resultTextarea' + variant + isEn] += 'Knowledge of';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q15, variant, language, '', ' and ');
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += 'history';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'Knowledge of basic Microsoft Office and Google Docs.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      // Английский
      if (answersObject.q21?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q21['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества
      if (answersObject.q20) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q20);

        this['resultTextarea' + variant + isEn] += '<br/><b>Personal skills:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(resultToChange, variant, language, ['20_7', '20_8'], '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2
      // Опыт работы
      if (answersObject.q19?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q19['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'I’m interested in different interior styles, while recognizing both the advantages and visual risks of each of them.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      // Главное предпочтение
      if (answersObject.q5?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q5['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Вас особенно увлекает
      if (answersObject.q6?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q6['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Подписаны
      if (answersObject.q13) {
        this['resultTextarea' + variant + isEn] += 'I try to keep up-to-date on interior trends, getting inspirations from ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q13, variant, language);
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Восхищаетесь
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Бренды
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества 2
      if (answersObject.q20) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q20);

        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(resultToChange, variant, language, ['20_1', '20_2', '20_3', '20_4', '20_5'], '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Таск-трекеры
      if (answersObject.q17) {
        this['resultTextarea' + variant + isEn] += 'I’m skilled in using task trackers:';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q17, variant, language, ['20_1', '20_2', '20_3', '20_4', '20_5']);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт делегирования, Опыт руководства
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // График
      if (answersObject.q22?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q22['0'][variantFull][language];
      }
      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>
