<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1

      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      // frontend
      if (answersObject.q1) {
        if (!answersObject.q1?.['1_4']) {
          this['resultTextarea' + variant + isEn] += 'Уверенное владение языками frontend (';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language, '1_4');
          this['resultTextarea' + variant + isEn] += ')';
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Фреймворки
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += 'Опыт работы с фреймворками (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Храните код
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Опыт использования ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += ' для хостинга проектов и их совместной разработки.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Контроль версий
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += 'Опыт использования ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q4, variant, language);
        this['resultTextarea' + variant + isEn] += ' в качестве системы контроля версий.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Язык разметки
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += 'Опыт использования ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q10, variant, language);
        this['resultTextarea' + variant + isEn] += ' для передачи данных в логически и визуально правильной форме.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Архитектурный стиль
      if (answersObject.q11) {
        this['resultTextarea' + variant + isEn] += 'Понимание принципов создания веб-сервисов на основе ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q11, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Соединение с сервером
      if (answersObject.q12) {
        if (answersObject.q12?.['12_2']) {
          this['resultTextarea' + variant + isEn] += 'Опыт организации двунаправленного и однонаправленного соединения с сервером и получения событий';
        } else {
          this['resultTextarea' + variant + isEn] += 'Опыт организации однонаправленного соединения с сервером и получения событий';
        }
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q12, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Backend middle
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += 'Опыт backend-программирования на среднем уровне';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q8, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Backend junior
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'Опыт backend-программирования на начальном уровне';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // QA
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q25?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q25['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества ЛК
      if (answersObject.q23) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q23, variant, language, '', '<br/><br/>');
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2

      // Опыт работы
      if (answersObject.q21?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q21['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Области
      if (answersObject.q17) {
        this['resultTextarea' + variant + isEn] += 'Имею опыт реализации разноплановых проектов в сфере ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q17, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Методология
      if (answersObject.q7?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q7['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Препроцессоры HTML
      if (answersObject.q5) {
        this['resultTextarea' + variant + isEn] += 'Для автоматизации рутинных операций использую препроцессоры HTML (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q5, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Препроцессоры CSS
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'В целях ускорения и упрощения разработки, обеспечения «бесшовной» поддержки стилей использую препроцессоры CSS (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // CI/CD
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // UX/UI
      if (answersObject.q20?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q20['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Без фреймворков
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Стремитесь
      if (answersObject.q24) {
        this['resultTextarea' + variant + isEn] += 'Стремлюсь ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q24, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // таск-трекеры
      if (answersObject.q15) {
        this['resultTextarea' + variant + isEn] += 'Владею навыками работы с таск-трекерами';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q15, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Графические редакторы
      if (answersObject.q19) {
        this['resultTextarea' + variant + isEn] += 'Знаком с графическими редакторами';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q19, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Инструменты проектирования
      if (answersObject.q18) {
        if (answersObject.q19) {
          this['resultTextarea' + variant + isEn] += 'Знаком также с инструментами проектирования';
        } else {
          this['resultTextarea' + variant + isEn] += 'Знаком с инструментами проектирования';
        }

        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q18, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт руководства
      if (answersObject.q22?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q22['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // График
      if (answersObject.q26) {
        this['resultTextarea' + variant + isEn] += answersObject.q26['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском
      this['resultTextarea' + variant + isEn] += '<b>Professional skills:</b><br/><br/>';

      // frontend
      if (answersObject.q1) {
        if (!answersObject.q1?.['1_4']) {
          this['resultTextarea' + variant + isEn] += 'Proficiency in frontend languages (';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language, '1_4');
          this['resultTextarea' + variant + isEn] += ')';
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
       }
      }

      // Фреймворки
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += 'Experience with frameworks (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Храните код
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Experience using ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += ' for project hosting and collaborative development.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Контроль версий
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += 'Experience using ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q4, variant, language);
        this['resultTextarea' + variant + isEn] += ' as a version control system.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Язык разметки
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += 'Experience using ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q10, variant, language);
        this['resultTextarea' + variant + isEn] += ' to communicate data in a logically and visually correct manner.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Архитектурный стиль
      if (answersObject.q11) {
        this['resultTextarea' + variant + isEn] += 'Experience creating web services based on ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q11, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Соединение с сервером
      if (answersObject.q12) {
        if (answersObject.q12?.['12_2']) {
          this['resultTextarea' + variant + isEn] += 'Experience organizing bidirectional and unidirectional server connections and receiving events';
        } else {
          this['resultTextarea' + variant + isEn] += 'Experience organizing unidirectional server connections and receiving events';
        }
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q12, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Backend middle
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += 'Middle level backend programming experience';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q8, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Backend junior
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'Junior level backend programming experience';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // QA
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q25?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q25['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества ЛК
      if (answersObject.q23) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Personal skills:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q23, variant, language, '', '<br/><br/>');
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском

      // Опыт работы
      if (answersObject.q21?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q21['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Области
      if (answersObject.q17) {
        this['resultTextarea' + variant + isEn] += 'I have experience in implementing various projects in the fields of ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q17, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Методология
      if (answersObject.q7?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q7['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Препроцессоры HTML
      if (answersObject.q5) {
        this['resultTextarea' + variant + isEn] += 'I use HTML preprocessors (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q5, variant, language);
        this['resultTextarea' + variant + isEn] += ') to automate routine operations.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Препроцессоры CSS
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'I use CSS preprocessors (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += ') in order to speed up and simplify development and provide seamless style support.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // CI/CD
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // UX/UI
      if (answersObject.q20?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q20['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Без фреймворков
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Стремитесь
      if (answersObject.q24) {
        this['resultTextarea' + variant + isEn] += 'Driven to ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q24, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // таск-трекеры
      if (answersObject.q15) {
        this['resultTextarea' + variant + isEn] += 'I’m skilled in working with task-trackers';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q15, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Графические редакторы
      if (answersObject.q19) {
        this['resultTextarea' + variant + isEn] += 'I’m familiar with graphics editing tools';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q19, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Инструменты проектирования
      if (answersObject.q18) {
        if (answersObject.q19) {
          this['resultTextarea' + variant + isEn] += 'I’m also familiar with design tool';
        } else {
          this['resultTextarea' + variant + isEn] += 'I’m familiar with design tool';
        }

        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q18, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт руководства
      if (answersObject.q22?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q22['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // График
      if (answersObject.q26) {
        this['resultTextarea' + variant + isEn] += answersObject.q26['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>