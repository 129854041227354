import { render, staticRenderFns } from "./SingleJob.vue?vue&type=template&id=a50edd60&scoped=true&"
import script from "./SingleJob.vue?vue&type=script&lang=js&"
export * from "./SingleJob.vue?vue&type=script&lang=js&"
import style0 from "./SingleJob.vue?vue&type=style&index=0&id=a50edd60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a50edd60",
  null
  
)

export default component.exports