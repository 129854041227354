<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let resultToChange = null;
      this.unusedVars = resultToChange;
      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1

      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      // Уже снимали
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += 'Опыт разноплановой и многочасовой съемки:';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q4, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Цветокоррекция
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'Опыт цветокоррекции и ретуширования';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Редактирование
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += 'Опыт редактирования и стилизации';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Цифровой / Пленочный
      if (answersObject.q3?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q3['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Продюссирование
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Дополнительный опыт
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += 'Знание основ и владение спецификой';
        this['resultTextarea' + variant + isEn] += ' ';

        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q10);

        if (answersObject.q10['10_1'] || answersObject.q10['10_2']) {
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, "10_3", ' и ');
        }

        if (answersObject.q10?.['10_3']) {
          if (answersObject.q10['10_1'] && answersObject.q10['10_2']) {
            this['resultTextarea' + variant + isEn] += ', а также ';
          } else {
            this['resultTextarea' + variant + isEn] += ' и ';
          }
          this['resultTextarea' + variant + isEn] += answersObject.q10['10_3'].results[0][variantFull][language];
        }

        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      //Английский
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // личные качества
      if (answersObject.q15) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q15, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2

      // Опыт работы
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Цифровой / Пленочный
      if (answersObject.q3?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q3['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Предпочтение
      if (answersObject.q9?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q9['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Чаще
      if (answersObject.q5?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q5['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Реже
      if (answersObject.q6?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q6['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Стилистика 1
      if (answersObject.q7?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q7['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Стилистика 2
      if (answersObject.q8?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q8['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Восхищаюсь, Восхищаетесь
      if (answersObject.q11?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }


      if (answersObject.q13) {
        if (answersObject.q13?.['13_7']) {
          this['resultTextarea' + variant + isEn] += 'Имею устоявшуюся команду';
          this['resultTextarea' + variant + isEn] += ' ';
          this['resultTextarea' + variant + isEn] += '(';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q13, variant, language);
          this['resultTextarea' + variant + isEn] += ')';
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += ' ';
          this['resultTextarea' + variant + isEn] += 'Привлекаю к проекту профессионалов, которым доверяю.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      this['resultTextarea' + variant + isEn] += 'Умею доносить свои идеи, грамотно и дипломатично защищать авторскую задумку.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "Отношусь к каждому проекту как к своему личному. Искреннее удовлетворение заказчика – моя лучшая награда."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "В работе организован и дисциплинирован. Не терплю опозданий."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";

      // График
      if (answersObject.q17) {
        this['resultTextarea' + variant + isEn] += answersObject.q17['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском

      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском

      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>
