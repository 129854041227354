<template>
  <div class="container container_main">

    {{ /* Возраст */ }}
    <div class="question question_main" v-if="age<18 && !ageDenied">
      <form
          class="question__wrapper"
      >
        <div class="question__header">
          <div class="question__logo"></div>
          <div class="question__title">Когда Вы родились?</div>
          <div class="question__subtitle">Нужно убедиться, что Вам уже есть 18</div>
        </div>

        <div class="question__variants question__variants_birthday">
          <div class="question__item question-item">
            <the-mask :mask="['##']" placeholder="10" minlength="2" maxlength="2" v-model="user.day" autofocus v-on:input="dateDayCheck" required ref="birthday_day"/>
            <label>день</label>
          </div>
          <div class="question__item question-item">
            <the-mask :mask="['##']" placeholder="01" minlength="2" maxlength="2" v-model="user.month" v-on:input="dateMonthCheck" required ref="birthday_month"/>
            <label>месяц</label>
          </div>
          <div class="question__item question-item">
            <the-mask :mask="['####']" placeholder="1990" minlength="4" maxlength="4" v-model="user.year" v-on:input="dateYearCheck" required ref="birthday_year"/>
            <label>год</label>
          </div>
        </div>

        <div class="question__delimiter"></div>

        <div class="question__footer">
          <div class="question__next btn btn_grey" @click="submitAge">Продолжить</div>
        </div>
      </form>
    </div>

    {{ /* Запрет прохождения теста */ }}
    <div class="question question_main" v-if="ageDenied">
      <div class="question__wrapper question__wrapper_centered">
        <div class="question__header">
          <div class="question__logo"></div>
          <div class="question__title question__title_small">Похоже, тебе ещё нет 18 лет.</div>
          <div class="question__subtitle"><br><br>Ждем тебя снова в день твоего совершеннолетия.</div>
        </div>

        <div class="question__delimiter"></div>

        <div class="question__footer question__footer_bottom question__footer_left">
          <div class="question__next btn btn_back" @click="returnAge">&lt; назад</div>
        </div>
      </div>
    </div>

    {{ /* Пол */ }}
    <div class="question question_main" v-if="isQuestionMain1 && !isQuestionMain2">
      <div class="question__wrapper">
        <div class="question__header">
          <div class="question__logo"></div>
          <div class="question__title">Вы</div>
          <div class="question__subtitle"></div>
        </div>
        <div class="question__variants question__variants_buttons">
          <div class="question__item question-item">
            <input type="radio" id="sex_women" name="sex">
            <label for="sex_women" @click="submitSex('woman')">Женщина</label>
          </div>
          <div class="question__item question-item">
            <input type="radio" id="sex_men" name="sex">
            <label for="sex_men" @click="submitSex('man')">Мужчина</label>
          </div>
        </div>
      </div>
    </div>

    {{ /* Рост */ }}
    <div class="question question_main" v-if="isQuestionMain4 && !isQuestionMain5">
      <div class="question__wrapper">
        <div class="question__header">
          <div class="question__logo"></div>
          <div class="question__title">Какой у Вас рост?</div>
          <div class="question__subtitle" v-if="sex==='man'">Девушкам важно знать это сразу</div>
          <div class="question__subtitle" v-if="sex==='woman'">Мужчинам важно знать это сразу</div>
        </div>
        <div class="question__variants question__variants_growth">
          <div class="question__item question-item">
            <the-mask :mask="['###']" minlength="3" maxlength="3" v-model="growth" v-on:input="growthCheck" required/>
            <label>см</label>
          </div>
        </div>
        <div class="question__delimiter"></div>
        <div class="question__footer">
          <div class="question__next btn btn_grey height" @click="submitGrowth">Продолжить</div>
        </div>
      </div>
    </div>

    <template v-if="dataArray && dataArray.length > 0 && isQuestionMain5">

      <question :data="dataArray[currentQuestion]"
                :currentProgress="progressBar"
                v-bind:key="'id_' + dataArray[currentQuestion].id"
                @changeStep="onChangeStep"
                @lastStep="onLastStep"
                @setAnswer="onSetAnswer"
                @skipStep="onSkipStep"
                v-if="!isResultReady"
      >
      </question>

      <template v-if="isNeed">
        <div class="result result_finish" v-if="isBeforeResult">
          <div class="result__wrapper">
            <div class="result__logo"></div>
            <div class="result__text result__text_normal">
              <p>Мы подготовили для тебя 5 описаний.<br> Выбери, какое нравится больше, скопируй и вставь в свое приложение для знакомств. Также сохрани себе копию всех пяти описаний в личном кабинете.</p>
            </div>
            <div class="result__buttons">
              <div class="btn btn_grey btn_continue" @click="openFirstResult()">Продолжить</div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="isResultReady">

        <div class="result result_1" v-if="isShowResults[0]">
          <div class="result__wrapper">

            <div class="result__logo"></div>

            <div class="result__header">
              <div class="result__title">Вариант 1</div>
              <!--<div class="result__copy btn_grey1" @click="copyResult(1)" ref="resultBtn1">Copy</div>-->
              <template v-if="isEnglish && isNeed">
                <div class="result__language" v-if="language === 'ru'" @click="language = 'en'">EN</div>
                <div class="result__language" v-if="language === 'en'" @click="language = 'ru'">RU</div>
              </template>
              <div class="result__buttons">
                <div class="result__pagination">
                  <div class="result__pagination-item result__pagination-item_dot js-active" @click="changeResultPager(1)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(2)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(3)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(4)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(5)"></div>
                </div>
                <div class="btn btn_result-nav btn_result-nav-next" @click="changeResult(1, 'next')"></div>
              </div>
            </div>

            <div class="result__text" ref="result1" v-if="language === 'ru'">
              <div v-html="resultTextarea1"></div>
              <div v-bind:class="{'blur-container ': !isAccess}">
                <blur isFirst v-if="!isAccess"></blur>
              </div>

            </div>
            <div class="result__text" ref="result1en" v-if="language === 'en'">
              <div v-html="resultTextarea1en"></div>
            </div>

          </div>
        </div>

        <div class="result result_2" v-if="isShowResults[1]">
          <div class="result__wrapper">

            <div class="result__logo"></div>

            <div class="result__header">
              <div class="result__title">Вариант 2</div>
              <!--<div class="result__copy btn_grey2" @click="copyResult(2)" ref="resultBtn2">Copy</div>-->
              <template v-if="isEnglish && isNeed">
                <div class="result__language" v-if="language === 'ru'" @click="language = 'en'">EN</div>
                <div class="result__language" v-if="language === 'en'" @click="language = 'ru'">RU</div>
              </template>
              <div class="result__buttons">
                <div class="btn btn_result-nav btn_result-nav-prev" @click="changeResult(2, 'prev')"></div>
                <div class="result__pagination">
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(1)"></div>
                  <div class="result__pagination-item result__pagination-item_dot js-active" @click="changeResultPager(2)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(3)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(4)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(5)"></div>
                </div>
                <div class="btn btn_result-nav btn_result-nav-next" @click="changeResult(2, 'next')"></div>
              </div>
            </div>

            <div class="result__text" ref="result2" v-if="language === 'ru'" v-bind:class="{'blur-container': !isAccess}">
              <div v-html="resultTextarea2"></div>
              <blur v-if="!isAccess"></blur>
            </div>
            <div class="result__text" ref="result2en" v-if="language === 'en'">
              <div v-html="resultTextarea2en"></div>
            </div>


          </div>
        </div>

        <div class="result result_3" v-if="isShowResults[2]">
          <div class="result__wrapper">

            <div class="result__logo"></div>

            <div class="result__header">
              <div class="result__title">Вариант 3</div>
              <!--<div class="result__copy btn_grey3" @click="copyResult(3)" ref="resultBtn3">Copy</div>-->
              <template v-if="isEnglish && isNeed">
                <div class="result__language" v-if="language === 'ru'" @click="language = 'en'">EN</div>
                <div class="result__language" v-if="language === 'en'" @click="language = 'ru'">RU</div>
              </template>
              <div class="result__buttons">
                <div class="btn btn_result-nav btn_result-nav-prev" @click="changeResult(3, 'prev')"></div>
                <div class="result__pagination">
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(1)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(2)"></div>
                  <div class="result__pagination-item result__pagination-item_dot js-active" @click="changeResultPager(3)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(4)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(5)"></div>
                </div>
                <div class="btn btn_result-nav btn_result-nav-next" @click="changeResult(3, 'next')"></div>
              </div>
            </div>

            <div class="result__text" ref="result3" v-if="language === 'ru'" v-bind:class="{'blur-container': !isAccess}">
              <div v-html="resultTextarea3"></div>
              <blur v-if="!isAccess"></blur>
            </div>
            <div class="result__text" ref="result3en" v-if="language === 'en'">
              <div v-html="resultTextarea3en"></div>
            </div>




          </div>

        </div>

        <div class="result result_4" v-if="isShowResults[3]">
          <div class="result__wrapper">

            <div class="result__logo"></div>

            <div class="result__header">
              <div class="result__title">Вариант 4</div>
              <!--<div class="result__copy btn_grey4" @click="copyResult(4)" ref="resultBtn4">Copy</div>-->
              <template v-if="isEnglish && isNeed">
                <div class="result__language" v-if="language === 'ru'" @click="language = 'en'">EN</div>
                <div class="result__language" v-if="language === 'en'" @click="language = 'ru'">RU</div>
              </template>
              <div class="result__buttons">
                <div class="btn btn_result-nav btn_result-nav-prev" @click="changeResult(4, 'prev')"></div>
                <div class="result__pagination">
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(1)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(2)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(3)"></div>
                  <div class="result__pagination-item result__pagination-item_dot js-active" @click="changeResultPager(4)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(5)"></div>
                </div>
                <div class="btn btn_result-nav btn_result-nav-next" @click="changeResult(4, 'next')"></div>
              </div>
            </div>

            <div class="result__text" ref="result4" v-if="language === 'ru'" v-bind:class="{'blur-container': !isAccess}">
              <div v-html="resultTextarea4"></div>
              <blur v-if="!isAccess"></blur>
            </div>
            <div class="result__text" ref="result4en" v-if="language === 'en'">
              <div v-html="resultTextarea4en"></div>
            </div>

          </div>
        </div>

        <div class="result result_5" v-if="isShowResults[4]">
          <div class="result__wrapper">

            <div class="result__logo"></div>

            <div class="result__header">
              <div class="result__title">Вариант 5</div>
              <!--<div class="result__copy btn_grey5" @click="copyResult(5)" ref="resultBtn5">Copy</div>-->
              <template v-if="isEnglish && isNeed">
                <div class="result__language" v-if="language === 'ru'" @click="language = 'en'">EN</div>
                <div class="result__language" v-if="language === 'en'" @click="language = 'ru'">RU</div>
              </template>
              <div class="result__buttons">
                <div class="btn btn_result-nav btn_result-nav-prev" @click="changeResult(5, 'prev')"></div>
                <div class="result__pagination">
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(1)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(2)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(3)"></div>
                  <div class="result__pagination-item result__pagination-item_dot" @click="changeResultPager(4)"></div>
                  <div class="result__pagination-item result__pagination-item_dot js-active" @click="changeResultPager(5)"></div>
                </div>
                <!--<div class="btn btn_result-nav btn_result-nav-next" v-if="!isEmailServiceError" @click="changeResult(5, 'next')"></div>-->
              </div>
            </div>

            <div class="result__text" ref="result5" v-if="language === 'ru'" v-bind:class="{'blur-container': !isAccess}">
              <div v-html="resultTextarea5"></div>
              <blur v-if="!isAccess"></blur>
            </div>
            <div class="result__text" ref="result5en" v-if="language === 'en'">
              <div v-html="resultTextarea5en"></div>
            </div>

            <div class="result__buttons result__buttons_full" v-if="isAccess">
              <!--<div class="btn btn_grey btn_grey_big btn_save_all" v-if="!isEmailServiceError" @click="changeResult(5, 'next')">Сохранить все варианты</div>-->
              <div class="btn btn_grey btn_save_all" @click.once="saveResult" v-if="!isSaved">Сохранить</div>
              <div class="btn btn_grey btn_save_all js-active" type="button" v-if="isSaved">Сохранено</div>
            </div>

          </div>
        </div>

      </template>


    </template>


  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment";

// Маски
import {TheMask} from 'vue-the-mask'
import Question from "../components/Question";

//region Красивые селекты
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

// Метрика и аналитика
// import VueYandexMetrika from 'vue-yandex-metrika'
import * as checkAccess from "@/plugins/access-check";
import Blur from "@/components/Blur";

// Vue.use(VueYandexMetrika, {
//   id: 66407710,
//   env: process.env.VUE_APP_TYPE
//   // debug: true
// })


// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({});
//endregion

const emailCheckRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;

export default {
  name: "DatesSimple",
  components: {
    Question,
    TheMask,
    Blur
  },
  props: {
    isEnglish: Boolean,
  },
  data() {
    return {
      isNeed: false,

      // Данные
      dataArray: null,
      resultsTitles: [],

      // Debug
      isDebugMode: false,
      isDebugShow: false,
      isShowDebugBtn: false,
      isDebugAnswersShow: false,

      // Пол
      sex: null,

      // Язык
      language: "ru",

      // Цель
      goalSelected: null,
      goalSelectedName: null,
      goals: [
        {name: 'Любовь', value: 'love', description: 'В перспективе - семья'},
        {name: 'Общение', value: 'friendship', description: 'Возможно, близкое'},
        {name: 'Встречи', value: 'meet', description: 'Без обязательств'}
      ],

      //region Валидация email
      email: '',
      isEmailValid: false,
      isEmailError: false,
      isEmailServiceError: false,
      //endregion

      // Вход
      isEntered: false,

      // Дан ли ответ на главный вопрос
      isQuestionMain1: false,
      isQuestionMain2: false,
      isQuestionMain3: false,
      isQuestionMain4: false,
      isQuestionMain5: false,

      // Текущий вопрос
      currentQuestion: 0,


      // Показ результатов
      isResultReady: false,
      isBeforeResult: false,

      // возраст
      user: {
        day: null,
        month: null,
        year: null,
      },
      age: 0,
      ageDenied: false,
      // рост
      growth: null,
      // Случайные фразы для варианта 4
      customPhrase: ['- Я не похож на Киллиана Мерфи.',
        '- Я не похож на Тома Харди.',
        '- Я не похож на Арми Хаммера.',
        '- Я не похож на Джейка Джилленхола.',
        '- Я не похож на Криса Хемсворта.',
        '- Я не похож на Генри Кавилла.',
        '- Я не похож на Криса Пайна.',
        '- Я не похож на Райана Гослинга.',
        '- Я не похож на Криса Эванса.',
        '- Я не похож на Дэвида Бекхэма.'
      ],
      answers: [],
      answersObject: {},

      // Текущий прогресс вопросов
      currentProgress: 0,

      //region Результаты
      // Показ шагов
      isShowResults: [true, false, false, false, false],
      //region textarea с результатами
      resultSubject: null,
      resultTextarea1: null,
      resultTextarea2: null,
      resultTextarea3: null,
      resultTextarea4: null,
      resultTextarea5: null,
      resultTextarea1en: null,
      resultTextarea2en: null,
      resultTextarea3en: null,
      resultTextarea4en: null,
      resultTextarea5en: null,
      //endregion
      // Сохраните все результаты
      isShowResultAll: false,
      // Отправить на email
      isShowResultEmail: false,
      // Отправлено на email
      isShowResultSent: false,
      //endregion

      // Отправка формы
      ajaxRequest: false,
      postResults: [],

      // Todo: УДАЛИТЬ
      resultText1: null,

      // Сохраненное
      isSaved: false,

      // Доступ
      isAccess: false

    };
  },
  computed: {
    // Классы для поля email
    emailFieldClass: function () {
      return {
        error: this.isEmailError && !this.isEmailValid
      }
    },

    // Прогресс
    progressBar: function () {
      if (this.dataArray) {
        // Текущее значение прогресса
        return 100 / (4 + this.dataArray.length) * (this.currentQuestion + 4)
      }
      return 0
    }
  },
  methods: {

    setResults() {
      // {{ /* Мужчины */ }}
      if (this.sex === 'man') {

        //region Результат 1
        //Рост см. X1 и Х2 мужчина. Цель. С1. С2. П/П. Небольшая слабость – Кухня. В девушке ценю Д1 и Д2.
        this.resultTextarea1 = this.growth + ' см. ';
        this.resultTextarea1 += this.answersObject.q3 ? this.answersObject.q3[0].variant1.ru + ' и ' : '';
        this.resultTextarea1 += this.answersObject.q4 ? this.answersObject.q4[0].variant1.ru : '';
        this.resultTextarea1 += ' мужчина.';
        this.resultTextarea1 += ' ';
        this.resultTextarea1 += this.answersObject.q18 ? this.answersObject.q18[0].variant1.ru : '';
        this.resultTextarea1 += ' ';
        this.resultTextarea1 += this.answersObject.q1 ? this.answersObject.q1[0].variant1.ru : '';
        this.resultTextarea1 += ' ';
        this.resultTextarea1 += this.answersObject.q2 ? this.answersObject.q2[0].variant1.ru : '';
        this.resultTextarea1 += ' ';
        this.resultTextarea1 += this.answersObject.q11 && this.answersObject.q11[0].variant1.ru.length ? this.answersObject.q11[0].variant1.ru : '';
        this.resultTextarea1 += ' ';
        this.resultTextarea1 += 'Небольшая слабость – ';
        this.resultTextarea1 += this.answersObject.q14 ? this.answersObject.q14[0].variant1.ru : '';
        this.resultTextarea1 += ' ';
        this.resultTextarea1 += 'В девушке ценю ';
        this.resultTextarea1 += this.answersObject.q15 ? this.answersObject.q15[0].variant1.ru : '';
        this.resultTextarea1 += ' и ';
        this.resultTextarea1 += this.answersObject.q16 ? this.answersObject.q16[0].variant1.ru : '';
        //endregion

        //region Результат 1 английский
        if (this.isEnglish) {
          // Рост. X1 Х2. Цель. С1. С2. П/П. Кухня is my indulgence. Hope to find a girl who’s Д1 Д2.
          this.resultTextarea1en = this.growth + '. ';
          this.resultTextarea1en += this.answersObject.q3 ? this.answersObject.q3[0].variant1.en + ' ' : '';
          this.resultTextarea1en += this.answersObject.q4 ? this.answersObject.q4[0].variant1.en : '';
          this.resultTextarea1en += ' ';
          this.resultTextarea1en += this.answersObject.q18 ? this.answersObject.q18[0].variant1.en : '';
          this.resultTextarea1en += ' ';
          this.resultTextarea1en += this.answersObject.q1 ? this.answersObject.q1[0].variant1.en : '';
          this.resultTextarea1en += ' ';
          this.resultTextarea1en += this.answersObject.q2 ? this.answersObject.q2[0].variant1.en : '';
          this.resultTextarea1en += ' ';
          this.resultTextarea1en += this.answersObject.q11 && this.answersObject.q11[0].variant1.en.length ? this.answersObject.q11[0].variant1.en : '';
          this.resultTextarea1en += ' ';
          this.resultTextarea1en += this.answersObject.q14 ? this.answersObject.q14[0].variant1.en : '';
          this.resultTextarea1en += ' ';
          this.resultTextarea1en += 'is my indulgence.';
          this.resultTextarea1en += ' ';
          this.resultTextarea1en += 'Hope to find a girl who’s ';
          this.resultTextarea1en += this.answersObject.q15 ? this.answersObject.q15[0].variant1.en : '';
          this.resultTextarea1en += ' and ';
          this.resultTextarea1en += this.answersObject.q16 ? this.answersObject.q16[0].variant1.en : '';
        }
        //endregion

        //region Результат 2

        // Рост см.
        this.resultTextarea2 = this.growth + ' см.';
        this.resultTextarea2 += '<br><br>';
        // X1
        this.resultTextarea2 += this.answersObject.q3 ? this.answersObject.q3[0].variant2.ru + '<br><br>' : '';
        // Х2
        this.resultTextarea2 += this.answersObject.q4 ? this.answersObject.q4[0].variant2.ru + '<br><br>' : '';
        // С2
        this.resultTextarea2 += this.answersObject.q2 ? this.answersObject.q2[0].variant2.ru + '<br><br>' : '';
        // Смеюсь
        this.resultTextarea2 += this.answersObject.q8 ? this.answersObject.q8[0].variant2.ru + '<br><br>' : '';
        // Отдых
        this.resultTextarea2 += this.answersObject.q9 ? this.answersObject.q9[0].variant2.ru + '<br><br>' : '';
        // Футбол
        this.resultTextarea2 += this.answersObject.q12 && this.answersObject.q12[0].variant2.ru.length ? this.answersObject.q12[0].variant2.ru + '<br><br>' : '';
        // П/П
        this.resultTextarea2 += this.answersObject.q11 && this.answersObject.q11[0].variant2.ru.length ? this.answersObject.q11[0].variant2.ru + '<br><br>' : '';
        // Цель
        this.resultTextarea2 += this.answersObject.q18 ? this.answersObject.q18[0].variant2.ru : '';

        //endregion

        //region Результат 2
        if (this.isEnglish) {
          // Рост см.
          this.resultTextarea2en = this.growth + ' cm.';
          this.resultTextarea2en += '<br><br>';
          // X1
          this.resultTextarea2en += this.answersObject.q3 ? this.answersObject.q3[0].variant2.en + '<br><br>' : '';
          // Х2
          this.resultTextarea2en += this.answersObject.q4 ? this.answersObject.q4[0].variant2.en + '<br><br>' : '';
          // С2
          this.resultTextarea2en += this.answersObject.q2 ? this.answersObject.q2[0].variant2.en + '<br><br>' : '';
          // Смеюсь
          this.resultTextarea2en += this.answersObject.q8 && this.answersObject.q8[0].variant2.en.length ? this.answersObject.q8[0].variant2.en + '<br><br>' : '';
          // Отдых
          this.resultTextarea2en += this.answersObject.q9 ? this.answersObject.q9[0].variant2.en + '<br><br>' : '';
          // Футбол
          this.resultTextarea2en += this.answersObject.q12 && this.answersObject.q12[0].variant2.en.length ? this.answersObject.q12[0].variant2.en + '<br><br>' : '';
          // П/П
          this.resultTextarea2en += this.answersObject.q11 && this.answersObject.q11[0].variant2.en.length ? this.answersObject.q11[0].variant2.en + '<br><br>' : '';
          // Цель
          this.resultTextarea2en += this.answersObject.q18 ? this.answersObject.q18[0].variant2.en : '';
        }
        //endregion

        //region Результат 3

        // Рост см. X1
        this.resultTextarea3 = this.growth + ' см.';
        this.resultTextarea3 += ' ';
        this.resultTextarea3 += this.answersObject.q3 && this.answersObject.q3[0].variant3 ? this.answersObject.q3[0].variant3.ru + '<br><br>' : '';

        // С1
        this.resultTextarea3 += this.answersObject.q1 && this.answersObject.q1[0].variant3 ? this.answersObject.q1[0].variant3.ru + '<br><br>' : '';

        // Работа
        this.resultTextarea3 += this.answersObject.q17 && this.answersObject.q17[0].variant3.ru && this.answersObject.q17[0].variant3.ru.length ? this.answersObject.q17[0].variant3.ru + '<br><br>' : '';

        // Выходные
        this.resultTextarea3 += this.answersObject.q10 && this.answersObject.q10[0].variant3 ? this.answersObject.q10[0].variant3.ru + '<br><br>' : '';

        // Машина
        this.resultTextarea3 += this.answersObject.q13 && this.answersObject.q13[0].variant3 ? this.answersObject.q13[0].variant3.ru + '<br><br>' : '';

        // Цель
        this.resultTextarea3 += this.answersObject.q18 && this.answersObject.q18[0].variant3 ? this.answersObject.q18[0].variant3.ru : '';

        //endregion

        //region Результат 3 Английский
        if (this.isEnglish) {
          // Рост см. X1
          this.resultTextarea3en = this.growth + ' cm.';
          this.resultTextarea3en += ' ';
          this.resultTextarea3en += this.answersObject.q3 && this.answersObject.q3[0].variant3 ? this.answersObject.q3[0].variant3.en + '<br><br>' : '';

          // С1
          this.resultTextarea3en += this.answersObject.q1 && this.answersObject.q1[0].variant3 ? this.answersObject.q1[0].variant3.en + '<br><br>' : '';

          // Работа
          this.resultTextarea3en += this.answersObject.q17 && this.answersObject.q17[0].variant3.en && this.answersObject.q17[0].variant3.en.length ? this.answersObject.q17[0].variant3.en + '<br><br>' : '';

          // Выходные
          this.resultTextarea3en += this.answersObject.q10 && this.answersObject.q10[0].variant3 ? this.answersObject.q10[0].variant3.en + '<br><br>' : '';

          // Машина
          this.resultTextarea3en += this.answersObject.q13 && this.answersObject.q13[0].variant3 ? this.answersObject.q13[0].variant3.en + '<br><br>' : '';

          // Цель
          this.resultTextarea3en += this.answersObject.q18 && this.answersObject.q18[0].variant3 ? this.answersObject.q18[0].variant3.en : '';
        }
        //endregion

        //region Результат 4

        this.resultTextarea4 = 'Причины, почему ты будешь без ума от меня:';
        this.resultTextarea4 += '<br><br>';

        // Темперамент
        this.resultTextarea4 += this.answersObject.q5 && this.answersObject.q5[0].variant4 ? this.answersObject.q5[0].variant4.ru + '<br><br>' : '';

        // Делаю сам
        this.resultTextarea4 += this.answersObject.q6 && this.answersObject.q6[0].variant4 ? this.answersObject.q6[0].variant4.ru + '<br><br>' : '';

        // Одежда
        this.resultTextarea4 += this.answersObject.q7 && this.answersObject.q7[0].variant4 ? this.answersObject.q7[0].variant4.ru + '<br><br>' : '';

        // Работа
        this.resultTextarea4 += this.answersObject.q17 && this.answersObject.q17[0].variant4.ru && this.answersObject.q17[0].variant4.ru.length ? this.answersObject.q17[0].variant4.ru + '<br><br>' : '';

        // Цель
        this.resultTextarea4 += this.answersObject.q18 && this.answersObject.q18[0].variant4 ? this.answersObject.q18[0].variant4.ru + '<br><br>' : '';

        // Рост см.
        this.resultTextarea4 += this.growth + ' см.';

        //endregion

        //region Результат 4
        if (this.isEnglish) {
          this.resultTextarea4en = 'Reasons why you’d fall in love with me:';
          this.resultTextarea4en += '<br><br>';

          // Темперамент
          this.resultTextarea4en += this.answersObject.q5 && this.answersObject.q5[0].variant4 ? this.answersObject.q5[0].variant4.en + '<br><br>' : '';

          // Делаю сам
          this.resultTextarea4en += this.answersObject.q6 && this.answersObject.q6[0].variant4 ? this.answersObject.q6[0].variant4.en + '<br><br>' : '';

          // Одежда
          this.resultTextarea4en += this.answersObject.q7 && this.answersObject.q7[0].variant4 ? this.answersObject.q7[0].variant4.en + '<br><br>' : '';

          // Работа
          this.resultTextarea4en += this.answersObject.q17 && this.answersObject.q17[0].variant4.en && this.answersObject.q17[0].variant4.en.length ? this.answersObject.q17[0].variant4.en + '<br><br>' : '';

          // Цель
          this.resultTextarea4en += this.answersObject.q18 && this.answersObject.q18[0].variant4 ? this.answersObject.q18[0].variant4.en + '<br><br>' : '';

          // Рост см.
          this.resultTextarea4en += this.growth + ' cm.';
        }
        //endregion

        //region Результат 5

        // Все просто )
        this.resultTextarea5 = 'Все просто )';
        this.resultTextarea5 += '<br><br>';

        // Я: Х1 и Х2, С1, Смеюсь
        this.resultTextarea5 += 'Я: ';
        this.resultTextarea5 += this.answersObject.q3 && this.answersObject.q3[0].variant5.ru ? this.answersObject.q3[0].variant5.ru : '';
        this.resultTextarea5 += ' и ';
        this.resultTextarea5 += this.answersObject.q4 && this.answersObject.q4[0].variant5.ru ? this.answersObject.q4[0].variant5.ru : '';
        this.resultTextarea5 += ' ';
        this.resultTextarea5 += this.answersObject.q1 && this.answersObject.q1[0].variant5.ru ? this.answersObject.q1[0].variant5.ru : '';
        this.resultTextarea5 += ' ';
        this.resultTextarea5 += this.answersObject.q8 && this.answersObject.q8[0].variant5.ru ? this.answersObject.q8[0].variant5.ru + '<br><br>' : '';

        // Ты: Д1, Д2
        this.resultTextarea5 += 'Ты: ';
        this.resultTextarea5 += this.answersObject.q15 && this.answersObject.q15[0].variant5.ru ? this.answersObject.q15[0].variant5.ru : '';
        this.resultTextarea5 += ' ';
        this.resultTextarea5 += this.answersObject.q16 && this.answersObject.q16[0].variant5.ru ? this.answersObject.q16[0].variant5.ru + '<br><br>' : '';

        // Мы: встречаемся и болтаем о жизни.
        this.resultTextarea5 += 'Мы: встречаемся и болтаем о жизни.';
        this.resultTextarea5 += '<br><br>';

        // Мой рост - Рост см.
        this.resultTextarea5 += 'Мой рост - ' + this.growth + ' см.';

        //endregion

        //region Результат 5
        if (this.isEnglish) {
          // Все просто )
          this.resultTextarea5en = 'As simple as that 👇';
          this.resultTextarea5en += '<br><br>';

          // Я: Х1 Х2, С1, Смеюсь
          this.resultTextarea5en += 'Me: ';
          // X1
          this.resultTextarea5en += this.answersObject.q3 && this.answersObject.q3[0].variant5.en ? this.answersObject.q3[0].variant5.en : '';
          this.resultTextarea5en += ' ';
          // X2
          this.resultTextarea5en += this.answersObject.q4 && this.answersObject.q4[0].variant5.en ? this.answersObject.q4[0].variant5.en : '';
          this.resultTextarea5en += ' ';
          // C1
          this.resultTextarea5en += this.answersObject.q1 && this.answersObject.q1[0].variant5.en ? this.answersObject.q1[0].variant5.en : '';
          this.resultTextarea5en += ' ';
          // Смеюсь
          this.resultTextarea5en += this.answersObject.q8 && this.answersObject.q8[0].variant5.en ? this.answersObject.q8[0].variant5.en + '<br><br>' : '';

          // Ты: Д1, Д2
          this.resultTextarea5en += 'You: ';
          this.resultTextarea5en += this.answersObject.q15 && this.answersObject.q15[0].variant5.en ? this.answersObject.q15[0].variant5.en : '';
          this.resultTextarea5en += ' ';
          this.resultTextarea5en += this.answersObject.q16 && this.answersObject.q16[0].variant5.en ? this.answersObject.q16[0].variant5.en + '<br><br>' : '';

          // Мы: встречаемся и болтаем о жизни.
          this.resultTextarea5en += 'We: meet and have a lovely chit-chat.';
          this.resultTextarea5en += '<br><br>';

          // Мой рост - Рост см.
          this.resultTextarea5en += 'I’m ' + this.growth + ' cm tall.';
        }
        //endregion

      }

      // {{ /* Женщины */ }}
      if (this.sex === "woman") {

        //region Результат 1

        this.resultTextarea1 = '';
        // Х1 и Х2 девушка
        this.resultTextarea1 += this.answersObject.q1 && this.answersObject.q1[0].variant1 ? this.answersObject.q1[0].variant1.ru + ' и ' : '';
        this.resultTextarea1 += this.answersObject.q3 && this.answersObject.q3[0].variant1 ? this.answersObject.q3[0].variant1.ru + ' девушка.' : '';
        this.resultTextarea1 += ' ';
        // Х3
        this.resultTextarea1 += this.answersObject.q4 && this.answersObject.q4[0].variant1? this.answersObject.q4[0].variant1.ru : '';
        this.resultTextarea1 += ' ';
        // Мне нравится
        this.resultTextarea1 += this.answersObject.q2 && this.answersObject.q2[0].variant1 ? this.answersObject.q2[0].variant1.ru : '';
        this.resultTextarea1 += ' ';
        // Готовка.
        this.resultTextarea1 += this.answersObject.q14 && this.answersObject.q14[0].variant1 && this.answersObject.q14[0].variant1.ru.length ? this.answersObject.q14[0].variant1.ru : '';
        this.resultTextarea1 += ' ';
        // Уют.
        this.resultTextarea1 += this.answersObject.q15 && this.answersObject.q15[0].variant1 ? this.answersObject.q15[0].variant1.ru : '';
        this.resultTextarea1 += ' ';
        // Цель.
        this.resultTextarea1 += this.answersObject.q21 && this.answersObject.q21[0].variant1 ? this.answersObject.q21[0].variant1.ru + '<br><br>' : '';
        // if (this.answersObject.q21) {
        //   this.resultTextarea1 += this.answersObject.q21[0].variant1.ru + '<br><br>';
        // }
        //Мой рост – Рост см.
        this.resultTextarea1 += 'Мой рост - ' + this.growth + ' см.';

        //endregion

        //region Результат 1 английский
        if (this.isEnglish) {
          this.resultTextarea1en = '';
          // Х1 и Х2 девушка
          this.resultTextarea1en += this.answersObject.q1 && this.answersObject.q1[0].variant1 ? this.answersObject.q1[0].variant1.en + ' and ' : '';
          this.resultTextarea1en += this.answersObject.q3 && this.answersObject.q3[0].variant1 ? this.answersObject.q3[0].variant1.en + '.' : '';
          this.resultTextarea1en += ' ';
          // Х3
          this.resultTextarea1en += this.answersObject.q4 && this.answersObject.q4[0].variant1 ? this.answersObject.q4[0].variant1.en : '';
          this.resultTextarea1en += ' ';
          // Мне нравится
          this.resultTextarea1en += this.answersObject.q2 && this.answersObject.q2[0].variant1 ? this.answersObject.q2[0].variant1.en : '';
          this.resultTextarea1en += ' ';
          // Готовка.
          this.resultTextarea1en += this.answersObject.q14 && this.answersObject.q14[0].variant1 && this.answersObject.q14[0].variant1.en.length ? this.answersObject.q14[0].variant1.en : '';
          this.resultTextarea1en += ' ';
          // Уют.
          this.resultTextarea1en += this.answersObject.q15 && this.answersObject.q15[0].variant1 && this.answersObject.q15[0].variant1.en.length ? this.answersObject.q15[0].variant1.en : '';
          this.resultTextarea1en += ' ';
          // Цель.
          this.resultTextarea1en += this.answersObject.q21 && this.answersObject.q21[0].variant1 ? this.answersObject.q21[0].variant1.en + '<br><br>' : '';
          // if (this.answersObject.q21) {
          //   this.resultTextarea1en += this.answersObject.q21[0].variant1.en + '<br><br>';
          // }
          //Мой рост – Рост см.
          // this.resultTextarea1en += 'Мой рост - ' + this.growth + ' см.';
          this.resultTextarea1en += 'I’m ' + this.growth + ' cm tall.';
        }
        //endregion

        //region Результат 2

        // Рост. Статус. Дети.
        this.resultTextarea2 = this.growth + '.';
        this.resultTextarea2 += ' ';
        this.resultTextarea2 += this.answersObject.q20 && this.answersObject.q20[0].variant2 ? this.answersObject.q20[0].variant2.ru : '';
        this.resultTextarea2 += ' ';
        this.resultTextarea2 += this.answersObject.q19 && this.answersObject.q19[0].variant2 ? this.answersObject.q19[0].variant2.ru + '<br><br>' : '';
        // Готова снова влюбиться.
        this.resultTextarea2 += 'Готова';
        this.resultTextarea2 += ' ';
        this.resultTextarea2 += this.answersObject.q20 && this.answersObject.q20[0].variant2  && this.answersObject.q20[0].variant2.ru === "В разводе." ? 'снова ' : '';
        this.resultTextarea2 += 'влюбиться.';
        this.resultTextarea2 += '<br><br>';
        // М2 М1
        this.resultTextarea2 += this.answersObject.q7 && this.answersObject.q7[0].variant2 ? this.answersObject.q7[0].variant2.ru : '';
        this.resultTextarea2 += ' ';
        this.resultTextarea2 += this.answersObject.q6 && this.answersObject.q6[0].variant2 ? this.answersObject.q6[0].variant2.ru + '<br><br>' : '';
        // Цель
        this.resultTextarea2 += this.answersObject.q21 && this.answersObject.q21[0].variant2 ? this.answersObject.q21[0].variant2.ru + '<br><br>' : '';
        // if (this.answersObject.q21) {
        //   this.answersObject.q21[0].variant2.ru + '<br><br>';
        // }
        // Рост мужчины
        this.resultTextarea2 += this.answersObject.q18 && this.answersObject.q18[0].variant2 ? this.answersObject.q18[0].variant2.ru + '<br><br>' : '';
        this.resultTextarea2 += 'Альфонсы и женатые мужчины, пожалуйста, проходите мимо❗';

        //endregion

        //region Результат 2 Английский
        if (this.isEnglish) {
          // Рост. Статус. Дети.
          this.resultTextarea2en = this.growth + '.';
          this.resultTextarea2en += ' ';
          this.resultTextarea2en += this.answersObject.q20 && this.answersObject.q20[0].variant2 ? this.answersObject.q20[0].variant2.en : '';
          this.resultTextarea2en += ' ';
          this.resultTextarea2en += this.answersObject.q19 && this.answersObject.q19[0].variant2 ? this.answersObject.q19[0].variant2.en + '<br><br>' : '';
          // Готова снова влюбиться.
          this.resultTextarea2en += 'Ready to fall in love';
          this.resultTextarea2en += this.answersObject.q20 && this.answersObject.q20[0].variant2 && this.answersObject.q20[0].variant2.ru === "В разводе." ? ' again' : '';
          this.resultTextarea2en += '.';
          this.resultTextarea2en += '<br><br>';
          // М2 М1
          this.resultTextarea2en += this.answersObject.q7 && this.answersObject.q7[0].variant2 ? this.answersObject.q7[0].variant2.en : '';
          this.resultTextarea2en += ' ';
          this.resultTextarea2en += this.answersObject.q6 && this.answersObject.q6[0].variant2 ? this.answersObject.q6[0].variant2.en + '<br><br>' : '';
          // Цель
          this.resultTextarea2en += this.answersObject.q21 && this.answersObject.q21[0].variant2 ? this.answersObject.q21[0].variant2.en + '<br><br>' : '';

          // Рост мужчины
          this.resultTextarea2en += this.answersObject.q18 && this.answersObject.q18[0].variant2 ? this.answersObject.q18[0].variant2.en + '<br><br>' : '';
          this.resultTextarea2en += 'No ons, pls.';
        }
        //endregion

        //region Результат 3

        // Рост. Цель
        this.resultTextarea3 = this.growth + '. ';
        this.resultTextarea3 += this.answersObject.q21 && this.answersObject.q21[0].variant3 && this.answersObject.q21[0].variant3.ru ? this.answersObject.q21[0].variant3.ru + '<br><br>' : '';

        this.resultTextarea3 += this.answersObject.q14 && this.answersObject.q14[0].variant3 && this.answersObject.q14[0].variant3.ru ? this.answersObject.q14[0].variant3.ru + '<br><br>' : '';

        // Музыка. Кино. Отдых
        this.resultTextarea3 += this.answersObject.q8 && this.answersObject.q8[0].variant3 && this.answersObject.q8[0].variant3.ru ? this.answersObject.q8[0].variant3.ru + '<br><br>' : '';
        // this.resultTextarea3 += " ";
        this.resultTextarea3 += this.answersObject.q9 && this.answersObject.q9[0].variant3 && this.answersObject.q9[0].variant3.ru ? this.answersObject.q9[0].variant3.ru + '<br><br>' : '';
        // this.resultTextarea3 += " ";
        this.resultTextarea3 += this.answersObject.q17 && this.answersObject.q17[0].variant3 && this.answersObject.q17[0].variant3.ru && this.answersObject.q17[0].variant3.ru.length ? this.answersObject.q17[0].variant3.ru + '<br><br>' : '';
        this.resultTextarea3 += "Женатым просьба не беспокоить.";
        this.resultTextarea3 += "<br><br>";
        this.resultTextarea3 += "Ловеласам ⛔";

        //endregion

        //region Результат 3 Английский
        if (this.isEnglish) {
          // Рост. Цель
          this.resultTextarea3en = this.growth + '. ';
          this.resultTextarea3en += this.answersObject.q21 && this.answersObject.q21[0].variant3 && this.answersObject.q21[0].variant3.en ? this.answersObject.q21[0].variant3.en + '<br><br>' : '';

          // Музыка
          this.resultTextarea3en += this.answersObject.q8 && this.answersObject.q8[0].variant3 && this.answersObject.q8[0].variant3.en ? this.answersObject.q8[0].variant3.en + '<br><br>' : '';
          // Кино
          this.resultTextarea3en += this.answersObject.q9 && this.answersObject.q9[0].variant3 && this.answersObject.q9[0].variant3.en ? this.answersObject.q9[0].variant3.en + '<br><br>' : '';
          // Отдых
          this.resultTextarea3en += this.answersObject.q17 && this.answersObject.q17[0].variant3 && this.answersObject.q17[0].variant3.en && this.answersObject.q17[0].variant3.en.length ? this.answersObject.q17[0].variant3.en + '<br><br>' : '';
          this.resultTextarea3en += "No ons, pls";
        }
        //endregion

        //region Результат 4

        // Рост
        this.resultTextarea4 = this.growth + '.';
        this.resultTextarea4 += '<br><br>';
        // Цель
        if (this.answersObject.q21) {
          // this.resultTextarea4 += this.answersObject.q21 && this.answersObject.q21[0].variant4 && this.answersObject.q21[0].variant4.ru ? this.answersObject.q21[0].variant4.ru + '<br><br>' : '';
          this.resultTextarea4 += this.answersObject.q21[0].variant4.ru + '<br><br>';
        }
        // Х2 и Х1
        this.resultTextarea4 += this.answersObject.q3 && this.answersObject.q3[0].variant4 && this.answersObject.q3[0].variant4.ru ? this.answersObject.q3[0].variant4.ru : '';
        this.resultTextarea4 += ' ';
        this.resultTextarea4 += this.answersObject.q1 && this.answersObject.q1[0].variant4 && this.answersObject.q1[0].variant4.ru ? this.answersObject.q1[0].variant4.ru + '<br><br>' : '';
        // М2 и М1
        this.resultTextarea4 += this.answersObject.q7 && this.answersObject.q7[0].variant4 && this.answersObject.q7[0].variant4.ru ? this.answersObject.q7[0].variant4.ru : '';
        this.resultTextarea4 += ' ';
        this.resultTextarea4 += this.answersObject.q6 && this.answersObject.q6[0].variant4 && this.answersObject.q6[0].variant4.ru ? this.answersObject.q6[0].variant4.ru + '<br><br>' : '';

        // Ч/Ю
        this.resultTextarea4 += this.answersObject.q12 && this.answersObject.q12[0].variant4 && this.answersObject.q12[0].variant4.ru ? this.answersObject.q12[0].variant4.ru + '<br><br>' : '';

        // Спорт
        this.resultTextarea4 += this.answersObject.q5 && this.answersObject.q5[0].variant4 && this.answersObject.q5[0].variant4.ru ? this.answersObject.q5[0].variant4.ru + '<br><br>' : '';
        // Смеюсь
        this.resultTextarea4 += this.answersObject.q10 && this.answersObject.q10[0].variant4 && this.answersObject.q10[0].variant4.ru ? this.answersObject.q10[0].variant4.ru + '<br><br>' : '';
        // Машина
        this.resultTextarea4 += this.answersObject.q11 && this.answersObject.q11[0].variant4 && this.answersObject.q11[0].variant4.ru && this.answersObject.q11[0].variant4.ru.length ? this.answersObject.q11[0].variant4.ru : '';

        //endregion

        //region Результат 4 Английский
        if (this.isEnglish) {
          // Рост
          this.resultTextarea4en = this.growth + '.';
          this.resultTextarea4en += '<br><br>';
          // Цель
          if (this.answersObject.q21) {
            this.resultTextarea4en += this.answersObject.q21[0].variant4.en + '<br><br>';
          }
          // Х2 и Х1
          this.resultTextarea4en += this.answersObject.q3 && this.answersObject.q3[0].variant4 && this.answersObject.q3[0].variant4.en ? this.answersObject.q3[0].variant4.en : '';
          this.resultTextarea4en += ' ';
          this.resultTextarea4en += this.answersObject.q1 && this.answersObject.q1[0].variant4 && this.answersObject.q1[0].variant4.en ? this.answersObject.q1[0].variant4.en + '<br><br>' : '';
          // М2 и М1
          this.resultTextarea4en += this.answersObject.q7 && this.answersObject.q7[0].variant4 && this.answersObject.q7[0].variant4.en ? this.answersObject.q7[0].variant4.en : '';
          this.resultTextarea4en += ' ';
          this.resultTextarea4en += this.answersObject.q6 && this.answersObject.q6[0].variant4 && this.answersObject.q6[0].variant4.en ? this.answersObject.q6[0].variant4.en + '<br><br>' : '';

          // Ч/Ю
          this.resultTextarea4en += this.answersObject.q12 && this.answersObject.q12[0].variant4 && this.answersObject.q12[0].variant4.en ? this.answersObject.q12[0].variant4.en + '<br><br>' : '';

          // Спорт
          this.resultTextarea4en += this.answersObject.q5 && this.answersObject.q5[0].variant4 && this.answersObject.q5[0].variant4.en ? this.answersObject.q5[0].variant4.en + '<br><br>' : '';
          // Смеюсь
          this.resultTextarea4en += this.answersObject.q10 && this.answersObject.q10[0].variant4 && this.answersObject.q10[0].variant4.en ? this.answersObject.q10[0].variant4.en + '<br><br>' : '';
          // Машина
          this.resultTextarea4en += this.answersObject.q11 && this.answersObject.q11[0].variant4 && this.answersObject.q11[0].variant4.en && this.answersObject.q11[0].variant4.en.length ? this.answersObject.q11[0].variant4.en : '';
        }
        //endregion

        //region Результат 5

        // Рост. Х1, Х2 и разносторонняя девушка.
        this.resultTextarea5 = this.growth + '. ';
        this.resultTextarea5 += this.answersObject.q1 && this.answersObject.q1[0].variant5.ru ? this.answersObject.q1[0].variant5.ru : '';
        this.resultTextarea5 += ' ';
        this.resultTextarea5 += this.answersObject.q3 && this.answersObject.q3[0].variant5.ru ? this.answersObject.q3[0].variant5.ru : '';
        this.resultTextarea5 += ' и разносторонняя девушка.';
        this.resultTextarea5 += '<br><br>';
        // Музыка
        this.resultTextarea5 += this.answersObject.q8 && this.answersObject.q8[0].variant5.ru ? this.answersObject.q8[0].variant5.ru + '<br><br>' : '';
        // Кино
        this.resultTextarea5 += this.answersObject.q9 && this.answersObject.q9[0].variant5.ru ? this.answersObject.q9[0].variant5.ru + '<br><br>' : '';
        // Отдых
        this.resultTextarea5 += this.answersObject.q17 && this.answersObject.q17[0].variant5.ru && this.answersObject.q17[0].variant5.ru.length ? this.answersObject.q17[0].variant5.ru + '<br><br>' : '';
        // Смеюсь
        this.resultTextarea5 += this.answersObject.q10 && this.answersObject.q10[0].variant5.ru ? this.answersObject.q10[0].variant5.ru + '<br><br>' : '';
        // Первое свидание
        this.resultTextarea5 += this.answersObject.q16 && this.answersObject.q16[0].variant5.ru ? this.answersObject.q16[0].variant5.ru + '<br><br>' : '';
        // Аллергия
        this.resultTextarea5 += this.answersObject.q13 && this.answersObject.q13[0].variant5.ru ? this.answersObject.q13[0].variant5.ru + '<br><br>' : '';
        // Цель
        if (this.answersObject.q21) {
          // this.resultTextarea5 += this.answersObject.q21 && this.answersObject.q21[0].variant5.ru ? this.answersObject.q21[0].variant5.ru + '<br><br>' : '';
          this.resultTextarea5 += this.answersObject.q21[0].variant5.ru + '<br><br>';
        }
        this.resultTextarea5 += 'Если не ответила на твое сообщение, значит, мы друг другу не подходим. Без обид 🖐';

        //endregion

        //region Результат 5
        if (this.isEnglish) {
          // Рост. Х1, Х2 и разносторонняя девушка.
          this.resultTextarea5en = this.growth + '. ';
          this.resultTextarea5en += this.answersObject.q1 && this.answersObject.q1[0].variant5.en ? this.answersObject.q1[0].variant5.en : '';
          this.resultTextarea5en += ' ';
          this.resultTextarea5en += this.answersObject.q3 && this.answersObject.q3[0].variant5.en ? this.answersObject.q3[0].variant5.en : '';
          this.resultTextarea5en += ' and seemingly multi-hyphenate girl.';
          this.resultTextarea5en += '<br><br>';
          // Музыка
          this.resultTextarea5en += this.answersObject.q8 && this.answersObject.q8[0].variant5.en ? this.answersObject.q8[0].variant5.en + '<br><br>' : '';
          // Кино
          this.resultTextarea5en += this.answersObject.q9 && this.answersObject.q9[0].variant5.en ? this.answersObject.q9[0].variant5.en + '<br><br>' : '';
          // Отдых
          this.resultTextarea5en += this.answersObject.q17 && this.answersObject.q17[0].variant5.en && this.answersObject.q17[0].variant5.en.length ? this.answersObject.q17[0].variant5.en + '<br><br>' : '';
          // Смеюсь
          this.resultTextarea5en += this.answersObject.q10 && this.answersObject.q10[0].variant5.en ? this.answersObject.q10[0].variant5.en + '<br><br>' : '';
          // Первое свидание
          this.resultTextarea5en += this.answersObject.q16 && this.answersObject.q16[0].variant5.en ? this.answersObject.q16[0].variant5.en + '<br><br>' : '';
          // Аллергия
          this.resultTextarea5en += this.answersObject.q13 && this.answersObject.q13[0].variant5.en ? this.answersObject.q13[0].variant5.en + '<br><br>' : '';
          // Цель
          if (this.answersObject.q21) {
            this.resultTextarea5en += this.answersObject.q21[0].variant5.en + '<br><br>';
          }
          this.resultTextarea5en += 'If I leave your message without reply, it probably means there’s no chemistry. No offence, ok?';
        }
        //endregion
      }

      //region Сохранение данных для dates
      let dates = {
        result1: this.resultTextarea1,
        result2: this.resultTextarea2,
        result3: this.resultTextarea3,
        result4: this.resultTextarea4,
        result5: this.resultTextarea5,
        result1en: this.resultTextarea1en,
        result2en: this.resultTextarea2en,
        result3en: this.resultTextarea3en,
        result4en: this.resultTextarea4en,
        result5en: this.resultTextarea5en
      }

      this.saveDataToAnonymous(dates);
      //endregion

    },

    // Сохранение данных для анонима
    saveDataToAnonymous(dates) {
      this.$store.dispatch('setSavedData', {dates});
    },

    loadData() {
      // Переход к вопросам
      this.isQuestionMain4 = true;
      this.isQuestionMain3 = true;

      // Загрузка данных
      let self = this
      axios
          .get('json/' + this.sex + '.json?v=' + Date.now())
          .then(function (response) {
            self.dataArray = response.data.steps;
            self.resultsTitles = response.data.results_titles;
          });
    },

    // region Шаги
    onChangeStep(data) {
      // todo: УДАЛИТЬ
      this.setResults();


      this.currentQuestion = data.currentStep;

      // if (data.currentStep === 5) {
      //   this.$gtag.event('q5');
      // }
      // else if (data.currentStep === 10) {
      //   this.$gtag.event('q10');
      // }
      // else if (data.currentStep === 15) {
      //   this.$gtag.event('q15');
      // }

    },
    onLastStep() {

      this.setResults();

      // this.isBeforeResult = true;
      this.isResultReady = true;

      // this.$gtag.event('q_last');

    },
    onSkipStep(data) {
      this.currentQuestion = data.currentStep;

      // if (data.currentStep === 5) {
      //   this.$gtag.event('q5');
      // }
      // else if (data.currentStep === 10) {
      //   this.$gtag.event('q10');
      // }
      // else if (data.currentStep === 15) {
      //   this.$gtag.event('q15');
      // }
    },
    // Получаем ответ на вопрос
    onSetAnswer(data) {

      //console.log('data', data.answerResults[0]);

      const key = 'q' + this.dataArray[this.currentQuestion].id;
      // this.answersObject.key = data.answer;

      // Получаем варианты ответов на вопроы
      this.$set(this.answersObject, key, data.answerResults);

      // this.$set(this.answersObject[key], data.answer);

      this.answers.push({
        id: this.dataArray[this.currentQuestion].id,
        answer: data.answer
      });
    },
    //endregion

    openFirstResult() {
      this.isBeforeResult = false;
      this.isResultReady = true;

      // this.$gtag.event('show_result');
    },

    // region Дата рождения
    submitAge(e) {

      if ((this.user.day && this.user.month && this.user.year) &&
          this.user.day.length > 0 &&
          this.user.month.length > 0 &&
          this.user.year.toString().length === 4) {

        if (this.user.day.length === 1) {
          this.user.day = 0 + this.user.day;
        }
        if (this.user.month.length === 1) {
          this.user.month = 0 + this.user.month;
        }

        this.age = moment(moment.now()).diff(moment(this.user.day + this.user.month + this.user.year, "DD.MM.YYYY"), "years");
        if (this.age >= 18) {
          // Переход к следующему шагу
          this.isQuestionMain1 = true;
        }
        else {
          // Запрещаем
          this.ageDenied = true;
        }
        e.preventDefault();
      }
    },
    // Возраст
    returnAge() {
      this.user.day = null;
      this.user.month = null;
      this.user.year = null;
      this.ageDenied = false;
      this.age = null;
    },
    dateDayCheck: function (field) {
      if (field > 31) {
        this.user.day = 31;
      }

      if (field.length === 2) {
        this.$refs.birthday_month.$el.focus();
      }
    },
    // dateMonthFocus: function() {
    //   console.log('focus');
    //   this.$refs.birthday_month.$el.focus();
    // },
    dateMonthCheck: function (field) {
      if (field > 12) {
        this.user.month = 12;
      }
      if (field.length === 2) {
        this.$refs.birthday_year.$el.focus();
      }
    },
    dateYearCheck: function (field) {
      if (field.length === 4) {
        const currentYear = moment().year();

        if (field < 1900) {
          this.user.year = 1900;
        }
        else if (field > currentYear) {
          this.user.year = currentYear;
        }
      }
    },
    //endregion

    // Пол
    submitSex(variant) {
      // Пол
      this.sex = variant;
      // Убираю цель "встречи" 🤷
      if (variant === "woman") {
        this.goals.splice(2, 1);
      }
      // Переход к следующему шагу
      this.isQuestionMain1 = false;
      this.isQuestionMain4 = true;

      this.loadData();
    },

    // Цель
    submitGoal(variant) {
      this.goalSelected = variant.value;
      this.goalSelectedName = variant.name;
      // console.log('goalSelected', this.goalSelected);
      // console.log('variant', variant);
      // Переход к следующему шагу
      this.isQuestionMain3 = true;

      //region Метрика
      if (variant.value === "love") {
        this.$metrika.reachGoal('goal_love');

        if (this.sex === "man") {
          this.$metrika.reachGoal('man_goal_love');
        }
        else if (this.sex === "woman") {
          this.$metrika.reachGoal('woman_goal_love');
        }
      }
      else if (variant.value === "friendship") {
        this.$metrika.reachGoal('goal_friendship');

        if (this.sex === "man") {
          this.$metrika.reachGoal('woman_goal_friendship');
        }
        else if (this.sex === "woman") {
          this.$metrika.reachGoal('woman_goal_friendship');
        }
      }
      else if (variant.value === "meet") {
        this.$metrika.reachGoal('man_goal_meet');
      }
      //endregion Метрика
    },

    //region Рост
    submitGrowth(e) {
      // console.log('this.growth', this.growth);
      if ((this.growth) && this.growth.toString().length === 3 && this.growth > 100 && this.growth < 273) {
        // Переход к следующему шагу
        this.isQuestionMain5 = true;
        e.preventDefault();
      }
    },
    growthCheck(field) {
      if (field.length === 3) {
        if (field > 272) {
          this.growth = 272;
        }
        else if (field < 100) {
          this.growth = 100;
        }
      }
    },
    //endregion

    // Получить случайную фразу из списка для варианта 4 мужчины - любовь
    getCustomPhrase: function () {
      var chosenNumber = Math.floor(Math.random() * this.customPhrase.length);
      return this.customPhrase[chosenNumber];
    },

    // Скопировать результат
    copyResult: function (number) {
      let text = '';

      if (this.language === "ru") {
        text = this.$refs['result' + number].innerText
      }
      else {
        text = this.$refs['result' + number + 'en'].innerText
      }

      // Копировать
      this.$copyText(text);
      // Сменить текст на кнопке
      //this.$refs['resultBtn' + number].innerText = 'Скопировано';
    },

    // Ходим по шагам результатов на стрелочках
    changeResult: function (currentStep, action) {

      // Скрываем все результаты
      this.isShowResults = [false, false, false, false, false];

      // console.log('isShowResults', this.isShowResults);
      if (currentStep === 1 && action === 'prev') {
        this.isShowResults[0] = true;
        // this.isShowResult1 = true;
      }
      else if (action === 'next') {
        // заполнение данных
        // this["resultTextarea" + currentStep] = this.$refs['result' + currentStep].innerText;
        // this["resultTextarea" + currentStep + "en"] = this.$refs['result' + currentStep + 'en'].innerText;

        // Меняем шаг
        this.isShowResults[currentStep] = true;

        if (currentStep === 5) {
          this.isShowResultAll = true;
        }

      }
      else {
        // console.log('currentStep', currentStep - 1);
        this.isShowResults[currentStep - 2] = true;
        // var prevStep = currentStep - 1;
        // console.log('else');
      }

      // console.log('res', this.isShowResults);

      // console.log('step', currentStep);
      // console.log('action', action);
    },

    // Ходим по шагам результатов
    changeResultPager: function (step) {

      // Скрываем все результаты
      this.isShowResults = [false, false, false, false, false];

      let currentStep = step - 1;

      // if (this.$refs['result' + currentStep]) {
      //   this["resultTextarea" + currentStep] = this.$refs['result' + currentStep].innerText;
      // }

      // if (this.$refs['result' + currentStep + 'en']) {
      //   this["resultTextarea" + currentStep + "en"] = this.$refs['result' + currentStep + 'en'].innerText;
      // }

      this.isShowResults[currentStep] = true;

    },

    goToSendToEmail: function () {
      this.isShowResultAll = false;
      this.isShowResultEmail = true;
    },

    // Возврат к вариантам, если сломалась почта
    returnToVariants: function () {
      this.isShowResultAll = false;
      this.isShowResultEmail = false;
      this.isShowResults = [true, false, false, false, false];
    },

    // Валидация email
    emailValidation: function (value) {
      if (emailCheckRegex.test(value)) {
        this.isEmailValid = true;
        this.isEmailError = false;
      }
      else {
        this.isEmailValid = false;
      }
    },

    // Отправка формы
    submitToEmail: function () {

      if (!this.isEmailValid) {
        this.isEmailError = true;
      }
      else {
        let self = this;

        axios.post(process.env.VUE_APP_DOMAIN + 'php/sendpulse.php', {
          result_1_title: this.resultsTitles[0],
          result_2_title: this.resultsTitles[1],
          result_3_title: this.resultsTitles[2],
          result_4_title: this.resultsTitles[3],
          result_5_title: this.resultsTitles[4],
          result_1: this.resultTextarea1,
          result_2: this.resultTextarea2,
          result_3: this.resultTextarea3,
          result_4: this.resultTextarea4,
          result_5: this.resultTextarea5,
          result_1_en: this.resultTextarea1en,
          result_2_en: this.resultTextarea2en,
          result_3_en: this.resultTextarea3en,
          result_4_en: this.resultTextarea4en,
          result_5_en: this.resultTextarea5en,
          email: this.email,
          subject: this.goalSelectedName,
          sex: this.sex,
          goal: this.goalSelected
        })
            .then(function (response) {
              // console.log(response);

              // console.log('response.status', response.data.status);
              // console.log('response.status type', typeof response.data.status);

              if (response.data.status === "error" || !response.data.status) {
                alert('Не получается отправить email. Извините.')

                if (process.env.VUE_APP_TYPE === "local") {
                  console.log('response error', response.data);
                }
              }
              else {
                self.isShowResultEmail = false;
                self.isShowResultSent = true;

                // Отправляем событие в google tag
                self.$gtag.event('email_was_sent');
              }

            })
            .catch(function (error) {
              if (error) {
                alert('Не получается отправить email. Извините.')
                self.isEmailServiceError = true;
                if (process.env.VUE_APP_TYPE === "local") {
                  console.log('error', error);
                }
              }
              self.isShowResultEmail = true;
              self.isShowResultSent = false;
            });
      }


    },

    // Сохранить результаты
    saveResult() {
      this.isSaved = true;

      let self = this;

      axios({
        url: process.env.VUE_APP_API + 'dates-arr',
        data: {
          dates: [
            {
              name: 'Версия 5',
              version: this['resultTextarea5'],
              version_en: this['resultTextarea5en']
            },
            {
              name: 'Версия 4',
              version: this['resultTextarea4'],
              version_en: this['resultTextarea4en']
            },
            {
              name: 'Версия 3',
              version: this['resultTextarea3'],
              version_en: this['resultTextarea3en']
            },
            {
              name: 'Версия 2',
              version: this['resultTextarea2'],
              version_en: this['resultTextarea2en']
            },
            {
              name: 'Версия 1',
              version: this['resultTextarea1'],
              version_en: this['resultTextarea1en']
            }
          ]
        },
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then(function (response) {

            if (process.env.VUE_APP_TYPE === 'local' || (self.$route.query && self.$route.query.isDebug === true)) {
              console.log('response', response);
            }

            // Обновляем "Обо мне" (+1)
            self.$store.commit('updateUserSavedPlus', 5);


          })
          .catch(function (error) {
            if (process.env.VUE_APP_TYPE === 'local' || (self.$route.query && self.$route.query.isDebug === true)) {
              console.log(error)
              console.log(error.response);
            }
          });
    },

    getAccess() {
      console.log('getAccess()');
      this.isDataLoaded = true;
      //region Проверка платной подписки
      if (localStorage.getItem('token')) {
        console.log('getAccess() with token');

        checkAccess.isAccess.then((res) => {
          if (res === true) {
            this.isAccess = true;
          }
          else {
            this.isAccess = false;
          }
        });
      }
      //endregion
    },

  },
  mounted() {

    if (process.env.VUE_APP_TYPE === 'local') {
      // this.isDebugMode = true;
    }

    //region Проверка платной подписки
    this.getAccess();
    //endregion

  }
}
</script>

<style scoped>

.debug-mode {
  width: 100px;
  height: 100px;
  position: fixed;
  bottom: 0;
  right: 0;
  border: 1px solid #000;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 1000px) {
  .debug-mode {
    display: none;
  }
}

</style>

<style lang="scss">
body {
  //padding-top: 0;
  //padding-bottom: 0;
}

.question__variants_radio-simple {
  .v-select {
    min-height: 36px;
    /*margin-left: 10px;*/
    /*min-width: 200px;*/
    display: inline-block;
    background: #fff;
    border-radius: 5px;
    vertical-align: middle;
    border: 1px solid #d4d4d4;

    .vs__clear {
      display: none;
    }

    .vs__selected-options {
      font-size: inherit;
    }

    .vs__dropdown-toggle {
      border: 0;
      font-size: 17px;
      min-height: 31px;
    }

    .vs__dropdown-menu {
      background: #fff;
      top: 0;
      border: 0;
      border-radius: 5px;
      margin-top: -1px;
      border: 1px solid #d4d4d4;
      margin-left: -1px;

      overflow-y: scroll;
      max-height: 100%;
      min-height: 140px;

      .vs__dropdown-option {
        font-size: 17px;
        color: #999;
        padding-left: 6px;
        padding-right: 6px;

        &--selected {
          color: #000;
        }

        &--highlight {
          background-color: #ebf0fa;
          font-weight: bold;
          color: #000;
        }
      }
    }
  }
}

.result__buttons_full {
  min-width: 100%;
  width: 100%;
  margin-bottom: 40px;
}

.result__copy {
  cursor: pointer;
}

</style>