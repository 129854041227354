<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let resultToChange = null;
      this.unusedVars = resultToChange;
      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1
      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>'

      //канал продаж
      if (answersObject.q1) {
        // Если это целое предложение
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q1, variant, language, '', '<br/><br/>')
        this['resultTextarea' + variant + isEn] += '<br/><br/>'
      }

      // О2, О3
      if (answersObject.q2) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q2);

        //О1
        if (answersObject.q2['2_1'] || answersObject.q2['2_2'] || answersObject.q2['2_3']) {
          this['resultTextarea' + variant + isEn] += 'Опыт ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ["2_4", "2_5"]);
          this['resultTextarea' + variant + isEn] += '.<br/><br/>';
        }

        //02
        if (answersObject.q2?.['2_4']) {
          this['resultTextarea' + variant + isEn] += answersObject.q2['2_4'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        //03
        if (answersObject.q2?.['2_5']) {
          this['resultTextarea' + variant + isEn] += answersObject.q2['2_5'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      //Маркетплейсы 1
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Понимание специфики работы ведущих электронных площадок (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += ').<br/><br/>';
      }
      //Программы
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += 'Знание (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q7, variant, language);
        this['resultTextarea' + variant + isEn] += ').<br/><br/>';
      }
      //английский
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';

      }
      // личные качества
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        // Если это целое предложение
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q8, variant, language, '', '<br/><br/>')
        this['resultTextarea' + variant + isEn] += '<br/><br/>'
      }

      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2
      //опыт работы
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += "Владею формулой выстраивания эффективного и коммерчески успешного взаимодействия с ведущими маркетплейсами.<br/><br/>";
      this['resultTextarea' + variant + isEn] += "Знаю технические и юридические особенности подключение онлайн-магазина, имею опыт его комплексного, ежедневного обслуживания.<br/><br/>";

      if (answersObject.q4?.['0'][variantFull]?.[language]) {

        this['resultTextarea' + variant + isEn] += "Личное предпочтение отдаю ";
        this['resultTextarea' + variant + isEn] += answersObject.q4['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += ".";
        this['resultTextarea' + variant + isEn] += " ";
        this['resultTextarea' + variant + isEn] += "Ценю качество сервиса и алгоритм работы этой электронной площадки. ";

        this['resultTextarea' + variant + isEn] += "Периодически заказываю товары на " + answersObject.q4['0'][variantFull]?.[language] + " в личных целях.";
        this['resultTextarea' + variant + isEn] += '<br/><br/>';

      }

      //Предпочтение 1
      if (answersObject.q5?.['0'][variantFull]?.[language]) {
        // this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q5, variant, language, '', '<br/><br/>')
        if (answersObject.q5 && answersObject.q5['0'] && answersObject.q5['0'][variantFull]) {
          this['resultTextarea' + variant + isEn] += answersObject.q5['0'][variantFull]?.[language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }
      //Предпочтение 2
      if (answersObject.q6?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q6['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';

      }
      //Ошибки
      if (answersObject.q9?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q9['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //Достижения
      if (answersObject.q10?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //Физическое
      if (answersObject.q11?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //Духовное
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      // График
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region Результат 1 на английском
      this['resultTextarea' + variant + isEn] += '<b>Professional skills:</b><br/><br/>'

      //канал продаж
      if (answersObject.q1) {
        // Если это целое предложение
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q1, variant, language, '', '<br/><br/>')
        this['resultTextarea' + variant + isEn] += '<br/><br/>'
      }

      // О2, О3
      if (answersObject.q2) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q2);

        //О1
        if (answersObject.q2['2_1'] || answersObject.q2['2_2'] || answersObject.q2['2_3']) {
          this['resultTextarea' + variant + isEn] += 'Experience in ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ["2_4", "2_5"]);
          this['resultTextarea' + variant + isEn] += '.<br/><br/>';
        }

        //02
        if (answersObject.q2?.['2_4']) {
          this['resultTextarea' + variant + isEn] += answersObject.q2['2_4'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        //03
        if (answersObject.q2?.['2_5']) {
          this['resultTextarea' + variant + isEn] += answersObject.q2['2_5'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      //Маркетплейсы 1
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Understanding of both competitive edge and pitfalls of leading marketplaces (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += ').<br/><br/>';
      }
      //Программы
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += 'Knowledge of (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q7, variant, language);
        this['resultTextarea' + variant + isEn] += ').<br/><br/>';
      }
      //английский
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';

      }
      // личные качества
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Personal skills:</b><br/><br/>';
        // Если это целое предложение
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q8, variant, language, '', '<br/><br/>')
        this['resultTextarea' + variant + isEn] += '<br/><br/>'
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском

      //опыт работы
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += "I know the formula for building an effective and commercially successful relationship with leading marketplaces.<br/><br/>";
      this['resultTextarea' + variant + isEn] += "I know the technical and legal peculiarities of setting up an online store and have experience in its comprehensive day-to-day maintenance.<br/><br/>";

      if (answersObject.q4?.['0'][variantFull]?.[language]) {

        this['resultTextarea' + variant + isEn] += "I personally prefer dealing with ";
        this['resultTextarea' + variant + isEn] += answersObject.q4['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += ".";
        this['resultTextarea' + variant + isEn] += " ";
        this['resultTextarea' + variant + isEn] += "I like the way it works and the service quality it offers. ";

        this['resultTextarea' + variant + isEn] += "And I choose " + answersObject.q4['0'][variantFull]?.[language] + " to buy things for myself, too.";
        this['resultTextarea' + variant + isEn] += '<br/><br/>';

      }

      //Предпочтение 1
      if (answersObject.q5) {
        if (answersObject.q5 && answersObject.q5['0'] && answersObject.q5['0'][variantFull]) {
          // this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q5, variant, language, '', '<br/><br/>')
          this['resultTextarea' + variant + isEn] += answersObject.q5['0'][variantFull]?.[language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }
      //Предпочтение 2
      if (answersObject.q6?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q6['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';

      }
      //Ошибки
      if (answersObject.q9?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q9['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //Достижения
      if (answersObject.q10?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //Физическое
      if (answersObject.q11?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';

      }
      //Духовное
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      // График
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull]?.[language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion

    }
  }
};


export default textResult
</script>
