<template>
  <div class="container container_main">
    <div class="text-page" v-if="isDataLoaded">
<!--      <a class="btn btn_back" href="#" @click="$router.push('/')">&lt; Назад</a>-->
      <router-link class="btn btn_back" to="/?isNeedReload=true">&lt; Назад</router-link>
      <h1>Платёж прошёл успешно!</h1>
        <p v-if="type===0 || !type">Произошла ошибка присвоения премиум аккаунта. Пожалуйста, напишите нам на <a href="mailto:info@bestinme.ru">info@bestinme.ru</a> с того email, на который регистрировали аккаунт.</p>
        <div v-else>
          <!--<p>Теперь вам доступны <router-link to="jobs">Best Me Jobs</router-link> и <router-link to="dates">Best Me Dates</router-link></p>-->
          <p v-if="isSavedResult">Посмотрите вашу <router-link to="/latest?isNeedReload=true">последнюю заполненную анкету</router-link></p>
        </div>
        <!--<p v-if="type===2">Теперь вам доступен <router-link to="dates">Best Me Dates</router-link></p>-->

    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PaymentSuccess",
  data() {
    return {
      type: 2,
      isError: false,
      isDataLoaded: false,
      isSavedResult: false
    }
  },
  mounted() {

    // Страница открыта
    setTimeout(() => {
      this.$metrika.reachGoal('buy_success');
    }, 300)

    // Тип подключенного аккаунта
    this.type = parseInt(this.$route.query.type);

    // Номер заказа в платёжной системе
    const order_id = this.$route.query.order_id;
    if (order_id && order_id.length) {

      let self = this;
      axios({
        url: process.env.VUE_APP_API + 'pay-success/' + order_id, method: 'GET', headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then((resp) => {

            this.type = resp.data.type;
            this.$store.dispatch('setPremium', {order_id: order_id});
            this.isDataLoaded = true

          })
          .catch(err => {
            console.log(err)
            console.log(err.response);
            this.isDataLoaded = true
            self.$router.push({name: 'paymentFail', params: {order_id: order_id}})
          })

      // Проверяем, сработало ли всё на бэкэнде и сохраняем тип премиум аккаунта
      // Запрос check-premium/{order_id}
      // this.$store.dispatch('setPremium', {order_id})
      //     .then((resp) => {
      //       console.log('resp2', resp);
      //       this.type = resp.data.type;
      //     })
      //     .catch(err => console.log(err))
    }

    if (localStorage.getItem('savedData')) {
      this.isSavedResult = true;
    }

  }
}
</script>

<style scoped>
.text-page {
  padding-bottom: 80px;
}
</style>