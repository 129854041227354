<template>
  <div>


    <div class="l-page-wrapper">

      <div class="l-first-screen">
        <div class="l-container">
          <div class="l-first-screen__block">
            <h1>Best Me</h1>
            <div class="l-first-screen__text">Создайте красивое описание Ваших лучших профессиональных и личных качеств</div>
          </div>
          <div class="l-first-screen__block">
            <div class="l-first-screen__small">Всего за 2 минуты</div>
            <div class="l-first-screen__button" style="height: 65px;font-size: 15px; font-weight: 400;" @click="$router.push({name:'want'})" onclick="ym(66407710,'reachGoal','front_create')">Создать описание</div>
            <!--<div class="l-first-screen__small">Сочинять о себе не нужно. <br><p>Best Me всё сочинит за Вас.</p></div>-->
            <div class="l-first-screen__small" @click="scrollMeTo('start')">Подробнее &#8595;</div>
          </div>
          <a class="l-first-screen__down anchor" @click="scrollMeTo('start')"></a>
        </div>
      </div>

      <div class="l-second-screen" id="start" ref="start">
        <div class="l-container">
          <h1>Best Me</h1>
          <h3>Расскажет о Вас<span>Пожалуй, лучше Вас</span></h3>
          <p>Это генератор описаний Ваших навыков, знаний и человеческих качеств.<br> Best Me подчеркнет Ваши самые сильные стороны – за счет удивительно точных и выразительных фраз. Это будете Вы, только еще лучше.</p>
          <!--<p>Это генератор резюме и сопроводительных писем. <br> Он подчеркнет Ваши сильные стороны – за счет удивительно точных и выразительных фраз. Это будете Вы, только еще лучше.</p>-->
        </div>
      </div>
      <div class="l-third-screen">
        <div class="l-container">
          <h3>Короткий опрос.<br>Мгновенный результат.</h3>
          <div class="l-subtitle">Сочинять о себе не нужно.<br>Просто выбирайте ответы.</div>
          <div class="l-example-screens">
            <div class="l-example-screens__item">
              <div class="l-example-screens__item-title">Работаете</div>
              <div class="l-example-screens__item-form">
                <div>
                  <input id="ex1" type="radio" name="example1">
                  <label for="ex1">1 – 3 года</label>
                </div>
                <div>
                  <input id="ex2" checked type="radio" name="example1">
                  <label for="ex2">4 – 7 лет</label>
                </div>
                <div>
                  <input id="ex3" type="radio" name="example1">
                  <label for="ex3">8 – 10 лет</label>
                </div>
                <div>
                  <input id="ex4" type="radio" name="example1">
                  <label for="ex4">Более 10 лет</label>
                </div>
              </div>
            </div>
            <div class="l-example-screens__item">
              <div class="l-example-screens__item-title">Это о Вас</div>
              <div class="l-example-screens__item-form">
                <div>
                  <input id="ex5" type="checkbox" name="example2">
                  <label for="ex5">Любознательность</label>
                </div>
                <div>
                  <input id="ex6" checked type="checkbox" name="example2">
                  <label for="ex6">Чувство юмора</label>
                </div>
                <div>
                  <input id="ex7" checked type="checkbox" name="example2">
                  <label for="ex7">Терпеливость</label>
                </div>
                <div>
                  <input id="ex8" checked type="checkbox" name="example2">
                  <label for="ex8">Тактичность</label>
                </div>
              </div>
            </div>
            <div class="l-example-screens__item l-example-screens__item_manager">
              <div class="l-example-screens__item-subtitle">Пример</div>
              <div class="l-example-screens__item-title">Менеджер по продажам</div>
              <div class="js-tabs">
                <div class="l-example-screens__item-toggles js-tabs__toggles"><a class="js-tabs__toggle_prev active" href="#">Тезисно</a><a class="js-tabs__toggle_next" href="#">Подробно</a></div>
                <div class="l-example-screens__item-texts">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <div id="managerText1">
                          <h5>Профессиональные качества:</h5>
                          <p>Опыт успешных холодных продаж в b2b.</p>
                          <p>Опыт успешных перекрестных и пакетных продаж.</p>
                          <p>Опыт подготовки индивидуальных КП, проведения личных встреч на уровне ЛПР.</p>
                          <p>Знание особенностей 44-ФЗ и 223-ФЗ, опыт подготовки тендерной документации.</p>
                          <p>Знание 1С, CRM, базовых программ Microsoft и инструментов Googlе.</p>
                          <p>Владение английским языком на уровне B1 (устная и письменная коммуникация практически не вызывает у меня затруднений).</p>
                          <br>
                          <h5>Личные качества:</h5>
                          <p>Терпеливость. Умение сделать нужную паузу, вовремя внести новое предложение.</p>
                          <p>Чувство юмора. Способность расположить собеседника.</p>
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div id="managerText2">
                          <p>Занимаюсь продажами в сфере b2b более пяти лет.</p>
                          <p>Нацелен на результат. Понимаю специфику работы в условиях длительного цикла продаж.</p>
                          <p>В общении с клиентом проявляю выдержку и дипломатичность.</p>
                          <p>Предпочитаю использовать преимущественно качественные, а не количественные вопросы.</p>
                          <p>Умею выслушать заказчика, определить его ключевые потребности и таким образом выстроить выгодные для обеих сторон условия сделки.</p>
                          <p>Изучаю продукт, который продаю. Понимаю его сильные стороны, обновляю и составляю презентации без посторонней помощи.</p>
                          <p>Всегда анализирую собственные ошибки. Размышляю над тем, как повысить личную эффективность с учетом предыдущего  (не всегда успешного) опыта продаж.</p>
                          <p>Люблю выполнять сложные задачи, регулярно преодолевать себя.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="l-fourth-screen">
        <div class="l-container">
          <div>Создавайте<br>лучшую<br>версию<br>себя</div>
          <a class="l-fourth-screen__link" href="#" @click="$router.push('/examples')">Посмотреть примеры   &gt;</a>
        </div>
      </div>
      <div class="l-peoples">
        <div class="l-container">
          <h3>Выделяйтесь</h3>
          <div class="l-subtitle">Первое впечатление – важное впечатление.</div>
          <div class="people-cards">
            <div class="people-card">
              <div class="people-card__img-wrapper"><img src="/img/landing/card1.jpg" alt="Bestme"><span>Алексей, 24</span></div>
              <div class="people-card__text">
                <h5>Графический дизайнер</h5>
                <p>Восхищаюсь гением Уильяма Плейфэра. В&nbsp;шутку жалею, что родоначальником современной инфографики был не я.</p>
              </div>
            </div>
            <div class="people-card">
              <div class="people-card__img-wrapper"><img src="/img/landing/card2.jpg" alt="Bestme"><span>Дарья, 22</span></div>
              <div class="people-card__text">
                <h5>Секретарь</h5>
                <p>Находчивость – мое второе я. Смогу по поручению руководителя найти «тот самый» товар, которого нет в природе.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="l-links-block">
        <div class="l-container">
          <div class="l-site-links">
            <div class="l-site-links__title">Идеально для</div>
            <ul>
              <li><a href="https://hh.ru/" target="_blank" rel="nofollow">HeadHunter</a></li>
              <li><a href="https://www.superjob.ru/" target="_blank" rel="nofollow">SuperJob</a></li>
              <li><a href="https://rabota.ru/" target="_blank" rel="nofollow">Rabota.ru</a></li>
              <li><a href="https://youdo.com/" target="_blank" rel="nofollow">YouDo</a></li>
              <li><a href="https://profi.ru/" target="_blank" rel="nofollow">PROFI.RU</a></li>
            </ul>
          </div>
          <div class="l-counters">
            <div class="l-counters__item"><span>50</span>профессий</div>
            <div class="l-counters__item"><span>5000</span>уникальных фраз</div>
          </div>
        </div>
      </div>
      <div class="l-seventh-screen">
        <div class="l-container">
          <h3>Перевод без перевода</h3>
          <div class="l-subtitle">Переходите на английский.<br>И в ту же секунду на русский.</div>
          <div class="l-translate js-tabs" data-initial="1">
            <div class="l-translate__toggles js-tabs__toggles"><a class="js-tabs__toggle_prev" href="#">Русский</a><a class="js-tabs__toggle_next active" href="#">Английский</a></div>
            <div class="l-translate__text">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div id="translate1">
                      <h5>Дизайнер интерьеров</h5>
                      <p>Предпочитаю играть с симметрией и объемом. Не люблю, когда в интерьере все слишком правильно. Когда нарушаешь шаблоны, тогда и создаешь что-то особенное.</p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="active" id="translate2">
                      <h5>Interior Designer</h5>
                      <p>I prefer playing with symmetry and scale rather than just falling into simple, matchy-matchy monotony. And that’s what makes interior design really stand out.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!--<div class="l-peoples l-peoples_no-pt">-->
      <!--  <div class="l-container">-->
      <!--    <h3>Влюбляйтесь</h3>-->
      <!--    <div class="l-subtitle">Хотя постойте. В любви признаются Вам.</div>-->
      <!--    <div class="people-cards">-->
      <!--      <div class="people-card">-->
      <!--        <div class="people-card__img-wrapper"><img src="/img/landing/card3.jpg" alt="Bestme"><span>Мария, 24</span></div>-->
      <!--        <div class="people-card__text">-->
      <!--          <p>Без ума от музыки. Надеюсь, у нас с тобой это взаимно.<br>Влюблена в архитектуру. Будет классно, если ты тоже (интересно, тебе нравится французский балкон?).<br>Мне нравится ренессанс и классическая живопись. Видимо, я поклонница реалистичного и фотографически точного мира :)</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="people-card">-->
      <!--        <div class="people-card__img-wrapper"><img src="/img/landing/card4.jpg" alt="Bestme"><span>Максим, 26</span></div>-->
      <!--        <div class="people-card__text">-->
      <!--          <p>«Он невероятно умен» –The Telegraph.<br>«Его манерам можно позавидовать» – Меган Маркл.<br>«Кажется, этот парень объездил весь мир» – Орел и Решка.<br>«Хватит сочинять чужие цитаты» – мой первый учитель.<br>«Мой рост – 178 см» – я.</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </div>-->
      <!--  </div>-->
      <!--</div>-->


      <!--<div class="l-links-block">-->
      <!--  <div class="l-container">-->
      <!--    <div class="l-site-links">-->
      <!--      <div class="l-site-links__title">Идеально для</div>-->
      <!--      <ul>-->
      <!--        <li><a href="https://tinder.com/" target="_blank" rel="nofollow">Tinder</a></li>-->
      <!--        <li><a href="https://badoo.com/" target="_blank" rel="nofollow">Badoo</a></li>-->
      <!--        <li><a href="https://bumble.com/" target="_blank" rel="nofollow">Bumble</a></li>-->
      <!--        <li><a href="https://mamba.ru/" target="_blank" rel="nofollow">Mamba</a></li>-->
      <!--        <li><a href="https://teamo.ru/" target="_blank" rel="nofollow">Teamo</a></li>-->
      <!--      </ul>-->
      <!--    </div>-->
      <!--  </div>-->
      <!--</div>-->

      <Payment/>
      <!--<div class="l-chat-block">
        <div class="l-container">
          <h3>Общайтесь</h3>
          <div class="l-subtitle">Экономьте рабочее время.<br>Нужные фразы всегда под рукой.</div>
          <div class="l-chat-block__inner">
            <div class="l-chat-block__item l-chat-block__item_right">Андрей, добрый день!</div>
            <div class="l-chat-block__item l-chat-block__item_right">Направил в почту сравнительный анализ нашего оборудования и моделей конкурирующих производителей.</div>
            <div class="l-chat-block__item l-chat-block__item_right">Выделил цветом параметры, которые для решения вашей задачи являются критически важными.</div>
            <div class="l-chat-block__item l-chat-block__item_left">Спасибо! Уже изучаю.</div>
          </div>
          <div class="l-plans__item">
            <div class="l-plans__item-title"><b>Best Me</b> Chat</div>
            <div class="l-plans__item-subtitle">Доступно с 15 мая 2021 года</div>
            <div class="l-plans__item-text l-plans__item-text_small-margin">
              <ul>
                <li>50 профессий</li>
                <li>10 000 уникальных фраз</li>
                <li>На русском и английском языках</li>
              </ul>
            </div><a class="l-plans__item-button l-plans__item-button_large" href="#" @click="$router.push('/chat')">Посмотреть примеры</a>
          </div>
        </div>
      </div>-->
      <div class="l-faq-block">
        <div class="l-container">
          <h3>Подробнее о главном</h3>
          <div class="faq__list faq-list" :class="{'faq-list_show':openedFaqArr[index] === true}" v-for="(item, index) in fag" v-bind:key="index">
            <div class="faq-list__title" @click="openFaq(index)">{{ item.title }}</div>
            <button class="faq-list__show" type="button" @click="openFaq(index)"></button>
            <div class="faq-list__items">
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterFront />
  </div>
</template>

<script>
import FooterFront from "@/components/FooterFront";
import Payment from "@/components/Payment";

import Swiper from 'swiper/bundle';
// import Swiper styles
import 'swiper/swiper-bundle.css';
//import { Swiper } from "vue-awesome-swiper";
// import 'swiper/css/swiper.css';
// import 'swiper/css';

function frontJs() {
  const jsTabs = [...document.querySelectorAll('.js-tabs')];
  jsTabs.map((el) => {
    // const swiperInstance = new Swiper(el.querySelector('.swiper-container'), {allowTouchMove: false,});
    const initialSwiperSlide = el.getAttribute('data-initial') ? Number(el.getAttribute('data-initial')) : 0;
    const swiperInstance = new Swiper(el.querySelector('.swiper-container'), {allowTouchMove: false, autoHeight: true, initialSlide: initialSwiperSlide,});
    el.querySelector('.js-tabs__toggle_prev').addEventListener('click', (e) => {
      e.preventDefault();
      if (e.target.classList.contains('active')) {
        return false;
      }
      el.querySelector('.js-tabs__toggles .active').classList.remove('active');
      e.target.classList.add('active');
      swiperInstance.slidePrev();
    });
    el.querySelector('.js-tabs__toggle_next').addEventListener('click', (e) => {
      e.preventDefault();
      if (e.target.classList.contains('active')) {
        return false;
      }
      el.querySelector('.js-tabs__toggles .active').classList.remove('active');
      e.target.classList.add('active');
      swiperInstance.slideNext();
    });
  });
}


export default {
  name: "Front",
  data() {
    return {
      openedFaqArr: [false,false,false,false,false,false,false,false],
      fag: [
        {
          'title': 'Мне достаточно пройти опрос?',
          'text': 'Верно. Опрос включает около 20 вопросов и занимает не более 3 минут. Сочинять ничего не нужно. Best Me всё сочинит за Вас.'
        },
        {
          'title': 'Best Me напишет лучше меня?',
          'text': 'Вы удивитесь, но, скорее всего, да. Особенно если Вы испытываете сложности с изложением Ваших сильных сторон. Благодаря Best Me они станут очевидными. Не исключено, что Вы скажите: «Да, точно, это про меня».'
        },
        {
          'title': 'Как использовать Best Me?',
          'text': 'Проходите опрос и получаете текст о Ваших профессиональных и личных качествах. В двух вариантах – тезисном и подробном. Тезисный вариант можно вставить в Ваше резюме, подробный – использовать в качестве сопроводительного письма.'
        },
        // {
        //   'title': 'Как использовать Best Me Dates?',
        //   'text': 'Проходите опрос и получаете текст о Ваших увлечениях и жизненных ценностях. Текст можно вставить в раздел «О себе» наиболее популярных приложений и сайтов знакомств.'
        // },
        {
          'title': 'Кто составляет фразы?',
          'text': 'Редакторы Best Me. Они пишут не только фразы, но и вопросы, ответы на которые и позволяют определить Ваши достоинства. При составлении опросов редакторы опираются на мнения рекрутеров и CEO крупных, средних и небольших по размеру компаний, а также на опыт заказчиков фриланс-услуг – в России и за рубежом.'
        },
        {
          'title': 'Почему Вы с большой буквы?',
          'text': 'Из уважения к Вам.'
        }
      ]
    }
  },
  computed: {

  },
  mounted() {
    // Все скрипты для главной
    // todo: Переделать нормально
    frontJs()

    // Если есть хэш start, то скроллим
    if (location.hash && location.hash === "#start") {
      this.scrollMeTo('start');
    }

  },
  components: {
    FooterFront,
    Payment
  },
  methods: {

    // Открыть Вопросы и ответы
    openFaq(list) {
      if (this.openedFaqArr[list] === true) {
        this.$set(this.openedFaqArr, list, false)
      } else {
        this.$set(this.openedFaqArr, list, true)
      }
    },

    // Плавный скролл
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = element.offsetTop;
      if (top) {
        window.scrollTo({
          top: top,
          behavior: "smooth"
        });
      }
    }

  }
}
</script>

<style scoped lang="scss">
.l-first-screen__down {
  opacity: 0;
  visibility: hidden;
}
.l-plans {
  padding-top: 0;
}
.l-example-screens__item-subtitle {
  font-size: 13px;
  line-height: 1;
  color: orange;
  padding-left: 8px;
  font-weight: 400;
}
</style>