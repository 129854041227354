<template>
  <div class="auth">
    <div class="container">

      <template v-if="isDebug">{{$route.params}}</template>

      <div class="auth__form">
        <div class="auth__toggles">
          <button class="auth__toggle auth__toggle_active" type="button">Войти</button><span>или</span>
          <button class="auth__toggle" type="button" @click="$router.push({name:'registration', params: {type: $route.params.type, amount: $route.params.amount}})">Зарегистрироваться</button>
        </div>
        <div class="auth__text">Чтобы пользоваться платными сервисами</div>
        <div class="auth__body">
          <div class="auth__field">
            <input type="text" placeholder="Ваш email" v-model="fieldEmail" @input="checkFieldEmail()">
            <div class="auth__field-text" v-if="isEmailError">Проверьте email</div>
          </div>
          <div class="auth__field">
            <input :type="fieldPasswordType" placeholder="Ваш пароль" v-model="fieldPassword" @input="checkFieldPassword()">
            <div class="auth__password-switcher" @click="changePasswordType()"></div>
            <div class="auth__field-text auth__field-text_error" v-if="isPasswordError">Неправильный пароль</div>
            <div class="auth__field-text auth__field-text_error" v-if="isAuthError">Неправильный пароль или email</div>
            <div class="auth__field-text auth__field-text_error" v-if="isGlobalError">Простите, что-то пошло не так. Пожалуйста, напишите нам на info@bestme.nl</div>
          </div>
          <div class="auth__button">
            <div class="btn btn_bg-dark" @click="login()">Войти</div>
          </div>
          <div class="auth__link"><a href="#" class="auth__forgot-pw" @click.prevent="$router.push('remind')">Забыли пароль?</a></div>
        </div>
      </div>
      <div class="auth__rules">Продолжая, Вы принимаете условия <router-link to="terms" target="_blank">Соглашения</router-link></div>
    </div>
  </div>
</template>

<script>
const emailCheckRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;

export default {
  name: "Auth",
  data() {
    return {
      isRegister: false,

      fieldEmail:'',
      fieldPassword:'',
      fieldPasswordType:'password',
      passwordLengthNeed: 8,
      isEmailError: false,
      isPasswordError: false,
      isEmailValid: false,
      isPasswordValid: false,
      isAuthError: false,
      isGlobalError: false,
    }
  },
  computed: {
    isDebug() {
      return this.$route.query.isDebug || false
    },
    // Классы для поля email
    isFieldsValid: function () {
      // console.log('this.isEmailValid', this.isEmailValid);
      // console.log('this.isPasswordValid', this.isPasswordValid);
      return this.isEmailValid && this.isPasswordValid
    },
  },
  methods: {
    checkFieldPassword() {
      if (this.fieldPassword.length < this.passwordLengthNeed) {
        this.isPasswordError = true;
        this.isPasswordValid = false;
        this.isAuthError = false;
      } else {
        this.isPasswordError = false;
        this.isPasswordValid = true;
      }
    },
    checkFieldEmail() {
      if (emailCheckRegex.test(this.fieldEmail)) {
        this.isEmailError = false;
        this.isEmailValid = true;
      } else {
        this.isEmailError = true;
        this.isEmailValid = false;
      }
    },
    changePasswordType() {
      if(this.fieldPasswordType === 'password') {
        this.fieldPasswordType = 'text'
      } else {
        this.fieldPasswordType = 'password'
      }
    },
    login() {
      if (this.isFieldsValid) {
        let email = this.fieldEmail
        let password = this.fieldPassword
        this.$store.dispatch('login', { email, password })
            .then(() => {
              if (this.$route.params.type) {
                this.$router.push({name:'pay',
                  params: {type: this.$route.params.type, amount: this.$route.params.amount}});
              } else {
                // this.$router.push('/')
                this.$router.push({name:'pay'})
              }
            })
            .catch(err => {

              if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
                console.log(err)
                console.log(err.response);
              }

              if (err.response.data.errors.email) {
                this.isEmailError = true;
              } else {
                this.isEmailError = false;
              }

              if (err.response.data.errors.password) {
                this.isPasswordError = true;
              } else {
                this.isPasswordError = false;
              }

              if (err.response.status === 401) {
                this.isAuthError = true;
              }
              if (err.response.status === 500) {
                this.isGlobalError = true;
              }
            })
      } else {
        this.checkFieldPassword();
        this.checkFieldEmail();
      }
    }
  },
  mounted() {
    if (process.env.VUE_APP_TYPE === 'local') {
      this.fieldEmail = process.env.VUE_APP_USER_EMAIL
      this.fieldPassword = process.env.VUE_APP_USER_PASSWORD
      this.isEmailValid = this
      this.isPasswordValid = this
    }
  }

}
</script>

<style scoped>

</style>