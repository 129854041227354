<template>
  <div class="container container_main">
    <div class="text-page">
      <a class="btn btn_back" href="#" @click="$router.push('/')">&lt; Назад</a>
      <h1>Политика конфиденциальности</h1>
      <p>Политика конфиденциальности определяет правила обработки данных пользователей вебсайта <a href="https://bestme.nl" target="_blank">https://bestme.nl</a> (далее – «Вебсайт»).</p>
      <p>В настоящей Политике конфиденциальности ссылки на «мы», «нас» или «наш» (или слова с аналогичным смыслом) означают ООО «ПАРС», именуемое в дальнейшем «Компания», местонахождение: 105023, Москва, улица Парковая 14, дом 8, офис Э/ПОМ//К/ОФ5/I/26А2Е, которая обрабатывает Ваши личные данные и взаимодействует с Вами.</p>
      <p>Ссылки на «Вы», «Ваш» означают пользователя (далее – Пользователя) Вебсайта, личный электронный адрес которого мы собираем, используем и обрабатываем.</p>
      <p>Настоящая Политика конфиденциальности применяется к Вам в течение всего времени использования этого Вебсайта.</p>
      <h4>Общие положения</h4>
      <p>Настоящая Политика конфиденциальности и обработки персональных данных составлена в соответствии с требованиями Федерального закона РФ от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки Ваших персональных данных и меры по обеспечению безопасности Ваших персональных данных ООО «ПАРС».</p>
      <p>Компания ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
      <p>Политика Компании в отношении обработки персональных данных применяется ко всей информации, которую Компания может получить о посетителях вебсайта <a href="https://bestme.nl" target="_blank">https://bestme.nl</a></p>
      <p>Данная Политика действует каждый раз, когда Вы пользуетесь Вебсайтом, и она действует совместно с нашим Соглашением об условиях использования Вебсайта. Используя Вебсайт, вы соглашаетесь с Политикой конфиденциальности и даете разрешение на сбор, хранение, использование и обработку Ваших персональных данных в соответствии с данной Политикой.</p>
      <p>Если Вы не согласны с нашей Политикой конфиденциальности, пожалуйста, воздержитесь от использования Вебсайта.</p>
      <!--<p>Вы можете зарегистрироваться на Вебсайте и пользоваться его отдельными функциями только в том случае, если Вам уже исполнилось 18 лет. Это означает, что Компания намеренно не собирает никакой информации о лицах младше 18 лет. Мы не привлекаем и не поощряем использование нашего сервиса лицами, не достигшими 18 лет. Если Вам меньше 18 лет, пожалуйста, не предоставляйте нам информацию о себе. Если нам станет известно, что кто-то в возрасте до 18 лет зарегистрировался на Вебсайте и предоставил свои персональные данные, мы примем меры, чтобы прекратить регистрацию этого пользователя и удалить его/ее аккаунт.</p>-->
      <h4>Цели обработки персональных данных</h4>
      <p>Цель обработки персональных данных Пользователя — информирование Пользователя посредством отправки электронных писем; предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на Вебсайте.</p>
      <p>Компания имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Компании письмо на адрес электронной почты info@bestme.nl с пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях». </p>
      <p>Обезличенные данные Пользователей, собираемые с помощью сервисов Интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.</p>
      <h4>Правовые основания обработки персональных данных</h4>
      <p>Компания обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на вебсайте <a href="https://bestme.nl" target="_blank">https://bestme.nl</a>. Заполняя соответствующие формы и/или отправляя свои персональные данные Компании, Пользователь выражает свое согласие с данной Политикой. </p>
      <p>Компания обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя. </p>
      <h4>Порядок сбора, хранения, передачи и других видов обработки персональных данных</h4>
      <p>Безопасность персональных данных, которые обрабатываются Компанией, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных. </p>
      <p>Компания обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц. </p>
      <p>Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства. </p>
      <p>Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Компании уведомление посредством электронной почты на электронный адрес Компании info@bestme.nl с пометкой «Отзыв согласия на обработку персональных данных». </p>
      <h4>Заключительные положения</h4>
      <p>Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Компании с помощью электронной почты <a href="mailto:info@bestme.nl">info@bestme.nl</a>.</p>
      <p>В данном документе будут отражены любые изменения политики обработки персональных данных Компанией. Политика действует бессрочно до замены ее новой версией. </p>
      <p>Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <a href="https://bestme.nl/privacy">https://bestme.nl/privacy</a>.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Privacy"
}
</script>

<style scoped>
.text-page {
  padding-bottom: 80px;
}
</style>