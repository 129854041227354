<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1
      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      // Холодные продажи
      if (answersObject.q1?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q1['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Апселл
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += 'Опыт увеличения объема продаж за счет ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // О1
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Опыт ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // О2
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += 'Опыт ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q4, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // О3
      if (answersObject.q5?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q5['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Госзакупки
      if (answersObject.q6?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q6['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Тендеры
      if (answersObject.q7?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q7['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += 'Знание ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q8, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      //Английский
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // личные качества
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q9, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2
      // Опыт работы
      if (answersObject.q17?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q17['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'Нацелен на результат. Понимаю специфику работы в условиях длительного цикла продаж.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "В общении с клиентом проявляю выдержку и дипломатичность."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "Предпочитаю использовать преимущественно качественные, а не количественные вопросы."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "Умею выслушать заказчика, определить его ключевые потребности и таким образом выстроить выгодные для обеих сторон условия сделки."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";

      // Ошибки
      if (answersObject.q11) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      // Достижения
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      // Шахматы
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Принципы
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Физическое
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Духовное
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Мотивация
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // График
      if (answersObject.q19) {
        this['resultTextarea' + variant + isEn] += answersObject.q19['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском
      // Холодные продажи
      if (answersObject.q1?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += '<b>Professional qualities:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += answersObject.q1['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Апселл
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += 'Experience in increasing sales through ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // О1
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Experience in ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // О2
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += 'Experience in ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q4, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // О3
      if (answersObject.q5?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q5['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Госзакупки
      if (answersObject.q6?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q6['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Тендеры
      if (answersObject.q7?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q7['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += 'Knowledge of ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q8, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      //Английский
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // личные качества
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += '<b>Personal qualities:</b><br/>';
        // Где 1 - это номер в resultTextarea
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q9, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском
      // Опыт работы
      if (answersObject.q17?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q17['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }


      this['resultTextarea' + variant + isEn] += 'I strive for results and I understand the specifics of a long-term sales cycle.'
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "I show restraint and diplomacy when interacting with the client."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "I prefer to pose high-quality questions rather than high-quantity ones."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "I am able to listen to the customer, identify their key needs, and thereby build a deal that benefits both parties."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";

      // Ошибки
      if (answersObject.q11) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      // Достижения
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      // Шахматы
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Принципы
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Физическое
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Духовное
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Мотивация
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // График
      if (answersObject.q19) {
        this['resultTextarea' + variant + isEn] += answersObject.q19['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>