<template>
  <div class="question">
    <div class="question__wrapper" v-bind:class="{question__wrapper_fullheight: item.type==='radio_with_select'}">


      <div class="question__header">
        <div class="question__title" v-if="item.question">{{ item.question }}</div>
        <div class="question__subtitle" v-if="item.text" v-html="item.text"></div>
      </div>

      <!--выбранный: {{questionChecked}}-->
      <!--<br>-->
      <!--<br>-->
      <!--<br>-->
      <!--результаты: {{questionCheckedResults}}-->
      <!--<br>-->
      <!--<br>-->
      <!--<br>-->

      <div class="question__variants" v-bind:class="questionClass(item.class)">
        <template v-if="item.type === 'radio_with_select'">
          {{ /* Радио с селектом */ }}
          <div class="question__item question-item" v-bind:key="variant.id" v-for="variant of item.variants">

            <input :id="variant.id" :name="'q_' + item.id" v-bind:value="variant.id" type="radio" v-model="fieldRadioChecked">

            <label :for="variant.id" @click="clickRadioWithSelects(variant)">

              {{ variant.name }}

              <div v-if="variant.description">{{ variant.description }}</div>

              <v-select label="name"
                        :value="variant.name"
                        v-if="variant.inner_variants"
                        :options="variant.inner_variants"
                        v-model="fieldSelectSelected"
                        @input="changeSelects(variant)"
                        placeholder="Choose a Styling Option"
              >

              </v-select>

              <!--<select v-if="variant.inner_variants" v-model="fieldSelectSelected" @change="changeSelects(variant)">-->
              <!--  <option :value="option.id" v-for="(option, index) of variant.inner_variants" v-bind:key="option.id" v-bind:selected="index === 0">{{option.name}}</option>-->
              <!--</select>-->

            </label>

          </div>
        </template>

        <template v-else-if="item.type === 'text'">
          {{ /* Тестовое поле */ }}
          <div class="question__item question-item" v-bind:key="variant.id" v-for="(variant, index) of item.variants">

            <input type="text" :id="variant.id" :name="'n' + variant.id" v-model.trim="textVariants[index].text_result" :placeholder="variant.name" @input="checkQuestionTextFilled" minlength="1">

          </div>
        </template>

        <template v-else-if="item.type === 'text_single'">
          {{ /* Тестовое поле с дизайном jobs */ }}
          <div class="question__item question-item" v-bind:key="variant.id" v-for="(variant, index) of item.variants">
            <label :for="variant.id">{{ variant.name }}</label>
            <input type="text" :id="variant.id" :name="'n' + variant.id" v-model.trim="textVariants[index].text_result" :placeholder="variant.placeholder" @input="checkQuestionTextFilled" minlength="1">

          </div>
        </template>

        <template v-else-if="item.type === 'text_multiple'">
          {{ /* Тестовое поле множественное */ }}

          <div class="question__item question-item" v-bind:key="variant.id" v-for="(variant, index) of item.variants">
            <input v-if="textMultipleShown[index]" type="text" :id="variant.id" :name="'n' + variant.id" v-model.trim="textVariants[index].text_result" :placeholder="variant.placeholder" @input="checkQuestionTextFilledSingle" minlength="1">
          </div>

          <div class="question__more" @click="showTextField(textNextIndexToShow)" v-if="isShowAddMoreButton"></div>
        </template>

        <template v-else-if="item.type === 'versus'">
          {{ /* Радио с versus */ }}
          <div class="question__item question-item" v-bind:key="variant.id" v-for="(variant, index) of item.variants">
            <template v-for="(inner, inner_index) of variant.inner_variants">
              <div class="question-item__versus-item"
                   :class="inner_index === 0 ? 'question-item__versus-item_left' : 'question-item__versus-item_right'"
                   v-bind:key="inner.id"
              >
                <input :id="inner.id"
                       :name="'q_' + variant.id"
                       v-bind:value="inner.id"
                       type="radio"
                       v-model="versusSelected[index]"
                >

                <label :for="inner.id" @click="clickRadioVersus(index, inner)">{{ inner.name }}</label>
              </div>

              <div class="question-item__versus-item question-item__versus-item_center" v-if="inner_index === 0" v-bind:key="'c_' + inner.id">vs</div>

            </template>


          </div>
        </template>

        <template v-else-if="item.type === 'checkbox'">
          {{ /* checkbox */ }}
          <div class="question__item question-item" v-bind:key="variant.id" v-for="variant of item.variants">

            <input :id="variant.id" :name="'q_' + item.id" type="checkbox" v-bind:value="variant.id" v-model="checkboxSelected">

            <label :for="variant.id" @click="clickCheckbox(variant.id, variant)">
              {{ variant.name }}
              <div v-if="variant.description">{{ variant.description }}</div>
            </label>

          </div>
        </template>

        <template v-else>
          {{ /* Обычные радио */ }}
          <div class="question__item question-item" v-bind:key="variant.id" v-for="variant of item.variants">

            <input :id="variant.id" :name="'q_' + item.id" type="radio" v-bind:value="variant.id" v-model="questionChecked">

            <label :for="variant.id" @click="changeStep(item.step, item.last, variant.id, variant.results)">

              {{ variant.name }}

              <div v-if="variant.description">{{ variant.description }}</div>

            </label>

          </div>
        </template>
      </div>

      <div class="question__footer" v-if="item.skip || item.type === 'radio_with_select'">
        <div class="question__skip btn btn_grey" v-if="questionChecked && item.type === 'radio_with_select'" @click="changeStep(item.step, item.last, questionChecked, questionCheckedResults)" :disabled="!!questionChecked">Продолжить</div>
        <div class="question__skip btn btn_skip" v-if="item.skip" @click="skipStep(item.step, item.last)">Пропустить</div>
      </div>

      <div class="question__footer" v-if="item.type === 'text' || item.type === 'text_single' || item.type === 'text_multiple'">
        <div class="question__skip btn btn_grey btn_disabled js-disable" v-if="!isQuestionTextFilled">Продолжить</div>
        <div class="question__skip btn btn_grey" @click="changeStep(item.step, item.last, item.id, textVariants)" v-if="isQuestionTextFilled">Продолжить</div>
      </div>

      <div class="question__delimiter" v-if="item.type === 'versus'"></div>
      <div class="question__delimiter" v-if="item.type === 'checkbox'"></div>

      <div class="question__footer" v-if="item.type === 'versus'">
        <div class="question__skip btn btn_grey btn_disabled js-disable" v-if="!isVersusFilled">Продолжить</div>
        <div class="question__skip btn btn_grey" @click="changeStep(item.step, item.last, item.id, versusResults)" v-if="isVersusFilled">Продолжить</div>
      </div>

      <div class="question__footer" v-if="item.type === 'checkbox'">
        <div class="question__skip btn btn_grey btn_disabled js-disable" v-if="!isCheckboxFilled">Продолжить</div>
        <div class="question__skip btn btn_grey" @click="changeStep(item.step, item.last, item.id, checkboxResults)" v-if="isCheckboxFilled">Продолжить</div>
      </div>

      <div class="question__progress question-progress">
        <div class="question-progress__bar" v-bind:style="{ width: currentProgress + '%'}"></div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Question",
  props: {
    data: Object,
    currentProgress: Number
  },
  data() {
    return {
      item: this.data,
      // выбранный radio
      fieldRadioChecked: null,
      // выбранный select
      // fieldSelectSelected: this.data.variants[this.data.variants.length - 1].inner_variants ? this.data.variants[this.data.variants.length - 1].inner_variants[0].id : null,
      fieldSelectSelected: this.data.variants[this.data.variants.length - 1].inner_variants ? this.data.variants[this.data.variants.length - 1].inner_variants[0] : null,
      // Отправка в результаты
      questionChecked: null,
      questionCheckedResults: null,
      // Все текстовые вопросы заполнены
      isQuestionTextFilled: false,
      //region Текстовые вопросы
      textVariants: [],
      textNextIndexToShow: 2,
      isShowAddMoreButton: true,
      textMultipleShown: [true,true,false,false,false],
      //endregion
      // Versus
      versusResults: [],
      versusSelected: [],
      isVersusFilled: false,
      // Checkbox
      checkboxResults: {},
      checkboxSelected: [],
      isCheckboxFilled: false,

    };
  },
  methods: {
    // Расчёт класса
    // todo: Дубль в app
    questionClass: function (itemClass) {
      var classesArray = [];
      if (itemClass === 'radio') {
        classesArray.push('question__variants_radio');
      }
      else if (itemClass === 'radio_simple') {
        classesArray.push('question__variants_radio');
        classesArray.push('question__variants_radio-simple');
      }
      else if (itemClass === 'radio_simple columns') {
        classesArray.push('question__variants_radio');
        classesArray.push('question__variants_radio-simple');
        classesArray.push('question__variants_radio-columns');
      }
      else if (itemClass === 'radio_columns') {
        classesArray.push('question__variants_radio');
        classesArray.push('question__variants_radio-simple');
        classesArray.push('question__variants_radio-columns');
      }
      else if (itemClass === 'checkbox') {
        classesArray.push('question__variants_checkbox');
      }
      else if (itemClass === 'checkbox columns') {
        classesArray.push('question__variants_checkbox');
        classesArray.push('question__variants_checkbox_columns');
        classesArray.push('question__variants_radio-columns');
      }
      else if (itemClass === 'text') {
        classesArray.push('question__variants_text');
      }
      else if (itemClass === 'text text_single') {
        classesArray.push('question__variants_text question__variants_text_single');
      }
      else if (itemClass === 'text text_multiple') {
        classesArray.push('question__variants_text question__variants_text_multiple');
      }
      else if (itemClass === 'radio_versus') {
        classesArray.push('question__variants_versus');
      }
      return classesArray;
    },

    // Смена шага (отправляю в app.vue)
    changeStep(currentStep, isLast, answerId, answerResults) {

      this.$emit('setAnswer', {
        answerId: 'q' + answerId,
        answerResults: answerResults,
      })

      if (!isLast) {
        this.$emit('changeStep', {
          currentStep: currentStep
        })
      }
      else {
        this.$emit('lastStep', {
          currentStep: currentStep
        })
      }
    },

    // Пропуск шага
    skipStep(currentStep, isLast) {
      // console.log('skip', currentStep);
      if (!isLast) {
        this.$emit('skipStep', {
          currentStep: currentStep
        })
      }
      else {
        this.$emit('lastStep', {
          currentStep: currentStep
        })
      }
    },

    // Выбор селекта
    changeSelects(variant) {
      // console.log('variant', variant);

      // this.questionChecked = this.fieldSelectSelected;
      this.questionChecked = this.fieldSelectSelected.id;

      // console.log('this.questionChecked', this.questionChecked);

      this.questionCheckedResults = this.findInnerResults(variant.inner_variants, this.questionChecked);
    },

    // Клик по label
    clickRadioWithSelects(variant) {

      // console.log('clickRadioWithSelects variant', variant);

      if (variant.inner_variants) {
        // Выбор инпута, когда выбран селект
        this.fieldRadioChecked = variant.inner_variants[0].parent_id;

        // Выбор первого пункта в селекте
        // Если не выбран селект
        if (this.fieldSelectSelected == null) {
          this.questionChecked = this.data.variants[this.data.variants.length - 1].inner_variants[0].id;
          this.fieldSelectSelected = this.data.variants[this.data.variants.length - 1].inner_variants[0].id;
          this.questionCheckedResults = this.data.variants[this.data.variants.length - 1].inner_variants[0].results;
          // console.log('1');
        }
        else {

          // console.log('2');

          // Если уже выбран селект и нажат инпут
          this.questionChecked = this.fieldSelectSelected;
          // this.questionChecked = this.fieldSelectSelected.id;

          if (typeof this.questionChecked === "object") {
            this.questionChecked = this.fieldSelectSelected.id;
          }

          // console.log('2!!! this.questionChecked', this.questionChecked);

          // console.log('variant.inner_variants', variant.inner_variants);

          // this.questionCheckedResults = this.findInnerResults(this.questionChecked);

          // Сохраняю результаты выбранного пункта
          this.questionCheckedResults = this.findInnerResults(variant.inner_variants, this.questionChecked);

          // console.log('questionCheckedResults', this.questionCheckedResults);

          // this.findInnerResults(variant.inner_variants, this.questionChecked);
        }
      }
      // Обычный radio
      else {
        this.fieldRadioChecked = variant.id
        this.questionChecked = variant.id
        this.questionCheckedResults = variant.results
        // console.log('3');
      }


    },

    // Клик по label versus
    clickRadioVersus(index, variant) {
      this.versusResults[index] = variant;

      // Проверка, заполнены ли все радио, убирая null из массива
      var itemLength = this.item.variants.length;
      var arrReal = this.versusResults.filter(val => val);
      var arrRealLength = arrReal.length;
      this.isVersusFilled = itemLength === arrRealLength;
    },

    // Клик по label versus
    clickCheckbox(index, variant) {
      // this.checkbox[index] = variant;
      if (this.checkboxResults[index]) {
        delete this.checkboxResults[index];
      } else {
        this.checkboxResults[index] = variant;
      }
      // Проверка, заполнены ли хотя бы 1 чекбокс
      if (Object.keys(this.checkboxResults).length) {
        this.isCheckboxFilled = true;
      } else {
        this.isCheckboxFilled = false;
      }
    },

    // Поиск внутренних результатов выбранного пункта
    findInnerResults(data, searchId) {
      var valObj = data.filter(function (elem) {
        if (elem.id === searchId) {
          return elem.id;
        }
      });
      return valObj[0].results;
    },

    // Проверка, все ли текстовые поля заполнены
    checkQuestionTextFilled: function () {
      for (var i = 0; i < this.textVariants.length; i++) {
        if (this.textVariants[i].text_result === "") {
          this.isQuestionTextFilled = false;
        }
        else {
          this.isQuestionTextFilled = true;
        }
      }
    },

    // Проверка, чтобы было заполнено хотя бы 1 текстовое поле
    checkQuestionTextFilledSingle: function () {
      this.isQuestionTextFilled = false;
      for (var i = 0; i < this.textVariants.length; i++) {
        if (this.textVariants[i].text_result !== "") {
          this.isQuestionTextFilled = true;
        }
      }
    },

    // Показываем скрытые текстовые поля
    showTextField: function (index) {
      // this.textMultipleShown[index] = true;
      this.$set(this.textMultipleShown, index, true)

      // console.log('textMultipleShown', this.textMultipleShown);
      // console.log('textMultipleShown INDEX', this.textMultipleShown[index]);

      this.textNextIndexToShow = index + 1;

      if (index === 4) {
        this.isShowAddMoreButton = false;
      }

    }


  },
  created: function () {
    if (this.item.type === "text" || this.item.type === "text_single" || this.item.type === "text_multiple") {
      this.textVariants = this.item.variants;
    }
  },
  mounted() {

  }
}

</script>

<style scoped>
.text-question-hidden {
  display: none;
}
.question__wrapper {
  width: 100%;
}

@media screen and (max-width: 450px) {
  .question__variants.question__variants_radio.question__variants_radio-simple.question__variants_radio-columns,
  .question__variants.question__variants_checkbox.question__variants_checkbox_columns.question__variants_radio-columns {
    display: grid;
    grid-template-columns: 1fr;
  }

}

</style>