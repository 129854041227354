<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let resultToChange = null;
      this.unusedVars = resultToChange;
      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1

      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      // Холодные продажи
      if (answersObject.q1?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q1['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Апселл
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += 'Опыт увеличения объема продаж за счет ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      if (answersObject.q4) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q4);

        // Опыт 1, О1
        if (answersObject.q4['4_1'] || answersObject.q4['4_2'] || answersObject.q4['4_3'] || answersObject.q4['4_4']) {
          this['resultTextarea' + variant + isEn] += 'Опыт ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, "4_5", '; ');
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 5, О5
        if (answersObject.q4?.['4_5']) {
          this['resultTextarea' + variant + isEn] += answersObject.q4['4_5'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // О2
      if (answersObject.q5) {
        this['resultTextarea' + variant + isEn] += 'Опыт ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q5, variant, language, "", '; ');
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Госзакупки
      if (answersObject.q6?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q6['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Тендеры
      if (answersObject.q7?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q7['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += 'Знание ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q8, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      //Английский
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // личные качества
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q9, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2
      // Опыт работы
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'Ориентируюсь на работу, которая предполагает в том числе холодный поиск клиентов.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "Понимаю рынок, на котором работаю. Анализирую конкурентов, выявляю их сильные и слабые стороны."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "Нацелен на выстраивание долгосрочных, доверительных контактов с клиентами, на понимание их текущих и потенциальных потребностей.";
      this['resultTextarea' + variant + isEn] += "<br/><br/>";

      // Предпочтения
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += answersObject.q3['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Ошибки
      if (answersObject.q11) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Достижения
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Принципы
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Шахматы
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Мотивация
      if (answersObject.q10?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // График
      if (answersObject.q17) {
        this['resultTextarea' + variant + isEn] += answersObject.q17['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском

      this['resultTextarea' + variant + isEn] += '<b>Professional skills:</b><br/><br/>';

      // Холодные продажи
      if (answersObject.q1?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q1['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Апселл
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += 'Experience increasing sales via ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      if (answersObject.q4) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q4);

        // Опыт 1, О1
        if (answersObject.q4['4_1'] || answersObject.q4['4_2'] || answersObject.q4['4_3'] || answersObject.q4['4_4']) {
          this['resultTextarea' + variant + isEn] += 'Experience ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, "4_5", '; ');
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 5, О5
        if (answersObject.q4?.['4_5']) {
          this['resultTextarea' + variant + isEn] += answersObject.q4['4_5'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // О2
      if (answersObject.q5) {
        this['resultTextarea' + variant + isEn] += 'Experience in ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q5, variant, language, "", '; ');
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Госзакупки
      if (answersObject.q6?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q6['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Тендеры
      if (answersObject.q7?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q7['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы
      if (answersObject.q8) {
        this['resultTextarea' + variant + isEn] += 'Knowledge of ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q8, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      //Английский
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // личные качества
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Personal skills:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q9, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском
      // Опыт работы
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'I understand the market that I work in. I analyze competitors and can quickly identify both their strengths and weaknesses.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";
      this['resultTextarea' + variant + isEn] += "I am focused on building long-term, trusting contacts with clients and understanding their current and potential needs."
      this['resultTextarea' + variant + isEn] += "<br/><br/>";

      // Предпочтения
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += answersObject.q3['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Ошибки
      if (answersObject.q11) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Достижения
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Принципы
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Шахматы
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // Мотивация
      if (answersObject.q10?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }

      // График
      if (answersObject.q17) {
        this['resultTextarea' + variant + isEn] += answersObject.q17['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>