<template>
<div class="container container_main">
  <h1>Страница не найдена</h1>
  <p><a class="header__logo" href="/" @click="$router.push('/')">Bернуться на главную</a></p>
</div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>