<template>
  <div class="auth">
    <div class="container">
      <div class="auth__form">
        <div class="auth__title">Восстановить</div>
        <div class="auth__text">Данные для входа в Ваш аккаунт</div>
        <div class="auth__body">

          <template v-if="!isEmailSent">
            <div class="auth__field auth__field_big-margin">
              <input type="text" placeholder="Ваш email" v-model="fieldEmail" @input="checkFieldEmail()">
              <div class="auth__field-text auth__field-text_error" v-if="isEmailError && !isEmailNotFoundError">Проверьте email</div>
              <div class="auth__field-text auth__field-text_error" v-if="isEmailNotFoundError && !isEmailError">Аккаунт с этим эл. адресом не зарегистрирован.<br>Попробуйте ввести другой email<br>или <a href="#" @click.prevent="$router.push('registration')">Зарегистрируйтесь</a></div>
            </div>
            <div class="auth__button">
              <div class="btn btn_bg-dark" @click="checkEmail()">Восстановить</div>
            </div>
          </template>

          <template v-if="isEmailSent">

            <div class="auth__text-success">Инструкция о дальнейших <br>действиях направлена <br>на Ваш email</div>
            <div class="auth__link"><a href="#" @click.prevent="$router.push('/')">Вернуться на Главную</a></div>

          </template>

          <template v-if="isMailOpened">
            <div class="auth__field">
              <input :type="fieldPasswordType" placeholder="Придумайте новый пароль" v-model="fieldPassword">
              <div class="auth__password-switcher" @click="changePasswordType()"></div>
              <div class="auth__field-text auth__field-text_error">Не менее 8 символов</div>
            </div>
            <div class="auth__field auth__field_middle-margin">
              <input :type="fieldPasswordConfirmationType" placeholder="Повторите новый пароль" v-model="fieldPasswordConfirmation">
              <div class="auth__password-switcher" @click="changePasswordConfirmationType()"></div>
              <div class="auth__field-text auth__field-text_error">Пароли не совпадают</div>
            </div>
            <div class="auth__button">
              <div class="btn btn_bg-dark">Готово</div>
            </div>
          </template>

        </div>
      </div>
      <div class="auth__rules">Продолжая, Вы принимаете условия <router-link to="terms" target="_blank">Соглашения</router-link></div>

    </div>


    <div class="container">
      <div class="auth__form">
        <div class="auth__body">

        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {HTTP} from "@/http-common";
// import {router} from "@/main";

const emailCheckRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;

export default {
  name: "Remind",
  data() {
    return {
      fieldEmail:'',
      fieldPassword:'',
      fieldPasswordConfirmation:'',
      fieldPasswordType:'password',
      fieldPasswordConfirmationType:'password',
      passwordLengthNeed: 8,
      isEmailNotFoundError: false,
      isEmailError: false,
      isPasswordError: false,
      isEmailValid: false,
      isPasswordValid: false,
      isEmailSent: false,
      isMailOpened: false
    }
  },
  computed: {
    // Классы для поля email
    isFieldsValid: function () {
      return this.isEmailValid && this.isPasswordValid
    },
  },
  methods: {
    checkFieldPassword() {
      if (this.fieldPassword.length < this.passwordLengthNeed) {
        this.isPasswordError = true;
        this.isPasswordValid = false;
      } else {
        this.isPasswordError = false;
        this.isPasswordValid = true;
      }
    },
    checkFieldEmail() {
      if (emailCheckRegex.test(this.fieldEmail)) {
        this.isEmailError = false;
        this.isEmailValid = true;
      } else {
        this.isEmailError = true;
        this.isEmailValid = false;
      }
    },
    changePasswordType() {
      if(this.fieldPasswordType === 'password') {
        this.fieldPasswordType = 'text'
      } else {
        this.fieldPasswordType = 'password'
      }
    },
    changePasswordConfirmationType() {
      if(this.fieldPasswordConfirmationType === 'password') {
        this.fieldPasswordConfirmationType = 'text'
      } else {
        this.fieldPasswordConfirmationType = 'password'
      }
    },
    // Проверяем email в базе
    checkEmail() {
      // Проверяем email на валидность
      this.checkFieldEmail();
      if (this.isEmailError === false) {
        HTTP.post('email', {
          email: this.fieldEmail
        })
            .then(resp => {
              console.log('resp', resp);
              if (resp.status === 200) {
                this.sendEmail();
              }

            })
            .catch(err => {
              console.log('err', err);
              this.isEmailSent = false;
              this.isEmailNotFoundError = true;
            })
      }

    },

    // Отправляем ссылку на почту
    sendEmail() {
      HTTP.post('remind', {
        email: this.fieldEmail
      })
          .then(resp => {
            console.log('resp', resp);
            if (resp.status === 200) {
              this.isEmailSent = true;
            }

          })
          .catch(err => {
            console.log('err', err);
            this.isEmailSent = false;
            this.isEmailNotFoundError = true;
          })
    }

  },
  mounted() {
    if (process.env.VUE_APP_TYPE === 'local') {
      this.fieldEmail = process.env.VUE_APP_USER_EMAIL
      this.fieldPassword = process.env.VUE_APP_USER_PASSWORD
      this.fieldPasswordConfirmation = 'password'
      this.isEmailValid = this
      this.isPasswordValid = this
    }
  }
}
</script>

<style scoped>

</style>