<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let resultToChange = null;
      this.unusedVars = resultToChange;
      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1

      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      // ОС1
      this['resultTextarea' + variant + isEn] += 'Знание линейки ОС Windows, включая Windows Server, Active Directory, Exchange';
      if (answersObject.q1?.['1_2']) {
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += answersObject.q1['1_2'].results[0][variantFull][language];
      } else {
        this['resultTextarea' + variant + isEn] += '.';
      }
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      // ОС2
      if (answersObject.q1?.['1_1']) {
        this['resultTextarea' + variant + isEn] += answersObject.q1['1_1'].results[0][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // CRM
      if (answersObject.q2?.['0'][variantFull]?.[language]) {
        if (!answersObject.q2?.['2_3']) {
          this['resultTextarea' + variant + isEn] += answersObject.q2['0'][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // 1C, 1С
      if (answersObject.q4?.['0'][variantFull]?.[language]) {
        if (!answersObject.q4?.['4_3']) {
          this['resultTextarea' + variant + isEn] += answersObject.q4['0'][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // ПО, СКУД + Видео
      if (answersObject.q5) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q5);

        // ПО
        if (answersObject.q5['5_1'] || answersObject.q5['5_2'] || answersObject.q5['5_3'] || answersObject.q5['5_4'] || answersObject.q5['5_5']) {
          this['resultTextarea' + variant + isEn] += 'Опыт ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ["5_6", "5_7"]);
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // СКУД + Видео
        if (answersObject.q5?.['5_6'] || answersObject.q5?.['5_7']) {
          this['resultTextarea' + variant + isEn] += 'Опыт настройки и управления ';
          if (answersObject.q5?.['5_6']) {
            this['resultTextarea' + variant + isEn] += answersObject.q5['5_6'].results[0][variantFull][language];
          }
          if (answersObject.q5?.['5_6'] && answersObject.q5?.['5_7']) {
            this['resultTextarea' + variant + isEn] += ', ';
          }
          if (answersObject.q5?.['5_7']) {
            this['resultTextarea' + variant + isEn] += answersObject.q5['5_7'].results[0][variantFull][language];
          }
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

      }

      // Опыт 1
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += 'Опыт ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q10, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт 2
      if (answersObject.q11) {
        this['resultTextarea' + variant + isEn] += 'Опыт ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q11, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q17?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q17['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества ЛК
      if (answersObject.q16) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q16, variant, language, '', '<br/><br/>');
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2
      // Опыт работы
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Коллектив
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'Понимаю, как создавать и поддерживать надежную сетевую архитектуру, обеспечивать стабильное функционирование ПО и ИТ-сервисов компании.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      this['resultTextarea' + variant + isEn] += 'Осуществляю оперативную организацию новых рабочих мест, установку и настройку нового оборудования и программ.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      this['resultTextarea' + variant + isEn] += 'В работе с сотрудниками проявляю сдержанность и дипломатичность – по всем вопросам консультирую адекватно и профессионально, на понятном общедоступном языке.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      // Frontend
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'Имею опыт frontend-разработки ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Backend
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += 'Имею небольшой опыт backend-программирования ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q7, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Таск-трекеры
      if (answersObject.q12) {
        this['resultTextarea' + variant + isEn] += 'Владею навыками работы с таск-трекерами ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q12, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // ГР
      if (answersObject.q12) {
        this['resultTextarea' + variant + isEn] += 'Знаком с графическими редакторами ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q12, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // ИП, инструменты проектирования
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'Имею опыт работы с инструментами проектирования ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт руководства
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // График
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском
      this['resultTextarea' + variant + isEn] += '<b>Professional skills:</b><br/><br/>';
      // ОС1
      this['resultTextarea' + variant + isEn] += 'Knowledge of Windows OS, including Windows Server, Active Directory, Exchange';
      if (answersObject.q1?.['1_2']) {
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += answersObject.q1['1_2'].results[0][variantFull][language];
      } else {
        this['resultTextarea' + variant + isEn] += '.';
      }
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      // ОС2
      if (answersObject.q1?.['1_1']) {
        this['resultTextarea' + variant + isEn] += answersObject.q1['1_1'].results[0][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // CRM
      if (answersObject.q2?.['0'][variantFull]?.[language]) {
        if (!answersObject.q2?.['2_3']) {
          this['resultTextarea' + variant + isEn] += answersObject.q2['0'][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // 1C, 1С
      if (answersObject.q4?.['0'][variantFull]?.[language]) {
        if (!answersObject.q4?.['4_3']) {
          this['resultTextarea' + variant + isEn] += answersObject.q4['0'][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // ПО, СКУД + Видео
      if (answersObject.q5) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q5);

        // ПО
        if (answersObject.q5['5_1'] || answersObject.q5['5_2'] || answersObject.q5['5_3'] || answersObject.q5['5_4'] || answersObject.q5['5_5']) {
          this['resultTextarea' + variant + isEn] += 'Experience installing and configuring ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ["5_6", "5_7"]);
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // СКУД + Видео
        if (answersObject.q5?.['5_6'] || answersObject.q5?.['5_7']) {
          this['resultTextarea' + variant + isEn] += 'Experience in setting up and managing ';
          if (answersObject.q5?.['5_6']) {
            this['resultTextarea' + variant + isEn] += answersObject.q5['5_6'].results[0][variantFull][language];
          }
          if (answersObject.q5?.['5_6'] && answersObject.q5?.['5_7']) {
            this['resultTextarea' + variant + isEn] += ', ';
          }
          if (answersObject.q5?.['5_7']) {
            this['resultTextarea' + variant + isEn] += answersObject.q5['5_7'].results[0][variantFull][language];
          }
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

      }

      // Опыт 1
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += 'Experience in ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q10, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт 2
      if (answersObject.q11) {
        this['resultTextarea' + variant + isEn] += 'Experience in ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q11, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q17?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q17['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества ЛК
      if (answersObject.q16) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Personal skills:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q16, variant, language, '', '<br/><br/>');
      }

      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском
      // Опыт работы
      if (answersObject.q13?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q13['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Коллектив
      if (answersObject.q14?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q14['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'I have an understanding of how to create and maintain reliable network architecture and to ensure stable operation of both software and IT services.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      this['resultTextarea' + variant + isEn] += 'I’m skilled in rapidly organizing new workstations, and installing and configuring new equipment and programs.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      this['resultTextarea' + variant + isEn] += 'I always try to be discreet and diplomatic when working with employees. I advise on all issues in an adequate and professional manner, as well as in an easy-to-understand and accessible language.';
      this['resultTextarea' + variant + isEn] += '<br/><br/>';

      // Frontend
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'I have experience in frontend development ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Backend
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += 'I have some junior experience in backend programming ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q7, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Таск-трекеры
      if (answersObject.q12) {
        this['resultTextarea' + variant + isEn] += 'I’m skilled in working with task-trackers ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q12, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // ГР
      if (answersObject.q12) {
        this['resultTextarea' + variant + isEn] += 'I’m familiar with graphics editing tools ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q12, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // ИП, инструменты проектирования
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'I’m';
        this['resultTextarea' + variant + isEn] += ' ';
        if (answersObject.q12) {
          this['resultTextarea' + variant + isEn] += 'also';
          this['resultTextarea' + variant + isEn] += ' ';
        }
        this['resultTextarea' + variant + isEn] += 'familiar with design tools ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт руководства
      if (answersObject.q15?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q15['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // График
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>