import store from "@/store";

// todo: Работает только 1 раз

//region Проверка на авторизацию
async function checkIsAuth() {
  if (!localStorage.getItem('token')) {
    return false;
  }
  const checkToken = async () => {
    return store.dispatch('checkTokenSimple');
  }
  return checkToken().then(() => {
    if (!localStorage.getItem('token')) {
      return false;
    }
    return true;
  });
}
export const isAuth = checkIsAuth();
//endregion

//region Проверка на оплату
async function checkIsPayed() {

  const booleanIsPayed = function (result) {
    return result
  };

  let premiumType = 0;
  if (localStorage.getItem('premiumOrderId')) {
    const getPremiumType = async () => {
      return store.dispatch('setPremium')
        .then((resp) => {
          // console.log('resp', resp);
          premiumType = resp.data.type;
          if (premiumType === 1) {
            return booleanIsPayed(true);
          }
          return booleanIsPayed(false);
        })
        .catch(err => {
          if (process.env.VUE_APP_TYPE === 'local' || (this.$router.currentRoute.query && this.$router.currentRoute.query.isDebug === true)) {
            console.log(err)
            console.log(err.response);
            return booleanIsPayed(false);
          }
        });
    }

    return getPremiumType()

  }

  return booleanIsPayed(false);
}

export const isPayed = checkIsPayed();
//endregion

//region Есть ли доступ (зависит от двух функций)
async function result() {
  return await Promise.all([
    checkIsAuth(),
    checkIsPayed(),
  ]).then(value => {
    if ((value[0] && value[1]) === true) {
      return true
    }
    return false
  });

}
export const isAccess = result();
//endregion