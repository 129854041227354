<script>
import Vue from 'vue'

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1

      this['resultTextarea' + variant + isEn] += '<b>Профессиональные качества:</b><br/><br/>';

      // Backend Senior
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'Владение языками backend на уровне senior (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Backend Middle
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += 'Владение языками backend на уровне middle (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Backend Junior
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Владение языками backend на уровне junior (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Frontend
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += 'Понимание ключевых принципов frontend-программирования (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q4, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Храните код
      if (answersObject.q5) {
        this['resultTextarea' + variant + isEn] += 'Опыт использования ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q5, variant, language);
        this['resultTextarea' + variant + isEn] += ' для хостинга проектов и их совместной разработки.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Контроль версий
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'Опыт использования ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += ' в качестве системы контроля версий.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Язык разметки
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'Опыт использования ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += ' для передачи данных в логически и визуально правильной форме.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Архитектурный стиль
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += 'Понимание принципов создания веб-сервисов на основе ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q10, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Базы данных
      if (answersObject.q14) {
        this['resultTextarea' + variant + isEn] += 'Опыт использования СУБД (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q14, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // QA
      if (answersObject.q11?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q22?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q22['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества ЛК
      if (answersObject.q20) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q20, variant, language, '', '<br/><br/>');
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2
      // Опыт работы
      if (answersObject.q17?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q17['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Области
      if (answersObject.q15) {
        this['resultTextarea' + variant + isEn] += 'Имею опыт реализации разноплановых проектов в сфере ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q15, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // CMS
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += 'Владею основными принципами разработки, внедрения и поддержки систем управления контентом. Использую CMS с открытым исходным кодом ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q7, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Фреймворки
      if (answersObject.q8?.['0'].text_result) {
        this['resultTextarea' + variant + isEn] += 'Для ускорения и упрощения разработки задействую ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.TextResultsToString(answersObject.q8);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Без фреймворков
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Криптография
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Люблю backend за
      if (answersObject.q19) {
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q19, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Стремлюсь
      if (answersObject.q21?.['21_1']) {
        this['resultTextarea' + variant + isEn] += answersObject.q21['21_1'].results[0][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      if (answersObject.q21?.['21_2']) {
        if (answersObject.q21?.['21_1']) {
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.replace(answersObject.q21['21_2'].results[0][variantFull][language], 'Мне импонирует', 'Мне также импонирует');
        } else {
          this['resultTextarea' + variant + isEn] += answersObject.q21['21_2'].results[0][variantFull][language];
        }
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // таск-трекеры
      if (answersObject.q13) {
        this['resultTextarea' + variant + isEn] += 'Владею навыками работы с таск-трекерами';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q13, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт руководства
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // График
      if (answersObject.q23) {
        this['resultTextarea' + variant + isEn] += answersObject.q23['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском
      this['resultTextarea' + variant + isEn] += '<b>Professional skills:</b><br/><br/>';

      // Backend Senior
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'Senior level backend programming experience (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Backend Middle
      if (answersObject.q2) {
        this['resultTextarea' + variant + isEn] += 'Middle level backend programming experience (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Backend Junior
      if (answersObject.q3) {
        this['resultTextarea' + variant + isEn] += 'Junior level backend programming experience (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q3, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Frontend
      if (answersObject.q4) {
        this['resultTextarea' + variant + isEn] += 'Knowledge of key principles of frontend programming (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q4, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Храните код
      if (answersObject.q5) {
        this['resultTextarea' + variant + isEn] += 'Experience using ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q5, variant, language);
        this['resultTextarea' + variant + isEn] += ' for project hosting and collaborative development.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Контроль версий
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'Experience using ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += ' as a version control system.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Язык разметки
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += 'Experience using ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q9, variant, language);
        this['resultTextarea' + variant + isEn] += ' to communicate data in a logically and visually correct manner.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Архитектурный стиль
      if (answersObject.q10) {
        this['resultTextarea' + variant + isEn] += 'Experience developing API based on ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q10, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Базы данных
      if (answersObject.q14) {
        this['resultTextarea' + variant + isEn] += 'Experience using DBMS (';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q14, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // QA
      if (answersObject.q11?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q22?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q22['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Личные качества ЛК
      if (answersObject.q20) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Personal skills:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q20, variant, language, '', '<br/><br/>');
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском
      // Опыт работы
      if (answersObject.q17?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q17['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Области
      if (answersObject.q15) {
        this['resultTextarea' + variant + isEn] += 'I have experience in implementing various projects in the fields of ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q15, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // CMS
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += 'I know how to develop, implement, and support content management systems. I use open source CMS ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q7, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Фреймворки
      if (answersObject.q8?.['0'].text_result) {
        this['resultTextarea' + variant + isEn] += 'I use ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.TextResultsToString(answersObject.q8);
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += 'to make development faster and easier';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Без фреймворков
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Криптография
      if (answersObject.q16?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q16['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Люблю backend за
      if (answersObject.q19) {
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q19, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Стремлюсь
      if (answersObject.q21?.['21_1']) {
        this['resultTextarea' + variant + isEn] += answersObject.q21['21_1'].results[0][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      if (answersObject.q21?.['21_2']) {
        if (answersObject.q21?.['21_1']) {
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.replace(answersObject.q21['21_2'].results[0][variantFull][language], 'The idea of', 'Likewise, the idea of');
        } else {
          this['resultTextarea' + variant + isEn] += answersObject.q21['21_2'].results[0][variantFull][language];
        }
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // таск-трекеры
      if (answersObject.q13) {
        this['resultTextarea' + variant + isEn] += 'I’m skilled in working with task-trackers';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += '(';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q13, variant, language);
        this['resultTextarea' + variant + isEn] += ')';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт руководства
      if (answersObject.q18?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q18['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // График
      if (answersObject.q23) {
        this['resultTextarea' + variant + isEn] += answersObject.q23['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += "<br/><br/>";
      }
      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>