<script>
import Vue from 'vue'

// todo: Тут нет "Профессиональные качества"

const textResult = {
  result(answersObject) {
    if (answersObject) {

      //region Начальные данные (не менять)
      this.resultTextarea1 = '';
      this.resultTextarea2 = '';
      this.resultTextarea1en = '';
      this.resultTextarea2en = '';

      let resultToChange = null;
      this.unusedVars = resultToChange;
      let variant = 1;
      let variantFull = 'variant' + variant;
      let language = 'ru';
      let isEn = '';
      //endregion

      //region Результат 1
      // Терапия, Хирургия, Ортопедия
      if (answersObject.q1) {
        // Терапия
        // if (answersObject.q1?.['1_1']) {
        //
        // }

        // Хирургия
        // if (answersObject.q1?.['1_2']) {
        //
        // }

        // Ортопедия
        // if (answersObject.q1?.['1_3']) {
        //
        // }
      }

      // Терапия
      if (answersObject.q2 || answersObject.q3) {

        this['resultTextarea' + variant + isEn] += '<b>Терапия:</b><br/><br/>';

        // Опыт 1
        if (answersObject.q2) {
          this['resultTextarea' + variant + isEn] += 'Опыт лечения ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q2, variant, language);
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 2, Опыт 3, Опыт 4
        if (answersObject.q3) {
          // Временное хранилище
          resultToChange = Vue.jobsHelpers.clone(answersObject.q3);

          // Опыт 2
          if (answersObject.q3?.['3_1'] || answersObject.q3?.['3_2']) {
            this['resultTextarea' + variant + isEn] += 'Опыт ';
            this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ['3_4', '3_5']);
            this['resultTextarea' + variant + isEn] += '.<br/><br/>';
          }

          // Опыт 3
          if (answersObject.q3?.['3_4']) {
            this['resultTextarea' + variant + isEn] += answersObject.q3['3_4'].results[0][variantFull][language];
            this['resultTextarea' + variant + isEn] += '<br/><br/>';
          }

          // Опыт 4
          if (answersObject.q3?.['3_5']) {
            this['resultTextarea' + variant + isEn] += answersObject.q3['3_5'].results[0][variantFull][language];
            this['resultTextarea' + variant + isEn] += '<br/><br/>';
          }
        }
      }

      // Хирургия
      // Опыт 5, Опыт 6, Опыт 7
      if (answersObject.q4) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q4);

        this['resultTextarea' + variant + isEn] += '<b>Хирургия:</b><br/><br/>';

        // Опыт 5
        if (answersObject.q4?.['4_1'] || answersObject.q4?.['4_2'] || answersObject.q4?.['4_3'] || answersObject.q4?.['4_4'] || answersObject.q4?.['4_5'] || answersObject.q4?.['4_6'] || answersObject.q4?.['4_7']) {
          this['resultTextarea' + variant + isEn] += 'Опыт ';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ['4_8', '4_9']);
          this['resultTextarea' + variant + isEn] += '.<br/><br/>';
        }

        // Опыт 6
        if (answersObject.q4?.['4_8']) {
          this['resultTextarea' + variant + isEn] += answersObject.q4['4_8'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 7
        if (answersObject.q4?.['4_9']) {
          this['resultTextarea' + variant + isEn] += answersObject.q4['4_9'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // Ортопедия
      // Опыт 8, Опыт 9, Опыт 10
      if (answersObject.q5) {
        // Временное хранилище
        resultToChange = Vue.jobsHelpers.clone(answersObject.q5);

        this['resultTextarea' + variant + isEn] += '<b>Ортопедия:</b><br/><br/>';

        // Опыт 8
        if (answersObject.q5?.['5_1'] || answersObject.q5?.['5_2'] || answersObject.q5?.['5_3'] || answersObject.q5?.['5_6'] || answersObject.q5?.['5_7'] || answersObject.q5?.['5_8']) {
          this['resultTextarea' + variant + isEn] += 'Опыт протезирования ';
          this['resultTextarea' + variant + isEn] += '(';
          this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(resultToChange, variant, language, ['5_4', '5_5']);
          this['resultTextarea' + variant + isEn] += ')';
          this['resultTextarea' + variant + isEn] += '.';
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 9
        if (answersObject.q5?.['5_4']) {
          this['resultTextarea' + variant + isEn] += answersObject.q5['5_4'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }

        // Опыт 10
        if (answersObject.q5?.['5_5']) {
          this['resultTextarea' + variant + isEn] += answersObject.q5['5_5'].results[0][variantFull][language];
          this['resultTextarea' + variant + isEn] += '<br/><br/>';
        }
      }

      // личные качества
      if (answersObject.q9) {
        this['resultTextarea' + variant + isEn] += '<br/><b>Личные качества:</b><br/><br/>';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q9, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }
      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для результата 2 (НЕ УДАЛЯТЬ, НЕ РЕДАКТИРОВАТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      //endregion

      //region Результат 2

      // Опыт работы
      if (answersObject.q10?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q10['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Главный опыт
      if (answersObject.q1) {
        this['resultTextarea' + variant + isEn] += 'Веду';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q1, variant, language);
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += 'прием';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      this['resultTextarea' + variant + isEn] += 'Знаком с современными стоматологическими материалами, использую актуальные методики лечения.';
      this['resultTextarea' + variant + isEn] += "<br/><br/>";

      // Дополнительный опыт
      if (answersObject.q7) {
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToStringFull(answersObject.q7, variant, language, '', '<br/><br/>');
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Программы
      if (answersObject.q6) {
        this['resultTextarea' + variant + isEn] += 'Имею опыт использования ';
        this['resultTextarea' + variant + isEn] += Vue.jobsHelpers.ObjectToString(answersObject.q6, variant, language);
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Постоянные клиенты
      if (answersObject.q8?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q8['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Английский
      if (answersObject.q11?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += answersObject.q11['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      // Опыт руководства
      if (answersObject.q12?.['0'][variantFull]?.[language]) {
        this['resultTextarea' + variant + isEn] += 'Имею опыт руководства';
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += answersObject.q12['0'][variantFull][language];
        this['resultTextarea' + variant + isEn] += ' ';
        this['resultTextarea' + variant + isEn] += 'медперсоналом';
        this['resultTextarea' + variant + isEn] += '.';
        this['resultTextarea' + variant + isEn] += '<br/><br/>';
      }

      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского (НЕ УДАЛЯТЬ!)
      variant = 1;
      variantFull = 'variant' + variant;
      language = 'en';
      isEn = 'en';
      //endregion

      //region РЕЗУЛЬТАТ 1 на английском

      //endregion

      //=====================================
      //=====================================
      //=====================================

      //region Данные для английского результата 2 (НЕ УДАЛЯТЬ!)
      variant = 2;
      variantFull = 'variant' + variant;
      isEn = 'en';
      //endregion

      //region Результат 2 на английском

      //endregion

      //=====================================
      // notice: Дальше не идём!
      //=====================================

      //region НЕ ТРОГАТЬ!
      return {
        resultTextarea1: this.resultTextarea1,
        resultTextarea2: this.resultTextarea2,
        resultTextarea1en: this.resultTextarea1en,
        resultTextarea2en: this.resultTextarea2en,
      };
      //endregion
    }
  },
}

export default textResult

</script>
